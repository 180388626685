var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Button/Button.jinja"] = require( "mobile/components/Button/Button.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/TopNav/TopNav.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/TopNav/TopNav.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n";
env.getTemplate("mobile/components/Button/Button.jinja", false, "assets/app/mobile/components/TopNav/TopNav.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Button")) {
var t_8 = t_5.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["classes", "currentUrl", "title", "type", "href"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("currentUrl", Object.prototype.hasOwnProperty.call(kwargs, "currentUrl") ? kwargs["currentUrl"] : "");frame.set("title", Object.prototype.hasOwnProperty.call(kwargs, "title") ? kwargs["title"] : "");frame.set("type", Object.prototype.hasOwnProperty.call(kwargs, "type") ? kwargs["type"] : "");frame.set("href", Object.prototype.hasOwnProperty.call(kwargs, "href") ? kwargs["href"] : "");var t_10 = "";t_10 += "\n    <nav class=\"TopNav ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\">\n        <div class=\"TopNav-inner\">\n            <div class=\"TopNav-overlay\"></div>\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "caller")) {
t_10 += "\n                ";
t_10 += runtime.suppressValue((lineno = 9, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_10 += "\n            ";
;
}
t_10 += "\n\n            <div class=\"TopNav-header\">\n                <a class=\"TopNav-logo\" href=\"";
t_10 += runtime.suppressValue((lineno = 13, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:index"])), env.opts.autoescape);
t_10 += "\">\n                    ";
t_10 += runtime.suppressValue((lineno = 14, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["logo"])), env.opts.autoescape);
t_10 += "\n                </a>\n\n                ";
if(runtime.contextOrFrameLookup(context, frame, "title")) {
t_10 += "\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "postList") {
t_10 += "\n                        <button class=\"TopNav-title\">\n                            <div class=\"TopNav-titleInner\">\n                                ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_10 += "\n                                ";
t_10 += runtime.suppressValue((lineno = 22, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowNav"])), env.opts.autoescape);
t_10 += "\n                            </div>\n                        </button>\n                    ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "href")) {
t_10 += "\n                        <a href=\"";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
t_10 += "\" class=\"TopNav-title\">\n                            <div class=\"TopNav-titleInner\">\n                                ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_10 += "\n                                ";
t_10 += runtime.suppressValue((lineno = 29, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowNav"])), env.opts.autoescape);
t_10 += "\n                            </div>\n                        </a>\n                    ";
;
}
else {
t_10 += "\n                        <div class=\"TopNav-title\">\n                            <div class=\"TopNav-titleInner\">\n                                ";
t_10 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "title") == "Пиво"?"Пивоваренная продукция":runtime.contextOrFrameLookup(context, frame, "title")), env.opts.autoescape);
t_10 += "\n                                ";
t_10 += runtime.suppressValue((lineno = 36, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowNav"])), env.opts.autoescape);
t_10 += "\n                            </div>\n                        </div>\n                    ";
;
}
;
}
t_10 += "\n                ";
;
}
t_10 += "\n\n                <button class=\"TopNav-button\"></button>\n            </div>\n\n            <aside class=\"TopNav-popup\">\n                <div class=\"TopNav-body\">\n                    <div class=\"TopNav-links\">\n                        <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 48, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/about/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 48, colno = 92, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/people/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 48, colno = 130, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/history/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 49, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:about"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 49, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 50, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/production/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 51, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:production"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 51, colno = 69, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 52, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/brands/"])), env.opts.autoescape);
t_10 += " ";
t_10 += runtime.suppressValue((lineno = 52, colno = 93, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/brand/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 53, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": ["beer"]})])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 53, colno = 89, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link TopNav-link--ru ";
t_10 += runtime.suppressValue((lineno = 54, colno = 71, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/posts/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 55, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:index"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 55, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link ";
t_10 += runtime.suppressValue((lineno = 56, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/legal/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 57, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-information"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 57, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link TopNav-link--ru ";
t_10 += runtime.suppressValue((lineno = 58, colno = 71, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/career/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 59, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:career"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 59, colno = 65, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link TopNav-link--ru\" target=\"_blank\" href=\"https://shop.mosbrew.ru/\">";
t_10 += runtime.suppressValue((lineno = 60, colno = 115, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Магазин"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link u-Route ";
t_10 += runtime.suppressValue((lineno = 61, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "active"), "active", context, [runtime.contextOrFrameLookup(context, frame, "currentUrl"),"^/excursion/"])), env.opts.autoescape);
t_10 += "\"\n                            href=\"";
t_10 += runtime.suppressValue((lineno = 62, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:excursion"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 62, colno = 68, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
t_10 += "</a>\n                        <a class=\"TopNav-link\" href=\"";
t_10 += runtime.suppressValue((lineno = 63, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:contact"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 63, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
t_10 += "</a>\n                    </div>\n                </div>\n            </aside>\n        </div>\n    </nav>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("TopNav");
context.setVariable("TopNav", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 72, colno = 13, runtime.callWrap(macro_t_9, "TopNav", context, [runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes"),"currentUrl": runtime.contextOrFrameLookup(context, frame, "currentUrl")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/TopNav/TopNav.jinja"] , dependencies)
var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');
var LegalMenuTop = require('mobile/components/LegalMenuTop/LegalMenuTop');
var LegalFormRestaurants = require('mobile/components/LegalForms/LegalFormRestaurants/LegalFormRestaurants');

require('./LegalRestaurants.less');

module.exports = Page.extend({
    template: require('./LegalRestaurants.jinja'),

    el: '.Legal',

    apiUrl: '/api/index/',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Партнерство для ресторанов и баров'),

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new LegalMenuTop();
        this.legalForm = new LegalFormRestaurants();
    },

    activate: function () {
        this.setTitle();
    }
});

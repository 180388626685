var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/components/SectionBadge/SectionBadge.jinja"] = require( "mobile/components/SectionBadge/SectionBadge.jinja" );
dependencies["mobile/components/ViewCount/ViewCount.jinja"] = require( "mobile/components/ViewCount/ViewCount.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/PostItem/PostItem.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/components/SectionBadge/SectionBadge.jinja", false, "assets/app/mobile/components/PostItem/PostItem.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "SectionBadge")) {
var t_4 = t_1.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_4);
output += "\n";
env.getTemplate("mobile/components/ViewCount/ViewCount.jinja", false, "assets/app/mobile/components/PostItem/PostItem.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "ViewCount")) {
var t_8 = t_5.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
["post"], 
["classes"], 
function (l_post, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("post", l_post);
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_10 = "";t_10 += "\n    <article class=\"PostItem u-Route ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\" data-order=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"dateSeconds"), env.opts.autoescape);
t_10 += "\">\n        <div class=\"PostItem-meta\">\n            <span class=\"PostItem-category\">\n                ";
t_10 += runtime.suppressValue((lineno = 7, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SectionBadge"), "SectionBadge", context, [runtime.memberLookup((l_post),"section")])), env.opts.autoescape);
t_10 += "\n            </span>\n            <span class=\"PostItem-date\" style=\"color: ";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"textColor"), env.opts.autoescape);
t_10 += ";\">";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"dateText"), env.opts.autoescape);
t_10 += "</span>\n        </div>\n\n        <a class=\"PostItem-link\" href=\"";
t_10 += runtime.suppressValue((lineno = 12, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:post",runtime.makeKeywordArgs({"args": [runtime.memberLookup((l_post),"id")]})])), env.opts.autoescape);
t_10 += "\">\n            <div class=\"PostItem-content\" style=\"color: ";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"textColor"), env.opts.autoescape);
t_10 += ";\">\n                <span class=\"PostItem-title\" style=\"border-color: ";
t_10 += runtime.suppressValue(env.getFilter("hexToRGBA").call(context, runtime.memberLookup((l_post),"textColor"),0.2), env.opts.autoescape);
t_10 += ";\">";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"title"), env.opts.autoescape);
t_10 += "</span>\n            </div>\n\n            <div class=\"PostItem-img\">\n                <img src=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_post),"preview")),"w640"), env.opts.autoescape);
t_10 += "\" \n                    srcset=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_post),"preview")),"w320"), env.opts.autoescape);
t_10 += " 320w, \n                            ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_post),"preview")),"w640"), env.opts.autoescape);
t_10 += " 640w,\n                            ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_post),"preview")),"w768"), env.opts.autoescape);
t_10 += " 768w,\n                            ";
t_10 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((l_post),"preview")),"w1024"), env.opts.autoescape);
t_10 += " 1024w\"\n                    alt=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"title"), env.opts.autoescape);
t_10 += "\">\n                <div class=\"PostItem-imgOverlay\" style=\"background: linear-gradient(180deg, ";
t_10 += runtime.suppressValue(runtime.memberLookup((l_post),"imgColor"), env.opts.autoescape);
t_10 += " 8%, ";
t_10 += runtime.suppressValue(env.getFilter("hexToRGBA").call(context, runtime.memberLookup((l_post),"imgColor"),0), env.opts.autoescape);
t_10 += " 98%);\"></div>\n            </div>\n\n            <div class=\"PostItem-viewCount\">\n                ";
t_10 += runtime.suppressValue((lineno = 28, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ViewCount"), "ViewCount", context, [runtime.memberLookup((l_post),"viewCount")])), env.opts.autoescape);
t_10 += "\n            </div>\n        </a>\n\n        <div class=\"PostItem-overlay\"></div>\n    </article>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("PostItem");
context.setVariable("PostItem", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 37, colno = 15, runtime.callWrap(macro_t_9, "PostItem", context, [runtime.contextOrFrameLookup(context, frame, "post"),runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes")})])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/PostItem/PostItem.jinja"] , dependencies)
var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
var MenuTop = require('mobile/components/MenuTop/MenuTop');

require('./Career.less');

module.exports = Page.extend({
    template: require('./Career.jinja'),

    el: '.Career',

    title: window.gettext('Карьера'),

    events: {
        'click .Career-jobType': 'selectJobs',
        'click .Career-jobCross.isActive': 'hideJobs'
    },

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new MenuTop();
    },

    activate: function () {
        this.setTitle();
    },
});

var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Button/Button.jinja"] = require( "mobile/components/Button/Button.jinja" );
dependencies["mobile/components/ButtonIcon/ButtonIcon.jinja"] = require( "mobile/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["mobile/components/Slider/SliderVariant.jinja"] = require( "mobile/components/Slider/SliderVariant.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Excursion/Excursion.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Excursion/Excursion.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Excursion/Excursion.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n\n";
env.getTemplate("mobile/components/Button/Button.jinja", false, "assets/app/mobile/pages/Excursion/Excursion.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "Button")) {
var t_11 = t_8.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_11);
output += "\n";
env.getTemplate("mobile/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/mobile/pages/Excursion/Excursion.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "ButtonIcon")) {
var t_15 = t_12.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_15);
output += "\n";
env.getTemplate("mobile/components/Slider/SliderVariant.jinja", false, "assets/app/mobile/pages/Excursion/Excursion.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "SliderVariant")) {
var t_19 = t_16.SliderVariant;
} else {
cb(new Error("cannot import 'SliderVariant'")); return;
}
context.setVariable("SliderVariant", t_19);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popup"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 12, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--index"})])), env.opts.autoescape);
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "ExcursionPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popup(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Excursion ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "Excursion--toAnimate";
;
}
output += "\">\n        <div class=\"Excursion-floatNav\"></div>\n        <div class=\"Excursion-background\">\n            <img\n                class=\"Excursion-backgroundImage\"\n                src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"data")),"img")),"w720"), env.opts.autoescape);
output += "\"\n                alt=\"\"\n                decoding=\"async\"\n            >\n        </div>\n\n        <div class=\"Excursion-header\">\n            <div class=\"Excursion-subtitle\">\n                <span class=\"Excursion-subtitleStroke\"></span>\n                <div class=\"Excursion-subtitleText\">";
output += runtime.suppressValue((lineno = 32, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Экскурсия"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <h1 class=\"Excursion-headerTitle\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"title"), env.opts.autoescape);
output += "\n            </h1>\n        </div>\n\n        <div class=\"Excursion-textSection\">\n            <div class=\"Excursion-textSectionContent\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"text"))), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Excursion-contacts\">\n            <h2 class=\"Excursion-contactsTitle\">Контакты</h2>\n            <ul class=\"Excursion-contactsList\">\n                <li class=\"Excursion-contactsListItem\">\n                    <div class=\"Excursion-contactsListLabel\">Пишите:</div>\n                    <div class=\"Excursion-contactsListContent\">\n                        <a class=\"Excursion-contactsListLink\" href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"email"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"email"), env.opts.autoescape);
output += "</a>\n                        <a class=\"Excursion-contactsListLink\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"whatsapp"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                            <span class=\"Excursion-contactsListIcon\">\n                                ";
output += runtime.suppressValue((lineno = 54, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-contacts-whatsapp"])), env.opts.autoescape);
output += "\n                            </span>\n                            WhatsApp\n                        </a>\n                        <a class=\"Excursion-contactsListLink\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"telegram"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">\n                            <span class=\"Excursion-contactsListIcon\">\n                                ";
output += runtime.suppressValue((lineno = 60, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-contacts-tg"])), env.opts.autoescape);
output += "\n                            </span>\n                            Telegram\n                        </a>\n                    </div>\n                </li>\n                <li class=\"Excursion-contactsListItem\">\n                    <div class=\"Excursion-contactsListLabel\">Звоните:</div>\n                    <div class=\"Excursion-contactsListContent\">\n                        <a class=\"Excursion-contactsListLink\" href=\"tel:+79651818580\">+7 965 181 85 80</a>\n                    </div>\n                </li>\n            </ul>\n\n            <div class=\"Excursion-contactsText\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"contactsDescription"))), env.opts.autoescape);
output += "\n            </div>\n\n            <a class=\"Button Button--tiny Excursion-button Excursion-button--black\" href=\"#excursion-faq\">Частые вопросы</a>\n        </div>\n\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual")) > 0 && env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart")) > 0) {
output += "<div class=\"Excursion-textSection\">\n            <h2 class=\"Excursion-textSectionTitle\">\n                ";
output += runtime.suppressValue((lineno = 84, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стоимость экскурсии"])), env.opts.autoescape);
output += "\n            </h2>\n\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart")) > 0) {
output += "<div class=\"Excursion-tour\">\n                <h3 class=\"Excursion-textSectionSubTitle\">";
output += runtime.suppressValue((lineno = 89, colno = 62, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["В составе общей группы"])), env.opts.autoescape);
output += "</h3>\n\n                ";
var t_30;
t_30 = [];
frame.set("rows_workdays", t_30, true);
if(frame.topLevel) {
context.setVariable("rows_workdays", t_30);
}
if(frame.topLevel) {
context.addExport("rows_workdays", t_30);
}
output += "\n                ";
var t_31;
t_31 = [];
frame.set("rows_weekends", t_31, true);
if(frame.topLevel) {
context.setVariable("rows_weekends", t_31);
}
if(frame.topLevel) {
context.addExport("rows_weekends", t_31);
}
output += "\n\n                ";
frame = frame.push();
var t_34 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandart");
if(t_34) {t_34 = runtime.fromIterator(t_34);
var t_33 = t_34.length;
for(var t_32=0; t_32 < t_34.length; t_32++) {
var t_35 = t_34[t_32];
frame.set("row", t_35);
frame.set("loop.index", t_32 + 1);
frame.set("loop.index0", t_32);
frame.set("loop.revindex", t_33 - t_32);
frame.set("loop.revindex0", t_33 - t_32 - 1);
frame.set("loop.first", t_32 === 0);
frame.set("loop.last", t_32 === t_33 - 1);
frame.set("loop.length", t_33);
output += "\n                    ";
if(runtime.memberLookup((t_35),"workdays")) {
output += "\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays")),"push")) {
output += "\n                            ";
var t_36;
t_36 = (lineno = 97, colno = 57, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays")),"push"), "rows_workdays[\"push\"]", context, [t_35]));
frame.set("_", t_36, true);
if(frame.topLevel) {
context.setVariable("_", t_36);
}
output += "\n                        ";
;
}
else {
output += "\n                            ";
var t_37;
t_37 = (lineno = 99, colno = 59, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays")),"append"), "rows_workdays[\"append\"]", context, [t_35]));
frame.set("_", t_37, true);
if(frame.topLevel) {
context.setVariable("_", t_37);
}
output += "\n                        ";
;
}
output += "\n                    ";
;
}
output += "\n                    ";
if(runtime.memberLookup((t_35),"weekends")) {
output += "\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends")),"push")) {
output += "\n                            ";
var t_38;
t_38 = (lineno = 104, colno = 57, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends")),"push"), "rows_weekends[\"push\"]", context, [t_35]));
frame.set("_", t_38, true);
if(frame.topLevel) {
context.setVariable("_", t_38);
}
output += "\n                        ";
;
}
else {
output += "\n                            ";
var t_39;
t_39 = (lineno = 106, colno = 59, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends")),"append"), "rows_weekends[\"append\"]", context, [t_35]));
frame.set("_", t_39, true);
if(frame.topLevel) {
context.setVariable("_", t_39);
}
output += "\n                        ";
;
}
output += "\n                    ";
;
}
output += "\n                ";
;
}
}
frame = frame.pop();
output += "\n\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays")) > 0 || env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends")) > 0) {
output += "\n                <table class=\"Excursion-tourTable\">\n                    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays")) > 0) {
output += "\n                        ";
frame = frame.push();
var t_42 = runtime.contextOrFrameLookup(context, frame, "rows_workdays");
if(t_42) {t_42 = runtime.fromIterator(t_42);
var t_41 = t_42.length;
for(var t_40=0; t_40 < t_42.length; t_40++) {
var t_43 = t_42[t_40];
frame.set("row", t_43);
frame.set("loop.index", t_40 + 1);
frame.set("loop.index0", t_40);
frame.set("loop.revindex", t_41 - t_40);
frame.set("loop.revindex0", t_41 - t_40 - 1);
frame.set("loop.first", t_40 === 0);
frame.set("loop.last", t_40 === t_41 - 1);
frame.set("loop.length", t_41);
output += "\n                        <tr>\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1) {
output += "\n                            <td rowspan=\"";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays")), env.opts.autoescape);
output += "\" width=\"130\">\n                                ";
output += runtime.suppressValue((lineno = 118, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Будни"])), env.opts.autoescape);
output += "\n                            </td>\n                            ";
;
}
output += "\n                            <td>\n                                ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_43),"people")," ",true), env.opts.autoescape);
output += " <br>\n                                <b>";
output += runtime.suppressValue(runtime.memberLookup((t_43),"workdays"), env.opts.autoescape);
output += "</b>\n                            </td>\n                        </tr>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    ";
;
}
output += "\n\n                    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends")) > 0) {
output += "\n                        ";
frame = frame.push();
var t_46 = runtime.contextOrFrameLookup(context, frame, "rows_weekends");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("row", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
output += "\n                        <tr>\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1) {
output += "\n                            <td rowspan=\"";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends")), env.opts.autoescape);
output += "\" width=\"130\">\n                                ";
output += runtime.suppressValue((lineno = 134, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Выходные"])), env.opts.autoescape);
output += "\n                            </td>\n                            ";
;
}
output += "\n                            <td>\n                                ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_47),"people")," ",true), env.opts.autoescape);
output += " <br>\n                                <b>";
output += runtime.suppressValue(runtime.memberLookup((t_47),"weekends"), env.opts.autoescape);
output += "</b>\n                            </td>\n                        </tr>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    ";
;
}
output += "\n                </table>\n                ";
;
}
output += "\n\n                <div class=\"Excursion-tourNote\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursStandartNote"), env.opts.autoescape);
output += "</div>\n            </div>\n            ";
;
}
output += "\n\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual")) > 0) {
output += "<div class=\"Excursion-tour\">\n                <h3 class=\"Excursion-textSectionSubTitle\">";
output += runtime.suppressValue((lineno = 153, colno = 62, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Только для вас"])), env.opts.autoescape);
output += "</h3>\n                ";
var t_48;
t_48 = [];
frame.set("rows_workdays_individual", t_48, true);
if(frame.topLevel) {
context.setVariable("rows_workdays_individual", t_48);
}
if(frame.topLevel) {
context.addExport("rows_workdays_individual", t_48);
}
output += "\n                ";
var t_49;
t_49 = [];
frame.set("rows_weekends_individual", t_49, true);
if(frame.topLevel) {
context.setVariable("rows_weekends_individual", t_49);
}
if(frame.topLevel) {
context.addExport("rows_weekends_individual", t_49);
}
output += "\n\n                ";
frame = frame.push();
var t_52 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividual");
if(t_52) {t_52 = runtime.fromIterator(t_52);
var t_51 = t_52.length;
for(var t_50=0; t_50 < t_52.length; t_50++) {
var t_53 = t_52[t_50];
frame.set("row", t_53);
frame.set("loop.index", t_50 + 1);
frame.set("loop.index0", t_50);
frame.set("loop.revindex", t_51 - t_50);
frame.set("loop.revindex0", t_51 - t_50 - 1);
frame.set("loop.first", t_50 === 0);
frame.set("loop.last", t_50 === t_51 - 1);
frame.set("loop.length", t_51);
output += "\n                    ";
if(runtime.memberLookup((t_53),"workdays")) {
output += "\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")),"push")) {
output += "\n                            ";
var t_54;
t_54 = (lineno = 160, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")),"push"), "rows_workdays_individual[\"push\"]", context, [t_53]));
frame.set("_", t_54, true);
if(frame.topLevel) {
context.setVariable("_", t_54);
}
output += "\n                        ";
;
}
else {
output += "\n                            ";
var t_55;
t_55 = (lineno = 162, colno = 70, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")),"append"), "rows_workdays_individual[\"append\"]", context, [t_53]));
frame.set("_", t_55, true);
if(frame.topLevel) {
context.setVariable("_", t_55);
}
output += "\n                        ";
;
}
output += "\n                    ";
;
}
output += "\n                    ";
if(runtime.memberLookup((t_53),"weekends")) {
output += "\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")),"push")) {
output += "\n                            ";
var t_56;
t_56 = (lineno = 167, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")),"push"), "rows_weekends_individual[\"push\"]", context, [t_53]));
frame.set("_", t_56, true);
if(frame.topLevel) {
context.setVariable("_", t_56);
}
output += "\n                        ";
;
}
else {
output += "\n                            ";
var t_57;
t_57 = (lineno = 169, colno = 70, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")),"append"), "rows_weekends_individual[\"append\"]", context, [t_53]));
frame.set("_", t_57, true);
if(frame.topLevel) {
context.setVariable("_", t_57);
}
output += "\n                        ";
;
}
output += "\n                    ";
;
}
output += "\n                ";
;
}
}
frame = frame.pop();
output += "\n\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")) > 0 || env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")) > 0) {
output += "\n                <table class=\"Excursion-tourTable\">\n                    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")) > 0) {
output += "\n                        ";
frame = frame.push();
var t_60 = runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual");
if(t_60) {t_60 = runtime.fromIterator(t_60);
var t_59 = t_60.length;
for(var t_58=0; t_58 < t_60.length; t_58++) {
var t_61 = t_60[t_58];
frame.set("row", t_61);
frame.set("loop.index", t_58 + 1);
frame.set("loop.index0", t_58);
frame.set("loop.revindex", t_59 - t_58);
frame.set("loop.revindex0", t_59 - t_58 - 1);
frame.set("loop.first", t_58 === 0);
frame.set("loop.last", t_58 === t_59 - 1);
frame.set("loop.length", t_59);
output += "\n                        <tr>\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1) {
output += "\n                            <td rowspan=\"";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_workdays_individual")), env.opts.autoescape);
output += "\" width=\"130\">\n                                ";
output += runtime.suppressValue((lineno = 181, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Будни"])), env.opts.autoescape);
output += "\n                            </td>\n                            ";
;
}
output += "\n                            <td>\n                                ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_61),"people")," ",true), env.opts.autoescape);
output += " <br>\n                                <b>";
output += runtime.suppressValue(runtime.memberLookup((t_61),"workdays"), env.opts.autoescape);
output += "</b>\n                            </td>\n                        </tr>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    ";
;
}
output += "\n\n                    ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")) > 0) {
output += "\n                        ";
frame = frame.push();
var t_64 = runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual");
if(t_64) {t_64 = runtime.fromIterator(t_64);
var t_63 = t_64.length;
for(var t_62=0; t_62 < t_64.length; t_62++) {
var t_65 = t_64[t_62];
frame.set("row", t_65);
frame.set("loop.index", t_62 + 1);
frame.set("loop.index0", t_62);
frame.set("loop.revindex", t_63 - t_62);
frame.set("loop.revindex0", t_63 - t_62 - 1);
frame.set("loop.first", t_62 === 0);
frame.set("loop.last", t_62 === t_63 - 1);
frame.set("loop.length", t_63);
output += "\n                        <tr>\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == 1) {
output += "\n                            <td rowspan=\"";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "rows_weekends_individual")), env.opts.autoescape);
output += "\" width=\"130\">\n                                ";
output += runtime.suppressValue((lineno = 197, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Выходные"])), env.opts.autoescape);
output += "\n                            </td>\n                            ";
;
}
output += "\n                            <td>\n                                ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.memberLookup((t_65),"people")," ",true), env.opts.autoescape);
output += " <br>\n                                <b>";
output += runtime.suppressValue(runtime.memberLookup((t_65),"weekends"), env.opts.autoescape);
output += "</b>\n                            </td>\n                        </tr>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    ";
;
}
output += "\n                </table>\n                ";
;
}
output += "\n\n                <div class=\"Excursion-tourNote\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"toursIndividualNote"), env.opts.autoescape);
output += "</div>\n            </div>\n            ";
;
}
output += "\n\n            <div class=\"Excursion-buyButtonWrapper\">\n                <div class=\"Excursion-buyButtonWrapperNote\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"buyButtonNote"))), env.opts.autoescape);
output += "\n                </div>\n\n                ";
output += runtime.suppressValue((lineno = 219, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": (runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "ru"?(lineno = 220, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Купить билет"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-tw"?(lineno = 220, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-hans"?(lineno = 220, colno = 120, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):(lineno = 220, colno = 163, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Buy a ticket"]))))))),"classes": "Button--tiny Excursion-button js-excursionBuyTicket","attrs": [["data-tc-showroom",""],["data-tc-token","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNjA0YjI1NWFiZTcxYzlmOWE1ZjhhZjA2In0.ykLp9ZAqkfuOjP1EcTJXXy3llmBE_kQ3-xJFLFKNqyY"]]})])), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"Excursion-asideInfoBlocks\">\n                <div class=\"Excursion-asideInfo Excursion-asideInfo--row\">\n                    <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--docs\">\n                        ";
output += runtime.suppressValue((lineno = 232, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-docs"])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Excursion-asideInfoText\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"docs"), env.opts.autoescape);
output += "\n                    </div>\n                </div>\n            </div>\n        </div>\n        ";
;
}
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")) > 0) {
output += "<div class=\"Excursion-textSection\">\n            <h2 class=\"Excursion-textSectionTitle\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 245, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Что вас ждёт на&nbsp;экскурсии?"]))), env.opts.autoescape);
output += "\n            </h2>\n\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")) > 1) {
output += "<ul class=\"Excursion-featuresList\">\n                ";
frame = frame.push();
var t_68 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features");
if(t_68) {t_68 = runtime.fromIterator(t_68);
var t_67 = t_68.length;
for(var t_66=0; t_66 < t_68.length; t_66++) {
var t_69 = t_68[t_66];
frame.set("feature", t_69);
frame.set("loop.index", t_66 + 1);
frame.set("loop.index0", t_66);
frame.set("loop.revindex", t_67 - t_66);
frame.set("loop.revindex0", t_67 - t_66 - 1);
frame.set("loop.first", t_66 === 0);
frame.set("loop.last", t_66 === t_67 - 1);
frame.set("loop.length", t_67);
output += "<li>";
output += runtime.suppressValue(runtime.memberLookup((t_69),"text"), env.opts.autoescape);
output += "</li>\n                ";
;
}
}
frame = frame.pop();
output += "</ul>\n            ";
;
}
else {
output += "\n            <div class=\"Excursion-textSectionContent\">\n                <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"features")),0)),"text"))), env.opts.autoescape);
output += "</p>\n            </div>\n            ";
;
}
output += "\n\n            <div class=\"Excursion-asideInfoBlocks\">\n                <div class=\"Excursion-asideInfo\">\n                    <div class=\"Excursion-asideInfoTime\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"durationTime"), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Excursion-asideInfoText\">\n                        ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"durationText")), env.opts.autoescape);
output += "\n                    </div>\n                </div>\n\n                <div class=\"Excursion-asideInfo\">\n                    <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--shoot\">\n                        ";
output += runtime.suppressValue((lineno = 272, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-shoot"])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Excursion-asideInfoText\">\n                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"shoot"), env.opts.autoescape);
output += "\n                    </div>\n                </div>\n            </div>\n        </div>\n        ";
;
}
output += "<div class=\"Excursion-slider\">\n            ";
output += runtime.suppressValue((lineno = 283, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderVariant"), "SliderVariant", context, [runtime.makeKeywordArgs({"slides": runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"slides"),"classes": "Slider--excursion"})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"Excursion-transportBus\">\n            ";
output += runtime.suppressValue((lineno = 287, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursionBus"])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"Excursion-textSection Excursion-textSection--beforeMap\">\n            <h2 class=\"Excursion-textSectionTitle\">\n                ";
output += runtime.suppressValue((lineno = 292, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Как добраться?"])), env.opts.autoescape);
output += "\n            </h2>\n\n            <div class=\"Excursion-textSectionContent\">\n                <h3 class=\"Excursion-textSectionSubTitle\">\n                    ";
output += runtime.suppressValue((lineno = 297, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бесплатный автобус"])), env.opts.autoescape);
output += "\n                </h3>\n\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"bus"))), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"Excursion-asideInfoBlocks\">\n                <div class=\"Excursion-asideInfo Excursion-asideInfo--row\">\n                    <div class=\"Excursion-asideInfoIcon Excursion-asideInfoIcon--time\">\n                        ";
output += runtime.suppressValue((lineno = 306, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["excursion-time"])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Excursion-asideInfoText\">\n                        ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"busSchedule")), env.opts.autoescape);
output += "\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"Excursion-map\">\n            <p>На&nbsp;карте показан маршрут до&nbsp;остановки фирменного автобуса:</p>\n\n            <div class=\"Excursion-mapContainerWrapper\">\n                <div class=\"Excursion-mapContainer\" id=\"map\"></div>\n            </div>\n        </div>\n\n        <div class=\"Excursion-textSection\">\n            <h3 class=\"Excursion-textSectionSubTitle\">\n                ";
output += runtime.suppressValue((lineno = 325, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["На машине"])), env.opts.autoescape);
output += "\n            </h3>\n\n            <div class=\"Excursion-textSectionContent\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"car"))), env.opts.autoescape);
output += "\n            </div>\n\n            <h3 class=\"Excursion-textSectionSubTitle\">\n                ";
output += runtime.suppressValue((lineno = 333, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Общественный транспорт"])), env.opts.autoescape);
output += "\n            </h3>\n\n            <div class=\"Excursion-textSectionContent\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"common"))), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Excursion-textSection\">\n            <h2 class=\"Excursion-textSectionTitle\">Политика возврата билетов</h2>\n\n            <div class=\"Excursion-textSectionContent\">\n                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"returnPolicy"))), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <div class=\"Excursion-textSection\" id=\"excursion-faq\">\n            <h2 class=\"Excursion-textSectionTitle\">Часто задаваемые вопросы</h2>\n            <div class=\"Excursion-faq FAQ\">\n                ";
frame = frame.push();
var t_72 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"questions");
if(t_72) {t_72 = runtime.fromIterator(t_72);
var t_71 = t_72.length;
for(var t_70=0; t_70 < t_72.length; t_70++) {
var t_73 = t_72[t_70];
frame.set("question", t_73);
frame.set("loop.index", t_70 + 1);
frame.set("loop.index0", t_70);
frame.set("loop.revindex", t_71 - t_70);
frame.set("loop.revindex0", t_71 - t_70 - 1);
frame.set("loop.first", t_70 === 0);
frame.set("loop.last", t_70 === t_71 - 1);
frame.set("loop.length", t_71);
output += "<div class=\"FAQ-item\">\n                        <div class=\"FAQ-header\">\n                            <div class=\"FAQ-headerTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_73),"question"), env.opts.autoescape);
output += "</div>\n                            <div class=\"FAQ-headerCross\">\n                                ";
output += runtime.suppressValue((lineno = 357, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["career-cross"])), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n                        <div class=\"FAQ-answer\">\n                            <div class=\"FAQ-answerContent\">\n                                ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((t_73),"answer"))), env.opts.autoescape);
output += "\n                            </div>\n                        </div>\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "</div>\n        </div>\n\n        <div class=\"Excursion-fixedButton\">\n            <div class=\"Excursion-fixedButtonColumn\">\n                ";
output += runtime.suppressValue((lineno = 372, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": (runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "ru"?(lineno = 373, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Расписание и билеты"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-tw"?(lineno = 373, colno = 84, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):((runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE") == "zh-hans"?(lineno = 373, colno = 127, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["买票"])):(lineno = 373, colno = 170, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Buy a ticket"]))))))),"classes": "Button--tiny Excursion-button","attrs": [["data-tc-showroom",""],["data-tc-token","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSJ9.eyJwIjoiNjA0YjI1NWFiZTcxYzlmOWE1ZjhhZjA2In0.ykLp9ZAqkfuOjP1EcTJXXy3llmBE_kQ3-xJFLFKNqyY"]]})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerTitle")) {
output += "\n        <div class=\"Excursion-disclaimer is-showed\">\n            <div class=\"Excursion-disclaimerContent\">\n                <button type=\"button\" class=\"Excursion-disclaimerClose\" aria-label=\"Закрыть дисклеймер\">\n                    <svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" aria-hidden=\"true\" focusable=\"false\">\n                        <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.7155 9.99939L19.9991 0.715787L19.2833 0L9.9997 9.2836L0.716699 0.000606046L0.000912092 0.716393L9.28391 9.99939L0 19.2833L0.715787 19.9991L9.9997 10.7152L19.2842 19.9997L20 19.2839L10.7155 9.99939Z\" fill=\"black\"/>\n                    </svg>\n                </button>\n\n                <h2 class=\"Excursion-disclaimerContentTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerTitle"), env.opts.autoescape);
output += "</h2>\n                <div class=\"Excursion-disclaimerContentText\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("nl2br").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "excursionData")),"popup")),"disclaimerText"))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n        </div>\n        ";
;
}
output += "\n\n        <div class=\"Excursion-close\">";
output += runtime.suppressValue((lineno = 400, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, [])), env.opts.autoescape);
output += "</div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_popup: b_popup,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Excursion/Excursion.jinja"] , dependencies)
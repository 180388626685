var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Link/Link.jinja"] = require( "front/components/Link/Link.jinja" );
dependencies["front/components/StyleTag/StyleTag.jinja"] = require( "front/components/StyleTag/StyleTag.jinja" );
dependencies["front/components/Share/Share.jinja"] = require( "front/components/Share/Share.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Brand/BrandDescription.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Brand/BrandDescription.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
env.getTemplate("front/components/Link/Link.jinja", false, "assets/app/mobile/pages/Brand/BrandDescription.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Link")) {
var t_8 = t_5.Link;
} else {
cb(new Error("cannot import 'Link'")); return;
}
context.setVariable("Link", t_8);
output += "\n";
env.getTemplate("front/components/StyleTag/StyleTag.jinja", false, "assets/app/mobile/pages/Brand/BrandDescription.jinja", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(Object.prototype.hasOwnProperty.call(t_9, "StyleTag")) {
var t_12 = t_9.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_12);
output += "\n";
env.getTemplate("front/components/Share/Share.jinja", false, "assets/app/mobile/pages/Brand/BrandDescription.jinja", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
if(Object.prototype.hasOwnProperty.call(t_13, "Share")) {
var t_16 = t_13.Share;
} else {
cb(new Error("cannot import 'Share'")); return;
}
context.setVariable("Share", t_16);
output += "\n\n";
var macro_t_17 = runtime.makeMacro(
[], 
["brand"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("brand", Object.prototype.hasOwnProperty.call(kwargs, "brand") ? kwargs["brand"] : {});var t_18 = "";t_18 += "\n    <div class=\"Brand-descriptionInner\">\n        <h1 class=\"Brand-title\">";
t_18 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_18 += "</h1>\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"outerLink")) {
t_18 += "\n            <div class=\"Brand-link\">\n                ";
t_18 += runtime.suppressValue((lineno = 12, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Link"), "Link", context, [runtime.makeKeywordArgs({"title": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"outerLinkTitle"),"href": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"outerLink"),"newTab": 1})])), env.opts.autoescape);
t_18 += "\n            </div>\n        ";
;
}
t_18 += "\n\n        <div class=\"Brand-social\">\n            ";
t_18 += runtime.suppressValue((lineno = 17, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Share"), "Share", context, [])), env.opts.autoescape);
t_18 += "\n        </div>\n\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"styles"))) {
t_18 += "\n            <div class=\"Brand-styles\">\n                <div class=\"Brand-stylesInner\">\n                    ";
frame = frame.push();
var t_21 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"styles");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("s", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
t_18 += "\n                        ";
t_18 += runtime.suppressValue((lineno = 24, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_22])), env.opts.autoescape);
t_18 += "\n                    ";
;
}
}
frame = frame.pop();
t_18 += "\n                </div>\n            </div>\n        ";
;
}
t_18 += "\n\n        <div class=\"Brand-factList\">\n            ";
frame = frame.push();
var t_25 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"facts");
if(t_25) {t_25 = runtime.fromIterator(t_25);
var t_24 = t_25.length;
for(var t_23=0; t_23 < t_25.length; t_23++) {
var t_26 = t_25[t_23];
frame.set("f", t_26);
frame.set("loop.index", t_23 + 1);
frame.set("loop.index0", t_23);
frame.set("loop.revindex", t_24 - t_23);
frame.set("loop.revindex0", t_24 - t_23 - 1);
frame.set("loop.first", t_23 === 0);
frame.set("loop.last", t_23 === t_24 - 1);
frame.set("loop.length", t_24);
t_18 += "\n                ";
if(runtime.memberLookup((t_26),"title")) {
t_18 += "\n                    <div class=\"Brand-fact\">\n                        <div class=\"Brand-factLabel\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_26),"label"), env.opts.autoescape);
t_18 += "</div>\n                        <div class=\"Brand-factTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_26),"title"), env.opts.autoescape);
t_18 += "</div>\n\n                        ";
if(runtime.memberLookup((t_26),"subtitle")) {
t_18 += "\n                            <div class=\"Brand-factSubtitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_26),"subtitle"), env.opts.autoescape);
t_18 += "</div>\n                        ";
;
}
t_18 += "\n                    </div>\n                ";
;
}
t_18 += "\n            ";
;
}
}
frame = frame.pop();
t_18 += "\n\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"package"))) {
t_18 += "\n                <div class=\"Brand-fact Brand-fact--package\">\n                    <div class=\"Brand-factLabel\">";
t_18 += runtime.suppressValue((lineno = 46, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Упаковка"])), env.opts.autoescape);
t_18 += "</div>\n                    \n                    <div class=\"Brand-packageList\">\n                        ";
frame = frame.push();
var t_29 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"package");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("p", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
t_18 += "\n                            <div class=\"Brand-package\">\n                                <span class=\"Brand-packageTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_30),"title"), env.opts.autoescape);
t_18 += "</span>\n                                <span class=\"Brand-packageVolume\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_30),"volume"), env.opts.autoescape);
t_18 += "</span>\n                            </div>\n                        ";
;
}
}
frame = frame.pop();
t_18 += "\n                    </div>\n                </div>\n            ";
;
}
t_18 += "\n        </div>\n\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"text")) {
t_18 += "\n            <div class=\"Brand-text\">\n                <div class=\"Brand-textInner\">\n                    <div class=\"Brand-textContent\">\n                        ";
t_18 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"text")), env.opts.autoescape);
t_18 += "\n                        <button class=\"Brand-buttonText\">";
t_18 += runtime.suppressValue((lineno = 65, colno = 69, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["ellipsis"])), env.opts.autoescape);
t_18 += "</button>\n                    </div>\n                </div>\n                <noscript>\n                    <style>\n                        .Brand-textInner {\n                            max-height: none !important;\n                        }\n                    </style>\n                </noscript>\n            </div>\n        ";
;
}
t_18 += "\n\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"snacks"))) {
t_18 += "\n            <div class=\"Brand-snackSection\">\n                <h2 class=\"Brand-snackHeader\">";
t_18 += runtime.suppressValue((lineno = 80, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["С чем пить?"])), env.opts.autoescape);
t_18 += "</h2>\n\n                <div class=\"Brand-snackList\">\n                    ";
frame = frame.push();
var t_33 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"snacks");
if(t_33) {t_33 = runtime.fromIterator(t_33);
var t_32 = t_33.length;
for(var t_31=0; t_31 < t_33.length; t_31++) {
var t_34 = t_33[t_31];
frame.set("s", t_34);
frame.set("loop.index", t_31 + 1);
frame.set("loop.index0", t_31);
frame.set("loop.revindex", t_32 - t_31);
frame.set("loop.revindex0", t_32 - t_31 - 1);
frame.set("loop.first", t_31 === 0);
frame.set("loop.last", t_31 === t_32 - 1);
frame.set("loop.length", t_32);
t_18 += "\n                        <div class=\"Brand-snack\">\n                            <div class=\"Brand-snackImg\">\n                                <div class=\"Brand-snackImgInner\">\n                                    ";
t_18 += runtime.suppressValue((lineno = 87, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["snack-" + runtime.memberLookup((t_34),"icon")])), env.opts.autoescape);
t_18 += "\n                                </div>\n                            </div>\n                            <div class=\"Brand-snackContent\">\n                                <div class=\"Brand-snackTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_34),"title"), env.opts.autoescape);
t_18 += "</div>\n                            </div>\n                        </div>\n                    ";
;
}
}
frame = frame.pop();
t_18 += "\n                </div>\n            </div>\n        ";
;
}
t_18 += "\n\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"awards"))) {
t_18 += "\n            <div class=\"Brand-awardsSection\">\n                <h2 class=\"Brand-awardsHeader\">";
t_18 += runtime.suppressValue((lineno = 101, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Награды"])), env.opts.autoescape);
t_18 += "</h2>\n\n                <div class=\"Brand-awardsList\">\n                    ";
frame = frame.push();
var t_37 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"awards");
if(t_37) {t_37 = runtime.fromIterator(t_37);
var t_36 = t_37.length;
for(var t_35=0; t_35 < t_37.length; t_35++) {
var t_38 = t_37[t_35];
frame.set("a", t_38);
frame.set("loop.index", t_35 + 1);
frame.set("loop.index0", t_35);
frame.set("loop.revindex", t_36 - t_35);
frame.set("loop.revindex0", t_36 - t_35 - 1);
frame.set("loop.first", t_35 === 0);
frame.set("loop.last", t_35 === t_36 - 1);
frame.set("loop.length", t_36);
t_18 += "\n                        <div class=\"Brand-award\">\n                            <div class=\"Brand-awardImg\">\n                                <div class=\"Brand-awardImgInner\">\n                                    ";
if(runtime.memberLookup((t_38),"img") && runtime.memberLookup((runtime.memberLookup((t_38),"img")),"w40_url")) {
t_18 += "\n                                        <img src=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_38),"img")),"w40_url"), env.opts.autoescape);
t_18 += "\" srcset=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_38),"img")),"w80_url"), env.opts.autoescape);
t_18 += " 2x\" alt=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((t_38),"award"), env.opts.autoescape);
t_18 += "\">\n                                    ";
;
}
t_18 += "\n                                </div>\n                            </div>\n                            <div class=\"Brand-awardContent\">\n                                <div class=\"Brand-awardTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_38),"title"), env.opts.autoescape);
t_18 += "</div>\n                                <div class=\"Brand-awardSubtitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_38),"award"), env.opts.autoescape);
t_18 += "</div>\n                            </div>\n                        </div>\n                    ";
;
}
}
frame = frame.pop();
t_18 += "\n                </div>\n            </div>\n        ";
;
}
t_18 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_18);
});
context.addExport("BrandDescription");
context.setVariable("BrandDescription", macro_t_17);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 126, colno = 23, runtime.callWrap(macro_t_17, "BrandDescription", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Brand/BrandDescription.jinja"] , dependencies)
var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
require('mobile/components/PostItem/PostItem');
require('mobile/components/ViewCount/ViewCount');
var MenuTop = require('mobile/components/MenuTop/MenuTop');
var Slider = require('mobile/components/Slider/Slider');

require('./About.less');

module.exports = Page.extend({
    template: require('./About.jinja'),

    el: '.About',

    apiUrl: '/api/index/',

    title: window.gettext('Компания') + ': ' + window.gettext('О нас'),

    initialize: function (options) {
        this.slider = new Slider({
            el: this.$('.Slider')
        });
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new MenuTop();
        
        
    },

    activate: function () {
        this.setTitle();
        this.slider.activate();
    }
});

var Backbone = require('backbone');
var _ = require('underscore');

var geolocation = require('geolocation');

require('front/components/LocationTooltip/LocationTooltip');
var LocationTooltipTemplate = require('front/components/LocationTooltip/LocationTooltip.jinja');
var LocationsBrandTemplate = require('front/components/Locations/LocationsBrand.jinja');

var MapStyles = require('./MapStyles');
var MOSCOW_COORDINATES = [37.587443, 55.752134];

require('./Locations.less');

module.exports = Backbone.View.extend({
    el: '.Locations',

    template: require('./Locations.jinja'),

    events: {
        'click .Locations-scaleButton--zoomIn': 'onZoomIn',
        'click .Locations-scaleButton--zoomOut': 'onZoomOut',
        'click .Locations-topItem': 'onSelectTopItem'
    },

    initialize: function (options) {
        _.bindAll(this, 'onSelectMarker');

        this.options = options || {};
    },

    loadLocations: function () {
        return new Promise(function (resolve, reject) {

            if (window.app.cache['/api/locations/data/'] !== undefined) {
                resolve(window.app.cache['/api/locations/data/']);

                return;
            }

            $.getJSON('/api/locations/data/').then(
                function (data) {
                    window.app.cache['/api/locations/data/'] = data;
                    resolve(data);
                }.bind(this)
            );

        });
    },

    initMap: function () {

        return new Promise(function (resolve, reject) {
            if (window.mapboxgl) {
                this.createMap().then(resolve);
            } else {
                this.checkAPIStatus().then(resolve);
            }

        }.bind(this));
    },

    checkAPIStatus: function () {

        return new Promise(function (resolve, reject) {
            setTimeout(
                function () {
                    if (window.mapboxgl) {
                        this.createMap().then(resolve);
                    } else {
                        this.checkAPIStatus().then(resolve);
                    }
                }.bind(this),
                100
            );
        }.bind(this));
    },

    createMap: function () {

        return new Promise(function (resolve, reject) {

            mapboxgl.accessToken = app.data.MAPBOX_API_KEY;

            this.map = new mapboxgl.Map({
                container: this.$('.Locations-map')[0],
                center: MOSCOW_COORDINATES,
                zoom: 12,
                style: 'mapbox://styles/alanotemiraev/cjf31ocjr1l7w2rjte6c1v5zt'
            });

            this.map.scrollZoom.disable();

            this.map.on('style.load', _.bind(function () {
                this.initMarkers(this.options.data, true);

                this.map.on('mousemove', _.bind(function (e) {
                    var features = this.map.queryRenderedFeatures(e.point, {
                        // layers: ['clusters', 'shops']
                        layers: ['shops']
                    });
                    this.map.getCanvas().style.cursor = features.length ? 'pointer' : '';
                }, this));

                this.map.on('click', 'shops', _.bind(function (e) {
                    var features = this.map.queryRenderedFeatures(e.point, {
                        layers: ['shops']
                    });

                    if (features.length) {
                        this.selectMarker(features[0].properties.id);
                    }

                }, this));

            }, this));

            geolocation.getCurrentPosition(function (err, position) {
                // if (err) throw err;
                if (err) {
                    console.error(err);

                    return;
                }
                this.map.setCenter([position.coords.longitude, position.coords.latitude]);
                this.map.zoomTo(15);
            }.bind(this));

            resolve();

        }.bind(this));
    },

    convertMarkers: function (data) {
        return data && data.locations && {
            type: 'FeatureCollection',
            features: data.locations.map(function (location) {
                return {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: location.c
                    },
                    properties: {
                        id: location.id
                    }
                };
            })
        } || {
            type: 'FeatureCollection',
            features: []
        };
    },

    initMarkers: function (data, force) {

        const _data = this.convertMarkers(data);
        _.extend(this.options.data, data);

        if (!this.map.isStyleLoaded() && !force) {
            return;
        }

        var source = this.map.getSource('shops');

        if (source) {
            source.setData(_data);

            return;
        }

        this.map.addSource('shops', {
            type: 'geojson',
            data: _data
        });


        this.map.addLayer({
            id: 'shops',
            type: 'circle',
            source: 'shops',
            // filter: ['!has', 'point_count'],
            paint: {
                'circle-color': '#e6102d',
                'circle-radius': 6,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        });

    },

    updateView: function (ids) {
        var popUps = this.$('.mapboxgl-popup');

        if (popUps[0]) {
            popUps.remove();
        }
        this.map.setFilter('shops', ['match', ['get', 'id'], ids.locations, true, false]);
    },

    onSelectMarker: function (e) {
        var id = parseInt($(e.target).attr('data-id'), 10);

        this.selectMarker(id);

        e.stopPropagation();
    },

    selectMarker: function (id) {
        var location = _.chain(this.options.data.locations)
            .findWhere({ id: id })
            .value();

        this.flyToStore(location);
        this.createPopUp(location);
    },

    flyToStore: function (location) {
        this.map.easeTo({
            center: location.c
            // zoom: 12
        });
    },

    createPopUp: function (location) {
        var popUps = this.$('.mapboxgl-popup');

        if (popUps[0]) {
            popUps.remove();
        }

        this.popup = new mapboxgl.Popup({ anchor: 'bottom' })
            .setLngLat(location.c)
            .setHTML(
                LocationTooltipTemplate.render({
                    render: true,
                    data: location
                })
            )
            .addTo(this.map);
    },

    onZoomIn: function () {
        this.map.zoomIn();
    },

    onZoomOut: function () {
        this.map.zoomOut();
    },

    onSelectTopItem: function (e) {
        var id = parseInt(
            $(e.target)
                .closest('.Locations-topItem')
                .attr('data-id'),
            10
        );

        this.selectMarker(id);
    },

    render: function () {
        this.setElement(this.template.render({ data: this.options.data, render: true }));

        return this;
    },

    destroy: function () {

        this.map.remove();
        this.map = null;

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});

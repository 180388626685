var Backbone = require('backbone');
var _ = require('underscore');
var Cookie = require('js-cookie');
var Velocity = require('velocity-animate');

require('mobile/components/ButtonIcon/ButtonIcon');

require('./Notify.less');

module.exports = Backbone.View.extend({
    template: require('./Notify.jinja'),

    events: {
        'click .Notify-close': 'closeNotify'
    },

    closeNotify: function () {
        Velocity.animate(this.$el[0], {
            translateY: 50
        }).then(function () {
            this.$el.hide();
        }.bind(this));
        Cookie.set('notifyHidden', 'true', { expires: 1 });
    },

    destroy: function () {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});

var Backbone = require('backbone');
var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var Slider = require('mobile/components/Slider/Slider');
require('./Excursion.less');
require('cleave.js/dist/addons/cleave-phone.ru.js');
var template = require('./Excursion.jinja');
var waitForTransitionEnd = require('waitForTransitionEnd');

module.exports = Page.extend({
    template: template,

    el: '.Excursion',

    events: {
        'click .Excursion-close': 'closeExcursion',
        'click .Excursion-toursHeader span': 'switchDays',
        'click .FAQ-item': 'selectQuestion',
        'click .FAQ-headerCross.isActive': 'hideQuestion',
        'click .Excursion-disclaimerClose': 'hideDisclaimer'
    },

    title: window.gettext('Экскурсия'),

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;

        this.shownLists = [];

        _.bindAll(this, '_backgroundParallax', 'switchDays', '_checkBuyButton');

        this.$excursionBackgroundImage = $('.Excursion-backgroundImage');

        // this.checkBuyButton = _.throttle(this._checkBuyButton, 100);
        $('.Excursion-fixedButton').addClass('is-showed');

        this.backgroundParallax = _.throttle(this._backgroundParallax, 10);
        app.els.$window.on('scroll', this.backgroundParallax);
        app.els.$window.on('scroll', this.checkBuyButton);

        this.$excursionDisclaimer = $('.Excursion-disclaimer');
    },

    activate: function () {
        app.views.topNav.activate();

        this.slider = new Slider({
            el: this.$('.Slider')
        });

        this.slider.activate();

        $('.Excursion-fixedButton').addClass('is-showed');

        const scripts = document.getElementsByTagName('script');
        const isYMapsLoaded = false;

        const yMapsScript = document.querySelector('script[src*="api-maps.yandex.ru"]');

        if (!yMapsScript) {
            this._loadMap().then(() => {
                ymaps.ready(this._initMap);
            });
        } else {
            ymaps.ready(this._initMap);
        }

        app.views.topNav.$el.removeClass('isOpened');
    },

    isInViewport: function (el) {
        if (el) {
            const rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    },

    _loadMap: function () {
        return new Promise(function (resolve, reject) {
            const tag = document.getElementsByTagName('script')[0];
            const el = document.createElement('script');

            el.src = 'https://api-maps.yandex.ru/2.1/?lang=ru-RU&apikey=07785efa-291d-4ae4-9e4a-72a6954325e5';
            el.async = false;

            el.onload = function () {
                resolve();
            };
            el.onerror = function () {
                reject(new Error(`Error while loading ${src}`));
            };

            tag.parentNode.insertBefore(el, tag.nextSibling || tag);
        });
    },

    _initMap: function () {
        // placemark = Грекова, 4. Остановка фирм автобуса
        const placemarkCoords = [55.884124, 37.661956];
        // medvedkovo = Ближайшая остановка метро
        const medvedkovoCoords = [55.886119, 37.661270];
        const excursionMap = new ymaps.Map('map', {
            center: placemarkCoords,
            zoom: 14,
            controls: []
        }, {
            searchControlProvider: 'yandex#search'
        });


        const drawRoutes = (from, to) => {
            const routesCollection = new ymaps.GeoObjectCollection();
            const routeByPedastrian = new ymaps.multiRouter.MultiRoute(
                {
                    referencePoints: [from, to],
                    params: {
                        routingMode: 'pedestrian'
                    }
                },
                {
                    wayPointFinishVisible: true,
                    routeActiveStrokeColor: '4359e2'
                },
            );

            routesCollection.add(routeByPedastrian);
            excursionMap.geoObjects.add(routesCollection);

            routeByPedastrian.model.events.add('requestsuccess', function () {
                excursionMap.setBounds(routeByPedastrian.getBounds());
            });
        };

        drawRoutes(medvedkovoCoords, placemarkCoords);
    },

    _backgroundParallax: function () {
        var scrollTop = app.els.$window.scrollTop();
        scrollTop = scrollTop * 0.3;
        var backgroundTop = scrollTop.toFixed(2) + 'px';

        this.$excursionBackgroundImage.css({
            transform: 'translate3d(0, ' + backgroundTop + ', 0)'
        });
    },

    _checkBuyButton: function () {
        // $('.Excursion-fixedButton').toggleClass('is-showed', !this.isInViewport(this.$('.js-excursionBuyTicket')[0]));
    },

    playIn: function () {
        return new Promise(
            function (resolve) {
                var $popupContainer = $('.PopupContainer');
                var $content = $('.Content');
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');

                // If is frontend append the new view and add animation class
                if (!this.options.server) {
                    $popupContainer.append(this.render().el);
                }

                waitForTransitionEnd(
                    $topNavInner,
                    'transform',
                    function () {
                        $topNavInner.css('visibility', 'hidden');
                        $content.children('.Notify').remove();
                    },
                    300
                );
                $topNavInner.addClass('TopNav-inner--isInactive');
                $content.addClass('Content--scalingOut');

                this.prevScrollPosition = app.els.$window.scrollTop();

                this.$el.outerHeight();
                this.$el.removeClass('Excursion--toAnimate');

                this.setTitle();

                var waitForTransition = new Promise(function (resolve) {
                    waitForTransitionEnd(
                        $contentMain,
                        'transform',
                        function () {
                            resolve();
                        },
                        600
                    );
                });

                waitForTransition.then(
                    function () {
                        this.activate();
                        app.els.$window.scrollTop(0);

                        setTimeout(
                            function () {
                                $contentMain.hide();
                                this.$el.css('position', 'relative');

                                resolve();
                            }.bind(this),
                            25
                        );
                    }.bind(this)
                );
            }.bind(this)
        );
    },

    playOut: function () {
        return new Promise(
            function (resolve) {
                var $contentMain = $('.Content-main');
                var $topNavInner = $('.TopNav--floatNav,.TopNav--fixed').find('.TopNav-inner');
                var $content = $('.Content');
                app.els.$body.removeClass('ExcursionPage');

                $contentMain.outerHeight();
                $contentMain.show();

                this.$el.outerHeight();
                this.$el.css({
                    position: 'fixed',
                    transform: 'translateY(' + (app.els.$window.scrollTop() + window.innerHeight) + 'px)'
                });
                $content.removeClass('Content--scalingOut');

                $topNavInner.css('visibility', 'visible');
                $topNavInner.removeClass('TopNav-inner--isInactive');

                $contentMain.css({
                    position: 'relative',
                    left: 0,
                    right: 0,
                    top: 0
                });

                app.els.$window.scrollTop(this.prevScrollPosition);

                var waitForTransition = new Promise(function (resolve) {
                    waitForTransitionEnd(
                        $contentMain,
                        'transform',
                        function () {
                            resolve();
                        },
                        600
                    );
                });

                waitForTransition.then(
                    function () {
                        this.destroy();
                    }.bind(this)
                );

                resolve();
            }.bind(this)
        );
    },

    switchDays: function (e) {
        var id = $(e.target).attr('data-id');
        var $parent = $(e.target).closest('.Excursion-schedule');
        $('.Excursion-toursDay', $parent)
            .removeClass('Excursion-toursDay--active')
            .filter('[data-id="' + id + '"]')
            .addClass('Excursion-toursDay--active');
    },

    closeExcursion: function () {
        if (app.state.oldUrl === undefined) {
            Backbone.history.navigate(Urls['front:index'](), { trigger: 'true' });
        }

        Backbone.history.navigate(app.state.oldUrl, { trigger: 'true' });
    },

    selectQuestion: function (e) {
        if (this.shownLists.length > 0) {
            var innerTextClick = $(e.currentTarget)[0].innerText;
            var innerTextShown = $(this.shownLists[0])[0].innerText;

            if (innerTextClick === innerTextShown) {
                return;
            }

            this.hideQuestion(e).then(
                function () {
                    this.showAnswer(e);
                }.bind(this)
            );
        } else {
            this.showAnswer(e);
        }
    },

    hideQuestion: function (e) {
        e.stopPropagation();

        return new Promise(
            function (resolve) {
                $(this.shownLists).each(
                    function (index, element) {
                        var $element = $(element);
                        var $elementCross = $element.find('.FAQ-headerCross');

                        $elementCross.removeClass('isActive');
                        var $list = $(element).find('.FAQ-answer');
                        $list.slideUp(
                            300,
                            'swing',
                            function () {
                                $list.attr('data-state', '');
                                $element.removeClass('isActive');

                                resolve();
                            }.bind(this)
                        );
                    }.bind(this)
                );

                this.shownLists = [];
            }.bind(this)
        );
    },

    showAnswer: function (e) {
        var $target = $(e.currentTarget);
        if ($target.hasClass('isActive')) {
            return;
        }
        var $targetCross = $target.find('.FAQ-headerCross');
        var $targetList = $(e.currentTarget).find('.FAQ-answer');

        $target.addClass('isActive');
        $targetCross.addClass('isActive');
        $targetList.attr('data-state', 'active').slideDown(300, 'swing');

        this.shownLists.push($target);
    },

    hideDisclaimer: function () {
        this.$('.Excursion-disclaimer').removeClass('is-showed');
    }
});

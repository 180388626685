var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Slider/SliderVariantCaption.jinja"] = require( "mobile/components/Slider/SliderVariantCaption.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/Slider/SliderVariant.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/Slider/SliderVariant.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
if(Object.prototype.hasOwnProperty.call(t_1, "calcgrid")) {
var t_5 = t_1.calcgrid;
} else {
cb(new Error("cannot import 'calcgrid'")); return;
}
context.setVariable("calcgrid", t_5);
output += "\n";
env.getTemplate("mobile/components/Slider/SliderVariantCaption.jinja", false, "assets/app/mobile/components/Slider/SliderVariant.jinja", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
t_6.getExported(function(t_8,t_6) {
if(t_8) { cb(t_8); return; }
if(Object.prototype.hasOwnProperty.call(t_6, "SliderVariantCaption")) {
var t_9 = t_6.SliderVariantCaption;
} else {
cb(new Error("cannot import 'SliderVariantCaption'")); return;
}
context.setVariable("SliderVariantCaption", t_9);
output += "\n\n";
var macro_t_10 = runtime.makeMacro(
[], 
["slides", "classes"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("slides", Object.prototype.hasOwnProperty.call(kwargs, "slides") ? kwargs["slides"] : []);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_11 = "";t_11 += "\n    <div class=\"Slider ";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_11 += "\" data-id=\"SliderVariant\">\n        <div class=\"Slider-items owl-carousel\">\n            ";
frame = frame.push();
var t_14 = runtime.contextOrFrameLookup(context, frame, "slides");
if(t_14) {t_14 = runtime.fromIterator(t_14);
var t_13 = t_14.length;
for(var t_12=0; t_12 < t_14.length; t_12++) {
var t_15 = t_14[t_12];
frame.set("s", t_15);
frame.set("loop.index", t_12 + 1);
frame.set("loop.index0", t_12);
frame.set("loop.revindex", t_13 - t_12);
frame.set("loop.revindex0", t_13 - t_12 - 1);
frame.set("loop.first", t_12 === 0);
frame.set("loop.last", t_12 === t_13 - 1);
frame.set("loop.length", t_13);
t_11 += "\n                <div class=\"Slider-item\"\n                    data-index=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
t_11 += "\"\n                    data-title=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((t_15),"title"), env.opts.autoescape);
t_11 += "\"\n                    data-link=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((t_15),"link"), env.opts.autoescape);
t_11 += "\"\n                    data-link-title=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((t_15),"linkTitle"), env.opts.autoescape);
t_11 += "\">\n                <img src=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_15),"img")),"w1024"), env.opts.autoescape);
t_11 += "\"\n                    srcset=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_15),"img")),"w1024"), env.opts.autoescape);
t_11 += " 1024w,\n                            ";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_15),"img")),"w1280"), env.opts.autoescape);
t_11 += " 1280w,\n                            ";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_15),"img")),"w1440"), env.opts.autoescape);
t_11 += " 1440w,\n                            ";
t_11 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_15),"img")),"w1920"), env.opts.autoescape);
t_11 += " 1920w\"\n                    sizes=\"calc((1152 / 1440) * 100vw)\"\n                    alt=\"";
t_11 += runtime.suppressValue(runtime.memberLookup((t_15),"title"), env.opts.autoescape);
t_11 += "\">\n                </div>\n            ";
;
}
}
frame = frame.pop();
t_11 += "\n        </div>\n        <div class=\"Slider-left\"></div>\n        <div class=\"Slider-right\"></div>\n        <div class=\"Slider-caption\">\n            <div class=\"Slider-counter\">\n                <span class=\"Slider-counterCurrent\">1</span>\n                <span> / </span>\n                <span class=\"Slider-counterTotal\">";
t_11 += runtime.suppressValue(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides")), env.opts.autoescape);
t_11 += "</span>\n            </div>\n            <div class=\"Slider-captionText\">\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "slides"))) {
t_11 += "\n                    ";
t_11 += runtime.suppressValue((lineno = 32, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderVariantCaption"), "SliderVariantCaption", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "slides")),0)])), env.opts.autoescape);
t_11 += "\n                ";
;
}
t_11 += "\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_11);
});
context.addExport("SliderVariant");
context.setVariable("SliderVariant", macro_t_10);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 40, colno = 18, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderAbout"), "SliderAbout", context, [runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes")})])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/Slider/SliderVariant.jinja"] , dependencies)
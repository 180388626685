var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
// var TopNav = require('front/components/TopNav/TopNav');
var Slider = require('mobile/components/Slider/Slider');
var Notify = require('mobile/components/Notify/Notify');
require('mobile/components/PostItem/PostItem');
require('mobile/components/ViewCount/ViewCount');

require('./Index.less');

module.exports = Page.extend({
    template: require('./Index.jinja'),

    el: '.Index',

    apiUrl: '/api/index/',

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
    },

    activate: function () {
        // this.indexNav = new TopNav({
        //     type: 'index'
        // });

        this.slider = new Slider({
            el: this.$('.Slider')
        });

        this.notify = new Notify({
            el: $('.Notify')
        });

        this.setTitle();

        // $('.Index-indexNav').append(this.indexNav.render().el);
        // this.indexNav.activate();
        this.slider.activate();
    },

    render: function () {
        this.setElement(
            this.template.render({
                slides: window.app.data.indexData.slides,
                postsPrimary: window.app.data.indexData.postsPrimary,
                postsSecondary: window.app.data.indexData.postsSecondary,
                info: window.app.data.indexData.info,
                notify: window.app.data.indexData.notify
            })
        );

        return this;
    }
});

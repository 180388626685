var Backbone = require('backbone');
var _ = require('underscore');
var queryString = require('query-string');
require('dotdotdot');

var Page = require('front/pages/Page/Page');
var TagListMedia = require('mobile/components/TagListMedia/TagListMedia');
var PostGrid = require('mobile/components/PostGrid/PostGrid');
var TopNav = require('mobile/components/TopNav/TopNav');
require('mobile/components/SectionBadge/SectionBadge');

require('./PostList.less');

module.exports = Page.extend({
    template: require('./PostList.jinja'),

    el: '.PostList',

    events: {
        'click .PostList-tagsButton': 'onShowTags',
        'click .SectionBadge': 'onClickSectionBadge'
    },

    title: window.gettext('Медиа'),

    initialize: function (options) {
        this.options = options;

        this.data = {
            sections: app.data.sections,
            activeSection: app.data.activeSection,
            hasMore: app.data.hasMore,
            posts: app.data.posts,
            favorite: app.data.favorite,
            news: app.data.news
        };

        this.state = new Backbone.Model({
            tags: app.data.activeTags,
            section: app.data.activeSection ? app.data.activeSection : {slug: 'all'}
        });

        this.listenTo(this.state, 'change:section', this.onChangeFilters);
        this.listenTo(this.state, 'change:tags', this.onChangeFilters);

        this.$tagsButton = this.$('.PostList-tagsButton');
    },

    activate: function () {
        this.setTitle();
        app.els.$body.addClass('PostListPage');

        app.views.topNav.detach();
        app.views.topNav = new TopNav();
        app.views.topNav.activate();

        this.postGrid = new PostGrid({
            section: this.state.get('section') !== 'all' ? this.state.get('section').id : null,
            tags: this.state.get('tags')
        });

        this.$('.PostList-newsTitle').dotdotdot({
            watch: true
        });

        this.TagListMedia = new TagListMedia({
            data: {
                tags: app.data.tags,
                activeTags: app.data.activeTags
            }
        });
        this.listenTo(this.TagListMedia, 'change:tags', this.onChangeTags);

        this.$tags = this.$('.StyleTag');
        this.$sections = this.$('.PostList-sectionItem').add('.TopNav-sectionItem');
        this.$sectionDescription = this.$('.PostList-description');
        this.$main = this.$('.PostList-main');
        this.$filters = this.$('.PostList-filters');
    },

    update: function () {
        this.setTitle();
        app.els.$body.addClass('PostListPage');
    },

    onShowTags: function () {
        this.TagListMedia.show();
    },

    onChangeTags: function (tags) {
        this.state.set('tags', tags);
        this.updateSection(this.state.get('section').slug);
    },

    onChangeFilters: function () {
        this.updateGetParams();
        this.updateFilters();
        app.views.topNav.onCloseSections();

        if (this.state.get('section').slug !== 'all') {
            this.$main.slideUp();

            app.els.$htmlBody.animate({
                scrollTop: (this.$sectionDescription.hasClass('isActive') && this.$sectionDescription.offset().top || this.$filters.offset().top) - 50
            });
        } else {
            this.$main.slideDown();
        }

        this.postGrid.filterData(this.state.toJSON());
    },

    onClickSectionBadge: function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url =
            $(e.currentTarget).attr('href') ||
            $(e.target)
                .closest('a')
                .attr('href');

        if (
            $(e.target)
                .closest('a')
                .attr('data-section') === this.state.get('section').slug
        ) {
            return;
        }

        Backbone.history.navigate(url, { trigger: true });
    },

    getQueryParams: function (qs) {
        qs = qs.split('+').join(' ');

        var params = {};
        var tokens;
        var re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    },

    updateSection: function (slug) {

        var tagsArg = this.getQueryParams(window.location.search).tags || '';

        if (tagsArg) {

            var tagsId = _.map(tagsArg.split(','), function (id) {
                return parseInt(id, 10);
            });

            var tags = this.TagListMedia.data.tags.filter(function (tag) {
                return tagsId.indexOf(tag.id) != -1;
            });

            this.TagListMedia.state.set('tags', tags);
        } else {
            this.TagListMedia.state.set('tags', []);
        };

        var section = this.data.sections.filter(function (section) {
            return section.slug === slug;
        })[0];

        if (!section) {
            section = {
                slug: 'all'
            };
        }

        this.state.set('section', section);
    },

    updateGetParams: function () {
        var params = queryString.parse(location.search);

        if (this.state.get('tags').length) {
            params.tags = _.pluck(this.state.get('tags'), 'id').join(',');
        } else {
            delete params.tags;
        }

        var stringified = queryString.stringify(params);
        if (stringified) {
            app.router.navigate(window.location.pathname + '?' + stringified, {trigger: true});
        } else {
            app.router.navigate(window.location.pathname, {trigger: true});
        }
    },

    updateFilters: function () {
        this.$sections.removeClass('isActive');
        this.$sections
            .filter(
                function (index, el) {
                    return $(el).attr('data-section') === this.state.get('section').slug;
                }.bind(this)
            )
            .addClass('isActive');
        this.$sectionDescription.toggleClass('isActive', !!this.state.get('section').description).html(this.state.get('section').description);
    }
});

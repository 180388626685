var Backbone = require('backbone');
var template = require('./NotFound.jinja');
var Page = require('front/pages/Page/Page');

require('./NotFound.less');

module.exports = Page.extend({
    el: '.NotFound',

    template: template,

    title: 'Not found',

    initialize: function () {}
});

var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Button/Button.jinja"] = require( "front/components/Button/Button.jinja" );
dependencies["front/components/StyleTag/StyleTag.jinja"] = require( "front/components/StyleTag/StyleTag.jinja" );
dependencies["mobile/components/BrandsGrid/BrandsGrid.jinja"] = require( "mobile/components/BrandsGrid/BrandsGrid.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/BrandsList/BrandsList.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/BrandsList/BrandsList.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += " ";
env.getTemplate("front/components/Button/Button.jinja", false, "assets/app/mobile/components/BrandsList/BrandsList.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Button")) {
var t_8 = t_5.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_8);
output += "\n";
env.getTemplate("front/components/StyleTag/StyleTag.jinja", false, "assets/app/mobile/components/BrandsList/BrandsList.jinja", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(Object.prototype.hasOwnProperty.call(t_9, "StyleTag")) {
var t_12 = t_9.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_12);
output += "\n";
env.getTemplate("mobile/components/BrandsGrid/BrandsGrid.jinja", false, "assets/app/mobile/components/BrandsList/BrandsList.jinja", false, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
t_13.getExported(function(t_15,t_13) {
if(t_15) { cb(t_15); return; }
if(Object.prototype.hasOwnProperty.call(t_13, "BrandsGrid")) {
var t_16 = t_13.BrandsGrid;
} else {
cb(new Error("cannot import 'BrandsGrid'")); return;
}
context.setVariable("BrandsGrid", t_16);
output += "\n";
var macro_t_17 = runtime.makeMacro(
[], 
["activeCategory", "activeSubCategory", "activeStyles", "categories", "brands"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("activeCategory", Object.prototype.hasOwnProperty.call(kwargs, "activeCategory") ? kwargs["activeCategory"] : {});frame.set("activeSubCategory", Object.prototype.hasOwnProperty.call(kwargs, "activeSubCategory") ? kwargs["activeSubCategory"] : {});frame.set("activeStyles", Object.prototype.hasOwnProperty.call(kwargs, "activeStyles") ? kwargs["activeStyles"] : []);frame.set("categories", Object.prototype.hasOwnProperty.call(kwargs, "categories") ? kwargs["categories"] : []);frame.set("brands", Object.prototype.hasOwnProperty.call(kwargs, "brands") ? kwargs["brands"] : []);var t_18 = "";t_18 += "\n    <div class=\"BrandsList\">\n        <nav class=\"BrandsList-categories\">\n            ";
frame = frame.push();
var t_21 = runtime.contextOrFrameLookup(context, frame, "categories");
if(t_21) {t_21 = runtime.fromIterator(t_21);
var t_20 = t_21.length;
for(var t_19=0; t_19 < t_21.length; t_19++) {
var t_22 = t_21[t_19];
frame.set("c", t_22);
frame.set("loop.index", t_19 + 1);
frame.set("loop.index0", t_19);
frame.set("loop.revindex", t_20 - t_19);
frame.set("loop.revindex0", t_20 - t_19 - 1);
frame.set("loop.first", t_19 === 0);
frame.set("loop.last", t_19 === t_20 - 1);
frame.set("loop.length", t_20);
t_18 += "\n            <a\n                class=\"BrandsList-category BrandsList-category--";
t_18 += runtime.suppressValue(runtime.memberLookup((t_22),"type"), env.opts.autoescape);
t_18 += " ";
t_18 += runtime.suppressValue((runtime.memberLookup((t_22),"type") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "activeCategory")),"type")?"isActive":""), env.opts.autoescape);
t_18 += "\"\n                href=\"";
t_18 += runtime.suppressValue((lineno = 9, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:brands-category",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_22),"type")]})])), env.opts.autoescape);
t_18 += "\"\n            >\n                <div class=\"BrandsList-categoryIcon\">";
t_18 += runtime.suppressValue((lineno = 11, colno = 65, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["category-" + runtime.memberLookup((t_22),"type")])), env.opts.autoescape);
t_18 += "</div>\n                <div class=\"BrandsList-categoryTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((t_22),"title"), env.opts.autoescape);
t_18 += "</div>\n                <div class=\"BrandsList-categorySvg\">";
t_18 += runtime.suppressValue((lineno = 13, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["bump"])), env.opts.autoescape);
t_18 += "</div>\n            </a>\n            ";
;
}
}
frame = frame.pop();
t_18 += "\n        </nav>\n\n        <div class=\"BrandsList-content\">\n            <div class=\"BrandsList-subCategories\">\n                <!-- <header class=\"BrandsList-subCategoriesTitle\">";
t_18 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "activeCategory")),"title"), env.opts.autoescape);
t_18 += "</header> -->\n                <div class=\"BrandsList-subCategoriesList\">\n                    <button\n                        class=\"BrandsList-subCategory ";
t_18 += runtime.suppressValue((!runtime.contextOrFrameLookup(context, frame, "activeSubCategory")?"isActive":""), env.opts.autoescape);
t_18 += "\"\n                        data-sub-category=\"all\"\n                    >\n                        ";
t_18 += runtime.suppressValue((lineno = 26, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Все"])), env.opts.autoescape);
t_18 += "\n                    </button>\n                    ";
frame = frame.push();
var t_25 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "activeCategory")),"subCategories");
if(t_25) {t_25 = runtime.fromIterator(t_25);
var t_24 = t_25.length;
for(var t_23=0; t_23 < t_25.length; t_23++) {
var t_26 = t_25[t_23];
frame.set("s", t_26);
frame.set("loop.index", t_23 + 1);
frame.set("loop.index0", t_23);
frame.set("loop.revindex", t_24 - t_23);
frame.set("loop.revindex0", t_24 - t_23 - 1);
frame.set("loop.first", t_23 === 0);
frame.set("loop.last", t_23 === t_24 - 1);
frame.set("loop.length", t_24);
t_18 += "\n                    <button\n                        class=\"BrandsList-subCategory ";
t_18 += runtime.suppressValue((runtime.memberLookup((t_26),"slug") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "activeSubCategory")),"slug")?"isActive":""), env.opts.autoescape);
t_18 += "\"\n                        data-sub-category=\"";
t_18 += runtime.suppressValue(runtime.memberLookup((t_26),"slug"), env.opts.autoescape);
t_18 += "\"\n                    >\n                        ";
t_18 += runtime.suppressValue(runtime.memberLookup((t_26),"title"), env.opts.autoescape);
t_18 += "\n                    </button>\n                    ";
;
}
}
frame = frame.pop();
t_18 += "\n                </div>\n                <div class=\"BrandsList-subCategoriesLabel ";
t_18 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "activeStyles")?"BrandsList-subCategoriesLabel--active":""), env.opts.autoescape);
t_18 += "\">";
t_18 += runtime.suppressValue((lineno = 37, colno = 141, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["label"])), env.opts.autoescape);
t_18 += "</div>\n            </div>\n\n            <!--\n                ";
var t_27;
t_27 = (lineno = 41, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["На карте"]));
frame.set("mapButtonText", t_27, true);
if(frame.topLevel) {
context.setVariable("mapButtonText", t_27);
}
if(frame.topLevel) {
context.addExport("mapButtonText", t_27);
}
t_18 += "\n\n                <div class=\"BrandsList-map\">";
t_18 += runtime.suppressValue((lineno = 43, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"tag": "a","title": runtime.contextOrFrameLookup(context, frame, "mapButtonText"),"classes": "Button--grow Button--small u-Route","href": (lineno = 46, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:locations"]))})])), env.opts.autoescape);
t_18 += "</div>\n            -->\n            <div class=\"BrandsList-grid\">";
t_18 += runtime.suppressValue((lineno = 48, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandsGrid"), "BrandsGrid", context, [runtime.makeKeywordArgs({"brands": runtime.contextOrFrameLookup(context, frame, "brands")})])), env.opts.autoescape);
t_18 += "</div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_18);
});
context.addExport("BrandsList");
context.setVariable("BrandsList", macro_t_17);
output += "\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 53, colno = 17, runtime.callWrap(macro_t_17, "BrandsList", context, [runtime.contextOrFrameLookup(context, frame, "activeCategory"),runtime.contextOrFrameLookup(context, frame, "activeSubCategory"),runtime.contextOrFrameLookup(context, frame, "activeStyles"),runtime.contextOrFrameLookup(context, frame, "categories")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/BrandsList/BrandsList.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/Link/Link.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/Link/Link.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["classes", "title", "href", "icon", "newTab"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("title", Object.prototype.hasOwnProperty.call(kwargs, "title") ? kwargs["title"] : "");frame.set("href", Object.prototype.hasOwnProperty.call(kwargs, "href") ? kwargs["href"] : "");frame.set("icon", Object.prototype.hasOwnProperty.call(kwargs, "icon") ? kwargs["icon"] : "");frame.set("newTab", Object.prototype.hasOwnProperty.call(kwargs, "newTab") ? kwargs["newTab"] : 0);var t_6 = "";t_6 += "\n    <a class=\"Link ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_6 += " ";
t_6 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "icon")?"Link--icon-" + runtime.contextOrFrameLookup(context, frame, "icon"):""), env.opts.autoescape);
t_6 += "\" \n        href=\"";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "href"), env.opts.autoescape);
t_6 += "\" \n        ";
t_6 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "newTab")?"target=\"_blank\"":""), env.opts.autoescape);
t_6 += ">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "icon") == "arrowInfo") {
t_6 += "\n            ";
t_6 += runtime.suppressValue((lineno = 7, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["arrowInfo"])), env.opts.autoescape);
t_6 += "\n        ";
;
}
t_6 += "\n        \n        ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "title"), env.opts.autoescape);
t_6 += "\n        \n        ";
if(runtime.contextOrFrameLookup(context, frame, "icon") == "link") {
t_6 += "\n            ";
t_6 += runtime.suppressValue((lineno = 13, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["link"])), env.opts.autoescape);
t_6 += "\n        ";
;
}
t_6 += "\n    </a>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Link");
context.setVariable("Link", macro_t_5);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/Link/Link.jinja"] , dependencies)
var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
require('mobile/components/PostItem/PostItem');
require('mobile/components/ViewCount/ViewCount');
var LegalMenuTop = require('mobile/components/LegalMenuTop/LegalMenuTop');

require('./LegalDocuments.less');

module.exports = Page.extend({
    template: require('./LegalDocuments.jinja'),

    el: '.LegalDocuments',

    apiUrl: '/api/index/',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Документы'),

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new LegalMenuTop();
    },

    activate: function () {
        this.setTitle();
    }
});

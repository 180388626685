var _ = require('underscore');

var Page = require('front/pages/Page/Page');
var TopNav = require('mobile/components/TopNav/TopNav');
var BrandsList = require('mobile/components/BrandsList/BrandsList');
var TagList = require('mobile/components/TagList/TagList');

require('./Brands.less');

module.exports = Page.extend({
    template: require('./Brands.jinja'),

    el: '.Brands',

    title: window.gettext('Бренды'),

    events: {
        'click .BrandsList-subCategoriesLabel': 'renderPopup'
    },

    initialize: function () {
        _.bindAll(this, '_backgroundParallax', 'onChangeTags');

        this.topNav = new TopNav({
            el: this.$('.TopNav'),
            type: 'sticky'
        });

        this.headType = this.$('.Brands-head').attr('data-head-type');

        var subCategory;
        if (app.data.subCategory) {
            subCategory = app.data.subCategory;
        } else {
            subCategory = {
                slug: 'all'
            };
        }

        this.brandsList = new BrandsList({
            el: this.$('.BrandsList'),
            data: {
                subCategory: subCategory,
                styles: app.data.activeStyles,
                category: app.data.category
            }
        });

        this.$background = $('.Brands-headMedia');

        this.tagList = new TagList({});

        this.tagList.on('change:tags', this.onChangeTags);

        $('.BrandsList').prepend(this.tagList.render().$el);

        this.backgroundParallax = _.throttle(this._backgroundParallax, 10);
        app.els.$window.on('scroll', this.backgroundParallax);

        this.title = window.gettext('Бренды') + ': ' + app.data.category.title;
        this.setTitle();
    },

    activate: function () {
        this.topNav.activate();
        this.update();
    },

    getQueryParams: function (qs) {
        qs = qs.split('+').join(' ');

        var params = {};
        var tokens;
        var re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }

        return params;
    },

    update: function () {
        var tagsArg = this.getQueryParams(window.location.search).styles || '';
        var subcategoryArg = this.getQueryParams(window.location.search).subCategory;

        if (tagsArg) {

            var tagsId = _.map(tagsArg.split(','), function (id) {
                return parseInt(id, 10);
            });

            var tags = _.sortBy(app.data.styles, function (i) {
                return i.id;
            }).filter(function (tag) {
                return tagsId.indexOf(tag.id) !== -1;
            });

            this.brandsList.state.set('styles', tags);
            this.tagList.state.set('activeTags', tags);
        } else {
            this.brandsList.state.set('styles', []);
            this.tagList.state.set('activeTags', []);
        }

        var subCategory = app.data.category.subCategories.filter(function (subCategory) {
            return subCategory.slug === subcategoryArg;
        })[0];

        if (!subCategory) {
            subCategory = {
                slug: 'all'
            };
        }

        this.brandsList.state.set('subCategory', subCategory);
    },

    renderPopup: function () {
        this.tagList.show();
    },

    onChangeTags: function (tags) {
        this.brandsList.state.set('styles', tags);
    },

    _backgroundParallax: function () {
        var scrollTop = app.els.$window.scrollTop();
        scrollTop = scrollTop * 0.3;
        var backgroundTop = scrollTop.toFixed(2) + 'px';

        this.$background.css({
            transform: 'translate3d(0, ' + backgroundTop + ', 0)'
        });
    }
});

var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Career/Career.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Career/Career.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Career/Career.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/Career/Career.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TopNav")) {
var t_11 = t_8.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_11);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
output += t_12;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_15,t_14) {
if(t_15) { cb(t_15); return; }
output += t_14;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
output += t_16;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
output += t_18;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 5;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 5, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 6, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 11, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--career","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 11, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Career\">\n    <div class=\"Career-floatNav\"></div>\n    <div class=\"Career-bg\">\n        <picture>\n            <source srcset=\"/static/img/front/career/bg.png 1x, /static/img/front/career/bg@2x.png 2x\" media=\"min-width: 768px\" />\n            <img\n                class=\"Career-bgImage\"\n                src=\"/static/img/front/career/bg-mobile.png\"\n                srcset=\"/static/img/front/career/bg-mobile.png 1x, /static/img/front/career/bg-mobile@2x.png 2x\"\n                alt=\"\"\n                decoding=\"async\"\n            />\n        </picture>\n    </div>\n    <div class=\"Career-main\">\n        <div class=\"Career-header\">\n            <div class=\"Career-subtitle\">\n                <span class=\"Career-subtitleStroke\"></span>\n                <div class=\"Career-subtitleText\">";
output += runtime.suppressValue((lineno = 33, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <h1 class=\"Career-title\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"title"), env.opts.autoescape);
output += "\n            </h1>\n            <div class=\"Career-text\">\n                <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"description")), env.opts.autoescape);
output += "</p>\n            </div>\n        </div>\n        <div class=\"Career-container\">\n            ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages")) > 0) {
output += "<section class=\"Career-section\">\n                <h2 class=\"Career-sectionTitle\">Что мы предлагаем нашим сотрудникам</h2>\n                <div class=\"CareerAdvantages\">\n                    ";
frame = frame.push();
var t_24 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages");
if(t_24) {t_24 = runtime.fromIterator(t_24);
var t_23 = t_24.length;
for(var t_22=0; t_22 < t_24.length; t_22++) {
var t_25 = t_24[t_22];
frame.set("item", t_25);
frame.set("loop.index", t_22 + 1);
frame.set("loop.index0", t_22);
frame.set("loop.revindex", t_23 - t_22);
frame.set("loop.revindex0", t_23 - t_22 - 1);
frame.set("loop.first", t_22 === 0);
frame.set("loop.last", t_22 === t_23 - 1);
frame.set("loop.length", t_23);
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0") < (env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages")) - 1)) {
output += "\n                        <div class=\"CareerAdvantages-item\">\n                            ";
if(runtime.memberLookup((t_25),"text")) {
output += "\n                            <h3 class=\"CareerAdvantages-itemTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_25),"text"), env.opts.autoescape);
output += "</h3>\n                            ";
;
}
output += "\n\n                            ";
if(runtime.memberLookup((t_25),"img")) {
output += "\n                            <div class=\"CareerAdvantages-itemImage\">\n                                <img\n                                    src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w768"), env.opts.autoescape);
output += "\"\n                                    srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w640"), env.opts.autoescape);
output += " 640vw,\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w768"), env.opts.autoescape);
output += " 768vw,\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w1024"), env.opts.autoescape);
output += " 1024vw,\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w1280"), env.opts.autoescape);
output += " 1280vw,\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w1440"), env.opts.autoescape);
output += " 1440vw,\n                                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_25),"img")),"w1920"), env.opts.autoescape);
output += " 1920vw\"\n                                    alt=\"";
output += runtime.suppressValue(runtime.memberLookup((t_25),"text"), env.opts.autoescape);
output += "\"\n                                    decoding=\"async\"\n                                >\n                            </div>\n                            ";
;
}
output += "\n                        </div>\n                        ";
;
}
output += "\n                    ";
;
}
}
frame = frame.pop();
output += "</div>\n\n                <div class=\"CareerAdvantageMain\">\n                    <div class=\"CareerAdvantageMain-text\">\n                        ";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"value")), env.opts.autoescape);
output += "\n                    </div>\n                    ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages")) > 0) {
output += "\n                    <div class=\"CareerAdvantageMain-img\">\n                        ";
var t_26;
t_26 = runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages")),env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"advantages")) - 1);
frame.set("commonImg", t_26, true);
if(frame.topLevel) {
context.setVariable("commonImg", t_26);
}
if(frame.topLevel) {
context.addExport("commonImg", t_26);
}
output += "\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "commonImg")) {
output += "\n                        <img\n                            src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w1280"), env.opts.autoescape);
output += "\"\n                            srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w640"), env.opts.autoescape);
output += " 640vw,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w768"), env.opts.autoescape);
output += " 768vw,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w1024"), env.opts.autoescape);
output += " 1024vw,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w1280"), env.opts.autoescape);
output += " 1280vw,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w1440"), env.opts.autoescape);
output += " 1440vw,\n                                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "commonImg")),"img")),"w1920"), env.opts.autoescape);
output += " 1920vw\"\n                            alt=\"\"\n                            decoding=\"async\"\n                        >\n                        ";
;
}
output += "\n                    </div>\n                    ";
;
}
output += "\n                </div>\n            </section>\n            ";
;
}
output += "\n\n            ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "careerDirections")) > 0) {
output += "<section class=\"Career-section\" id=\"vacancies\">\n                <h2 class=\"Career-sectionTitle\">Вакансии</h2>\n                <ul class=\"CareerDirections CareerDirections--main\">\n                    ";
frame = frame.push();
var t_29 = runtime.contextOrFrameLookup(context, frame, "careerDirections");
if(t_29) {t_29 = runtime.fromIterator(t_29);
var t_28 = t_29.length;
for(var t_27=0; t_27 < t_29.length; t_27++) {
var t_30 = t_29[t_27];
frame.set("item", t_30);
frame.set("loop.index", t_27 + 1);
frame.set("loop.index0", t_27);
frame.set("loop.revindex", t_28 - t_27);
frame.set("loop.revindex0", t_28 - t_27 - 1);
frame.set("loop.first", t_27 === 0);
frame.set("loop.last", t_27 === t_28 - 1);
frame.set("loop.length", t_28);
output += "<li class=\"CareerDirections-item\">\n                        <a class=\"CareerDirections-itemLink\" href=\"/career/";
output += runtime.suppressValue(runtime.memberLookup((t_30),"slug"), env.opts.autoescape);
output += "\">\n                            <div class=\"CareerDirections-itemBg\">\n                                <img\n                                    src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_30),"img")),"w370"), env.opts.autoescape);
output += "\"\n                                    srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_30),"img")),"w370"), env.opts.autoescape);
output += " 1x, ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_30),"img")),"w768"), env.opts.autoescape);
output += " 2x\"\n                                    alt=\"";
output += runtime.suppressValue(runtime.memberLookup((t_30),"slug"), env.opts.autoescape);
output += "\"\n                                    loading=\"lazy\"\n                                    decoding=\"async\"\n                                >\n                            </div>\n                            <h3 class=\"CareerDirections-itemTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_30),"name"), env.opts.autoescape);
output += "</h3>\n                        </a>\n                    </li>\n                    ";
;
}
}
frame = frame.pop();
output += "</ul>\n            </section>\n            ";
;
}
output += "\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Career/Career.jinja"] , dependencies)
var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
require('mobile/components/PostItem/PostItem');
require('mobile/components/ViewCount/ViewCount');
var LegalMenuTop = require('mobile/components/LegalMenuTop/LegalMenuTop');
var LegalForm = require('mobile/components/LegalForms/LegalForm/LegalForm');

require('./Legal.less');

module.exports = Page.extend({
    template: require('./Legal.jinja'),

    el: '.Legal',

    apiUrl: '/api/index/',

    title: window.gettext('Для партнеров') + ': ' + window.gettext('Стать поставщиком транспортных услуг'),

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new LegalMenuTop();
        this.legalForm = new LegalForm();
    },

    activate: function () {
        this.setTitle();
    }
});

var Backbone = require('backbone');
var _ = require('underscore');
require('isotope-layout/dist/isotope.pkgd.js');

require('./PostGrid.less');
var PostItem = require('mobile/components/PostItem/PostItem');
var PostItemTemplate = require('mobile/components/PostItem/PostItem.jinja');

module.exports = Backbone.View.extend({
    el: '.PostGrid',

    template: require('./PostGrid.jinja'),

    initialize: function (options) {
        _.bindAll(this, '_onScroll');

        this.options = options || {};

        this.state = new Backbone.Model({
            scrollPagination: false
        });

        this.section = options.section;
        this.tags = options.tags;

        this.$el.isotope({
            itemSelector: '.PostItem',
            getSortData: {
                number: '[data-order] parseInt'
            },
            sortBy: 'number',
            sortAscending: false,
            masonry: {
                gutter: '.PostGrid-gutter'
            }
        });
        this.$el.addClass('isReady');

        this.$loader = this.$('.PostGrid-loader');
        this.$centerLoader = this.$('.PostGrid-centerLoader');

        if (app.data.hasMore) {
            this.onScroll = _.throttle(this._onScroll, 50);
            this.state.set('scrollPagination', true);

            app.els.$window.on('scroll', this.onScroll);
        }
    },

    filterData: function (params) {
        var fetchParams = {
            offset: 0,
            limit: 12
        };

        this.section = params.section.id;
        this.tags = _.map(params.tags, function (item) {
            return item.id;
        });

        if (this.section) {
            fetchParams.section = this.section;
        }

        if (this.tags.length) {
            fetchParams.tags = this.tags;
        }

        this.fetchData(fetchParams);
    },

    fetchData: function (params) {
        this.state.set('isLoading', false);
        this.$centerLoader.addClass('isActive');
        if (this.loadingRequest) {
            this.loadingRequest.abort();
        }
        this.state.set('scrollPagination', false);
        app.els.$window.off('scroll', this.onScroll);

        $.ajax({
            url: '/api/posts/?lang=' + app.data.lang,
            dataType: 'json',
            data: params,
            success: function (data) {
                this.$centerLoader.removeClass('isActive');

                var $currentElems = this.$('.PostItem');

                if (data.hasMore && !this.state.get('scrollPagination')) {
                    this.state.set('scrollPagination', true);
                    app.els.$window.on('scroll', this.onScroll);
                }

                var $addedElems = $(
                    _.map(data.posts, function (post) {
                        return $(
                            PostItemTemplate.render({
                                post: post,
                                render: true
                            })
                        )[0];
                    })
                );

                this.$el.isotope({
                    stagger: 0,
                    visibleStyle: {
                        opacity: 1,
                        transform: 'translateY(0)'
                    },
                    hiddenStyle: {
                        opacity: 0
                    }
                });

                this.$el.isotope('remove', $currentElems);

                setTimeout(
                    function () {
                        this.$el.isotope({
                            stagger: 50,
                            visibleStyle: {
                                opacity: 1,
                                transform: 'translateY(0)'
                            },
                            hiddenStyle: {
                                opacity: 0,
                                transform: 'translateY(50px)'
                            }
                        });

                        this.$el
                            .isotope('insert', $addedElems)
                            .isotope('updateSortData')
                            .isotope('layout');
                    }.bind(this),
                    400
                );
            }.bind(this)
        });
    },

    _onScroll: function () {
        if (this.state.get('isLoading')) {
            return;
        }

        var windowBottom = app.els.$window.scrollTop() + app.els.$window.height();
        var gridBottom = this.$el.offset().top + this.$el.height();

        if (windowBottom > gridBottom) {
            this.loadMore();
        }
    },

    loadMore: function () {
        this.state.set('isLoading', true);
        this.$loader.addClass('isActive');
        this.state.set('scrollPagination', false);
        app.els.$window.off('scroll', this.onScroll);

        var fetchParams = {
            offset: this.$('.PostItem').length,
            limit: 12
        };

        if (this.section) {
            fetchParams.section = this.section;
        }

        if (this.tags.length) {
            fetchParams.tags = this.tags;
        }

        this.loadingRequest = $.ajax({
            url: '/api/posts/?lang=' + app.data.lang,
            dataType: 'json',
            data: fetchParams,
            success: function (data) {
                this.state.set('isLoading', false);
                this.$loader.removeClass('isActive');

                if (data.hasMore) {
                    app.els.$window.on('scroll', this.onScroll);
                    this.state.set('scrollPagination', true);
                }

                var $addedElems = $(
                    _.map(data.posts, function (post) {
                        return $(
                            PostItemTemplate.render({
                                post: post,
                                render: true
                            })
                        )[0];
                    })
                );

                this.$el.isotope({
                    stagger: 50,
                    visibleStyle: {
                        opacity: 1,
                        transform: 'translateY(0)'
                    },
                    hiddenStyle: {
                        opacity: 0,
                        transform: 'translateY(100px)'
                    }
                });

                this.$el.append($addedElems);
                this.$el.isotope('appended', $addedElems).isotope('layout');
            }.bind(this)
        });
    },

    deactivate: function () {
        this.$el.isotope('destroy');

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});

var Backbone = require('backbone');
var _ = require('underscore');
var waitForTransitionEnd = require('waitForTransitionEnd');

require('mobile/components/Button/Button');
require('mobile/components/StyleTag/StyleTag');

require('./TagList.less');
var template = require('./TagList.jinja');

module.exports = Backbone.View.extend({

    el: '.TagList',

    template: template,

    events: {
        'click .StyleTag': 'onChangeTag',
        'click .TagList-close': 'onClose',
        'click .TagList-overlay': 'onClose',
        'click .TagList-submit': 'onSubmit'
    },

    initialize: function (options) {
        this.data = options.data;
        this.onChange = options.onChange;

        this.state = new Backbone.Model({
            tagsInitial: [],
            tags: app.data.styles,
            activeTags: app.data.activeStyles
        });

        this.$content = this.$('.TagList-content');
    },

    show: function () {
        this.$el.addClass('isActive');
        this.state.set('tagsInitial', _.clone(this.state.get('activeTags')));
        this.updateTags();
        this.checkDisabled();
    },

    close: function () {
        return new Promise(
            function (resolve) {
                this.$el.removeClass('isActive');

                waitForTransitionEnd(
                    this.$content,
                    'transform',
                    function () {
                        resolve();
                    }.bind(this),
                    300
                );
            }.bind(this)
        );
    },

    onClose: function () {
        this.close().then(
            function () {
                this.state.set('activeTags', _.clone(this.state.get('tagsInitial')));
                this.updateTags();
            }.bind(this)
        );
    },

    onSubmit: function () {
        this.trigger('change:tags', this.state.get('activeTags'));
        this.close();
    },

    onChangeTag: function (e) {
        var id = parseInt(
            $(e.target)
                .closest('.StyleTag')
                .attr('data-id'),
            10
        );

        var containedTags = _.findWhere(this.state.get('activeTags'), { id: id });
        if (containedTags) {
            var newTags = _.without(this.state.get('activeTags'), containedTags);
        } else {
            var addedTag = this.state.get('tags').filter(function (tag) {
                return tag.id === id;
            });
            var newTags = _.union(this.state.get('activeTags'), addedTag);
        }

        newTags = _.sortBy(newTags, function (i) {
            return i.id;
        });

        this.state.set('activeTags', newTags);

        this.updateTags();
    },

    updateTags: function () {
        var ids = _.pluck(this.state.get('activeTags'), 'id');

        this.$tags.removeClass('isActive')
            .filter(
                function (index, el) {
                    return ids.indexOf(parseInt($(el).attr('data-id'), 10)) != -1;
                }
            )
            .addClass('isActive');

        this.checkDisabled();
    },

    checkDisabled: function () {
        var newTags = this.state.get('activeTags').map(function (item) {
            return item.id;
        })
            .sort();
        var initialTags = this.state.get('tagsInitial').map(function (item) {
            return item.id;
        })
            .sort();

        if (_.isEqual(newTags, initialTags)) {
            this.$submitButton.attr('disabled', 'disabled');
        } else {
            this.$submitButton.removeAttr('disabled');
        }
    },

    render: function () {
        var x = this.template.render(Object.assign({render: true}, this.state.toJSON()));
        this.setElement(x);
        this.$tags = this.$('.StyleTag');
        this.$submitButton = this.$('.TagList-submit').find('button');

        return this;
    }

    // destroy: function () {
    //     this.$el.fadeOut().destroy();
    // }
});

var Page = require('front/pages/Page/Page');
require('mobile/components/Link/Link');

require('./Responsible.less');

module.exports = Page.extend({
    template: require('./Responsible.jinja'),

    title: 'Об ответственном употреблении',

    el: '.Responsible'
});

var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Locations/LocationsBrand.jinja"] = require( "front/components/Locations/LocationsBrand.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/Locations/Locations.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/Locations/Locations.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
env.getTemplate("front/components/Locations/LocationsBrand.jinja", false, "assets/app/mobile/components/Locations/Locations.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "LocationsBrand")) {
var t_8 = t_5.LocationsBrand;
} else {
cb(new Error("cannot import 'LocationsBrand'")); return;
}
context.setVariable("LocationsBrand", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["classes", "type", "data"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("type", Object.prototype.hasOwnProperty.call(kwargs, "type") ? kwargs["type"] : "default");frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_10 = "";t_10 += "\n    <div class=\"Locations ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "brand") {
t_10 += "\n            <div class=\"Locations-brand\">";
t_10 += runtime.suppressValue((lineno = 7, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LocationsBrand"), "LocationsBrand", context, [runtime.makeKeywordArgs({"data": runtime.contextOrFrameLookup(context, frame, "data")})])), env.opts.autoescape);
t_10 += "</div>\n        ";
;
}
t_10 += "\n\n        <div class=\"Locations-map\"></div>\n\n        <div class=\"Locations-controls\">\n            <a class=\"Locations-fullscreen u-Route\" href=\"";
t_10 += runtime.suppressValue((lineno = 13, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:locations"])), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue((lineno = 13, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["fullscreen"])), env.opts.autoescape);
t_10 += "</a>\n\n            <div class=\"Locations-scale\">\n                <button class=\"Locations-scaleButton Locations-scaleButton--zoomIn\">";
t_10 += runtime.suppressValue((lineno = 16, colno = 96, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["plus"])), env.opts.autoescape);
t_10 += "</button>\n                <button class=\"Locations-scaleButton Locations-scaleButton--zoomOut\">";
t_10 += runtime.suppressValue((lineno = 17, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["minus"])), env.opts.autoescape);
t_10 += "</button>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("Locations");
context.setVariable("Locations", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 24, colno = 16, runtime.callWrap(macro_t_9, "Locations", context, [runtime.makeKeywordArgs({"classes": runtime.contextOrFrameLookup(context, frame, "classes")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/Locations/Locations.jinja"] , dependencies)
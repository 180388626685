var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Slider/SliderIndex.jinja"] = require( "mobile/components/Slider/SliderIndex.jinja" );
dependencies["mobile/components/ViewCount/ViewCount.jinja"] = require( "mobile/components/ViewCount/ViewCount.jinja" );
dependencies["mobile/components/PostItem/PostItem.jinja"] = require( "mobile/components/PostItem/PostItem.jinja" );
dependencies["mobile/components/Notify/Notify.jinja"] = require( "mobile/components/Notify/Notify.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Index/Index.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n\n";
env.getTemplate("mobile/components/Slider/SliderIndex.jinja", false, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "SliderIndex")) {
var t_11 = t_8.SliderIndex;
} else {
cb(new Error("cannot import 'SliderIndex'")); return;
}
context.setVariable("SliderIndex", t_11);
output += "\n";
env.getTemplate("mobile/components/ViewCount/ViewCount.jinja", false, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "ViewCount")) {
var t_15 = t_12.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_15);
output += "\n";
env.getTemplate("mobile/components/PostItem/PostItem.jinja", false, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "PostItem")) {
var t_19 = t_16.PostItem;
} else {
cb(new Error("cannot import 'PostItem'")); return;
}
context.setVariable("PostItem", t_19);
output += "\n";
env.getTemplate("mobile/components/Notify/Notify.jinja", false, "assets/app/mobile/pages/Index/Index.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "Notify")) {
var t_23 = t_20.Notify;
} else {
cb(new Error("cannot import 'Notify'")); return;
}
context.setVariable("Notify", t_23);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("notify"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_notify(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
output += runtime.suppressValue((lineno = 12, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Notify"), "Notify", context, [runtime.contextOrFrameLookup(context, frame, "notify")])), env.opts.autoescape);
output += "\n    ";
output += "\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 21, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--floatNav TopNav--index"})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 24;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Index\">\n        <div class=\"Index-slider\">\n            ";
output += runtime.suppressValue((lineno = 27, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SliderIndex"), "SliderIndex", context, [runtime.makeKeywordArgs({"slides": runtime.contextOrFrameLookup(context, frame, "slides"),"classes": "Slider--index"})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"Index-primaryPosts\">\n            <div class=\"Index-primaryPostsInner\">\n                ";
frame = frame.push();
var t_34 = runtime.contextOrFrameLookup(context, frame, "postsPrimary");
if(t_34) {t_34 = runtime.fromIterator(t_34);
var t_33 = t_34.length;
for(var t_32=0; t_32 < t_34.length; t_32++) {
var t_35 = t_34[t_32];
frame.set("p", t_35);
frame.set("loop.index", t_32 + 1);
frame.set("loop.index0", t_32);
frame.set("loop.revindex", t_33 - t_32);
frame.set("loop.revindex0", t_33 - t_32 - 1);
frame.set("loop.first", t_32 === 0);
frame.set("loop.last", t_32 === t_33 - 1);
frame.set("loop.length", t_33);
output += "\n                    <div class=\"Index-primaryPost\">\n                        ";
output += runtime.suppressValue((lineno = 34, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "PostItem"), "PostItem", context, [t_35,runtime.makeKeywordArgs({"classes": "PostItem--index"})])), env.opts.autoescape);
output += "\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n\n        <div class=\"Index-secondaryPosts\">\n            <header class=\"Index-secondaryHeader\">";
output += runtime.suppressValue((lineno = 41, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Новости"])), env.opts.autoescape);
output += "</header>\n\n            <div class=\"Index-postContainer\">\n                ";
frame = frame.push();
var t_38 = runtime.contextOrFrameLookup(context, frame, "postsSecondary");
if(t_38) {t_38 = runtime.fromIterator(t_38);
var t_37 = t_38.length;
for(var t_36=0; t_36 < t_38.length; t_36++) {
var t_39 = t_38[t_36];
frame.set("p", t_39);
frame.set("loop.index", t_36 + 1);
frame.set("loop.index0", t_36);
frame.set("loop.revindex", t_37 - t_36);
frame.set("loop.revindex0", t_37 - t_36 - 1);
frame.set("loop.first", t_36 === 0);
frame.set("loop.last", t_36 === t_37 - 1);
frame.set("loop.length", t_37);
output += "\n                    <a class=\"Index-postItem\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_39),"link"), env.opts.autoescape);
output += "\">\n                        <div class=\"Index-postTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_39),"title"), env.opts.autoescape);
output += "</div>\n                        <div class=\"Index-postMeta\">\n                            <div class=\"Index-postDate\">";
output += runtime.suppressValue(runtime.memberLookup((t_39),"dateText"), env.opts.autoescape);
output += "</div>\n                        </div>\n                    </a>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n\n        <div class=\"Index-about\">\n            <a class=\"Index-aboutCard\" href=\"";
output += runtime.suppressValue((lineno = 56, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:production"])), env.opts.autoescape);
output += "\">\n                <div class=\"Index-aboutImg\">\n                    <img src=\"";
output += runtime.suppressValue((lineno = 58, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/front/data/index_about_1.jpg"])), env.opts.autoescape);
output += "\"\n                        alt=\"";
output += runtime.suppressValue((lineno = 59, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Завод Московской Пивоваренной Компании входит в число самых современных, энергоэффективных и экологичных пивоварен в мире"])), env.opts.autoescape);
output += "\">\n                </div>\n\n                <div class=\"Index-aboutCaption\">\n                    <div class=\"Index-aboutCategory\">";
output += runtime.suppressValue((lineno = 63, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Производство"])), env.opts.autoescape);
output += "</div>\n                    <div class=\"Index-aboutTitle\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 64, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Завод Московской Пивоваренной Компании входит в&nbsp;число самых современных, энергоэффективных и&nbsp;экологичных пивоварен в&nbsp;мире"]))), env.opts.autoescape);
output += "</div>\n                </div>\n            </a>\n\n            <a class=\"Index-aboutCard u-Route\" href=\"";
output += runtime.suppressValue((lineno = 68, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:locations"])), env.opts.autoescape);
output += "\">\n                <div class=\"Index-aboutImg\">\n                    <img src=\"";
output += runtime.suppressValue((lineno = 70, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/front/data/index_about_2.jpg"])), env.opts.autoescape);
output += "\"\n                        alt=\"";
output += runtime.suppressValue((lineno = 71, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Найдите наше пиво в барах Москвы"])), env.opts.autoescape);
output += "\">\n                </div>\n\n                <div class=\"Index-aboutCaption\">\n                    <div class=\"Index-aboutCategory\">";
output += runtime.suppressValue((lineno = 75, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Где попробовать"])), env.opts.autoescape);
output += "</div>\n                    <div class=\"Index-aboutTitle\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 76, colno = 54, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Найдите наше пиво в&nbsp;барах Москвы"]))), env.opts.autoescape);
output += "</div>\n                </div>\n\n                <div class=\"Index-aboutButton\">";
output += runtime.suppressValue((lineno = 79, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["maximize"])), env.opts.autoescape);
output += "</div>\n            </a>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_body_class: b_body_class,
b_notify: b_notify,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Index/Index.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Slider/SliderIndex.jinja"] = require( "mobile/components/Slider/SliderIndex.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/PostItem/PostItem.jinja"] = require( "mobile/components/PostItem/PostItem.jinja" );
dependencies["mobile/components/ViewCount/ViewCount.jinja"] = require( "mobile/components/ViewCount/ViewCount.jinja" );
dependencies["mobile/components/Notify/Notify.jinja"] = require( "mobile/components/Notify/Notify.jinja" );
dependencies["mobile/components/LegalMenuTop/LegalMenuTop.jinja"] = require( "mobile/components/LegalMenuTop/LegalMenuTop.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/Slider/SliderIndex.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "SliderIndex")) {
var t_11 = t_8.SliderIndex;
} else {
cb(new Error("cannot import 'SliderIndex'")); return;
}
context.setVariable("SliderIndex", t_11);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n";
env.getTemplate("mobile/components/PostItem/PostItem.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "PostItem")) {
var t_19 = t_16.PostItem;
} else {
cb(new Error("cannot import 'PostItem'")); return;
}
context.setVariable("PostItem", t_19);
output += "\n";
env.getTemplate("mobile/components/ViewCount/ViewCount.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "ViewCount")) {
var t_23 = t_20.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_23);
output += "\n";
env.getTemplate("mobile/components/Notify/Notify.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "Notify")) {
var t_27 = t_24.Notify;
} else {
cb(new Error("cannot import 'Notify'")); return;
}
context.setVariable("Notify", t_27);
output += "\n";
env.getTemplate("mobile/components/LegalMenuTop/LegalMenuTop.jinja", false, "assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "LegalMenuTop")) {
var t_31 = t_28.LegalMenuTop;
} else {
cb(new Error("cannot import 'LegalMenuTop'")); return;
}
context.setVariable("LegalMenuTop", t_31);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнёров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 10, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнёров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 11, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 16, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 16, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 19;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"LegalDocuments\">\n    <div class=\"LegalDocuments-floatNav\"></div>\n    <div class=\"LegalDocuments-menuTop\">\n        ";
output += runtime.suppressValue((lineno = 23, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalMenuTop"), "LegalMenuTop", context, [runtime.makeKeywordArgs({"classes": "MenuTop--about","subpage": "documents","caller": (function (){var macro_t_42 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_43 = "";;
frame = frame.pop();
return new runtime.SafeString(t_43);
});
return macro_t_42;})()})])), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"LegalDocuments-contentWrapper\">\n        <div class=\"LegalDocuments-header\">\n            <div class=\"LegalDocuments-subtitle\">\n                <span class=\"LegalDocuments-subtitleStroke\"></span>\n                <div class=\"LegalDocuments-subtitleText\">\n                    ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive") {
output += "\n                        ";
output += runtime.suppressValue((lineno = 31, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Архив документов"])), env.opts.autoescape);
output += "\n                    ";
;
}
else {
output += "\n                        ";
output += runtime.suppressValue((lineno = 33, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Документы"])), env.opts.autoescape);
output += "\n                    ";
;
}
output += "\n                </div>\n            </div>\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "legalPolitics") && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "legalPoliticsDocuments")) > 0) {
output += "\n                <div class=\"LegalDocuments-description\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalPolitics")),"title"), env.opts.autoescape);
output += "\n                </div>\n                <div class=\"LegalDocuments-text\">\n                    ";
frame = frame.push();
var t_46 = runtime.contextOrFrameLookup(context, frame, "legalPoliticsDocuments");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("document", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_47),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                            <table>\n                                <tbody>\n                                <tr>\n                                    <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_47),"caption"), env.opts.autoescape);
output += "</span></td>\n                                    <td>\n                                        <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 51, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                        <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 54, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                    </td>\n                                </tr>\n                                </tbody>\n                            </table>\n                        </a>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "legalDocuments") && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "legalPartnersDocuments")) > 0) {
output += "\n                <div class=\"LegalDocuments-description\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "legalDocuments")),"title"), env.opts.autoescape);
output += "\n                </div>\n                <div class=\"LegalDocuments-text\">\n                    ";
frame = frame.push();
var t_50 = runtime.contextOrFrameLookup(context, frame, "legalPartnersDocuments");
if(t_50) {t_50 = runtime.fromIterator(t_50);
var t_49 = t_50.length;
for(var t_48=0; t_48 < t_50.length; t_48++) {
var t_51 = t_50[t_48];
frame.set("document", t_51);
frame.set("loop.index", t_48 + 1);
frame.set("loop.index0", t_48);
frame.set("loop.revindex", t_49 - t_48);
frame.set("loop.revindex0", t_49 - t_48 - 1);
frame.set("loop.first", t_48 === 0);
frame.set("loop.last", t_48 === t_49 - 1);
frame.set("loop.length", t_49);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_51),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                            <table>\n                                <tbody>\n                                <tr>\n                                    <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_51),"caption"), env.opts.autoescape);
output += "</span></td>\n                                    <td>\n                                        <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 78, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                        <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 81, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                    </td>\n                                </tr>\n                                </tbody>\n                            </table>\n                        </a>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive" && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "archiveDocuments")) > 0) {
output += "\n                <div class=\"LegalDocuments-text\">\n                    ";
frame = frame.push();
var t_54 = runtime.contextOrFrameLookup(context, frame, "archiveDocuments");
if(t_54) {t_54 = runtime.fromIterator(t_54);
var t_53 = t_54.length;
for(var t_52=0; t_52 < t_54.length; t_52++) {
var t_55 = t_54[t_52];
frame.set("document", t_55);
frame.set("loop.index", t_52 + 1);
frame.set("loop.index0", t_52);
frame.set("loop.revindex", t_53 - t_52);
frame.set("loop.revindex0", t_53 - t_52 - 1);
frame.set("loop.first", t_52 === 0);
frame.set("loop.last", t_52 === t_53 - 1);
frame.set("loop.length", t_53);
output += "<a class=\"Post-pdf\" href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_55),"url"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                            <table>\n                                <tbody>\n                                <tr>\n                                    <td><span>";
output += runtime.suppressValue(runtime.memberLookup((t_55),"caption"), env.opts.autoescape);
output += "</span></td>\n                                    <td>\n                                        <svg class=\"SvgIcon--pdf-download-desktop SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 102, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-desktop"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                        <svg class=\"SvgIcon--pdf-download-mobile SvgIcon\">\n                                            ";
output += runtime.suppressValue((lineno = 105, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["pdf-download-mobile"])), env.opts.autoescape);
output += "\n                                        </svg>\n                                    </td>\n                                </tr>\n                                </tbody>\n                            </table>\n                        </a>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            ";
;
}
output += "\n\n            <div class=\"LegalDocuments-links\">\n                ";
if(runtime.contextOrFrameLookup(context, frame, "type") == "archive") {
output += "\n                <a class=\"LegalDocuments-archiveLink\" href=\"";
output += runtime.suppressValue((lineno = 118, colno = 66, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-documents"])), env.opts.autoescape);
output += "\">\n                    <span>";
output += runtime.suppressValue((lineno = 119, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Актуальные документы"])), env.opts.autoescape);
output += "</span>\n                    ";
output += runtime.suppressValue((lineno = 120, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["archive-arrow"])), env.opts.autoescape);
output += "\n                </a>\n                ";
;
}
else {
if(runtime.contextOrFrameLookup(context, frame, "type") == "actual" && env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "archiveDocuments")) > 0) {
output += "\n                <a class=\"LegalDocuments-archiveLink\" href=\"";
output += runtime.suppressValue((lineno = 123, colno = 66, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-documents-archive"])), env.opts.autoescape);
output += "\">\n                    <span>";
output += runtime.suppressValue((lineno = 124, colno = 30, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Архив документов"])), env.opts.autoescape);
output += "</span>\n                    ";
output += runtime.suppressValue((lineno = 125, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["archive-arrow"])), env.opts.autoescape);
output += "\n                </a>\n                ";
;
}
;
}
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/LegalDocuments/LegalDocuments.jinja"] , dependencies)
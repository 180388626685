var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/BrandsGrid/BrandsGridItem.jinja"] = require( "mobile/components/BrandsGrid/BrandsGridItem.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/BrandsGrid/BrandsGrid.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/BrandsGrid/BrandsGrid.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
env.getTemplate("mobile/components/BrandsGrid/BrandsGridItem.jinja", false, "assets/app/mobile/components/BrandsGrid/BrandsGrid.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "BrandsGridItem")) {
var t_8 = t_5.BrandsGridItem;
} else {
cb(new Error("cannot import 'BrandsGridItem'")); return;
}
context.setVariable("BrandsGridItem", t_8);
output += "\n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["classes", "brands"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("brands", Object.prototype.hasOwnProperty.call(kwargs, "brands") ? kwargs["brands"] : []);var t_10 = "";t_10 += "\n    <div class=\"BrandsGrid ";
t_10 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_10 += "\">\n        <div class=\"BrandsGrid-sizer\"></div>\n        <div class=\"BrandsGrid-loader\">\n            <div class=\"BrandsGrid-loaderDot\"></div>\n            <div class=\"BrandsGrid-loaderDot\"></div>\n            <div class=\"BrandsGrid-loaderDot\"></div>\n        </div>     \n\n        ";
frame = frame.push();
var t_13 = runtime.contextOrFrameLookup(context, frame, "brands");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("b", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_10 += "\n            ";
t_10 += runtime.suppressValue((lineno = 14, colno = 29, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandsGridItem"), "BrandsGridItem", context, [runtime.makeKeywordArgs({"brand": t_14})])), env.opts.autoescape);
t_10 += "\n        ";
;
}
}
frame = frame.pop();
t_10 += "\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("BrandsGrid");
context.setVariable("BrandsGrid", macro_t_9);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 20, colno = 17, runtime.callWrap(macro_t_9, "BrandsGrid", context, [runtime.makeKeywordArgs({"brands": runtime.contextOrFrameLookup(context, frame, "brands")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/BrandsGrid/BrandsGrid.jinja"] , dependencies)
var Backbone = require('backbone');
var Page = require('front/pages/Page/Page');

var CareerResumeForm = require('mobile/components/CareerResumeForm/CareerResumeForm');
var CareerVacancyCard = require('mobile/components/CareerVacancyCard/CareerVacancyCard');

require('./CareerCategory.less');

module.exports = Page.extend({
    template: require('./CareerCategory.jinja'),

    el: '.Career',

    events: {},

    initialize: function (options) {
        this.form = new CareerResumeForm();
        this.vacancy = new CareerVacancyCard();

        Page.prototype.initialize.call(this, options);
    },

    activate: function (params) {
        return Page.prototype.activate.call(this, params);
    }
});

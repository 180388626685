var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Grid/Grid.jinja"] = require( "front/components/Grid/Grid.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/Slider/SliderVariant.jinja"] = require( "mobile/components/Slider/SliderVariant.jinja" );
dependencies["front/components/Video/Video.jinja"] = require( "front/components/Video/Video.jinja" );
dependencies["mobile/components/Button/Button.jinja"] = require( "mobile/components/Button/Button.jinja" );
dependencies["mobile/components/ButtonIcon/ButtonIcon.jinja"] = require( "mobile/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["mobile/components/BuildingCross/BuildingCross.jinja"] = require( "mobile/components/BuildingCross/BuildingCross.jinja" );
dependencies["mobile/components/QualityForm/QualityForm.jinja"] = require( "mobile/components/QualityForm/QualityForm.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Contact/Contact.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n\n";
env.getTemplate("front/components/Grid/Grid.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "Grid")) {
var t_11 = t_8.Grid;
} else {
cb(new Error("cannot import 'Grid'")); return;
}
context.setVariable("Grid", t_11);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n";
env.getTemplate("mobile/components/Slider/SliderVariant.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "SliderVariant")) {
var t_19 = t_16.SliderVariant;
} else {
cb(new Error("cannot import 'SliderVariant'")); return;
}
context.setVariable("SliderVariant", t_19);
output += "\n";
env.getTemplate("front/components/Video/Video.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "Video")) {
var t_23 = t_20.Video;
} else {
cb(new Error("cannot import 'Video'")); return;
}
context.setVariable("Video", t_23);
output += "\n";
env.getTemplate("mobile/components/Button/Button.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "Button")) {
var t_27 = t_24.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_27);
output += "\n";
env.getTemplate("mobile/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "ButtonIcon")) {
var t_31 = t_28.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_31);
output += "\n";
env.getTemplate("mobile/components/BuildingCross/BuildingCross.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
t_32.getExported(function(t_34,t_32) {
if(t_34) { cb(t_34); return; }
if(Object.prototype.hasOwnProperty.call(t_32, "BuildingCross")) {
var t_35 = t_32.BuildingCross;
} else {
cb(new Error("cannot import 'BuildingCross'")); return;
}
context.setVariable("BuildingCross", t_35);
output += "\n\n";
env.getTemplate("mobile/components/QualityForm/QualityForm.jinja", false, "assets/app/mobile/pages/Contact/Contact.jinja", false, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
t_36.getExported(function(t_38,t_36) {
if(t_38) { cb(t_38); return; }
if(Object.prototype.hasOwnProperty.call(t_36, "QualityForm")) {
var t_39 = t_36.QualityForm;
} else {
cb(new Error("cannot import 'QualityForm'")); return;
}
context.setVariable("QualityForm", t_39);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_43,t_42) {
if(t_43) { cb(t_43); return; }
output += t_42;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_45,t_44) {
if(t_45) { cb(t_45); return; }
output += t_44;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_47,t_46) {
if(t_47) { cb(t_47); return; }
output += t_46;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_49,t_48) {
if(t_49) { cb(t_49); return; }
output += t_48;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("libs"))(env, context, frame, runtime, function(t_51,t_50) {
if(t_51) { cb(t_51); return; }
output += t_50;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 14, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 15, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 18, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--contact","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 18, colno = 87, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "ContactPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 22;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Contact\">\n    <div class=\"Contact-map-outer\">\n        <div class=\"Contact-map\" id=\"Contact-map\" ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")) {
output += "data-center=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat"), env.opts.autoescape);
output += "\"";
;
}
output += "></div>\n    </div>\n\n    <div class=\"Contact-contentWrapper\">\n        <div class=\"Contact-contentMain\">\n            <h2 class=\"Contact-title\">";
output += runtime.suppressValue((lineno = 30, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Контакты"])), env.opts.autoescape);
output += "</h2>\n\n            <div class=\"Contact-content\">\n                <ul class=\"Contact-contentGrid\">\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--excursion\" href=\"";
output += runtime.suppressValue((lineno = 35, colno = 106, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:excursion"])), env.opts.autoescape);
output += "\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 36, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Узнать про экскурсию"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--partner\" href=\"";
output += runtime.suppressValue((lineno = 40, colno = 104, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:legal-information"])), env.opts.autoescape);
output += "\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 41, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стать партнером"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                    <li class=\"Contact-contentGridItem\">\n                        <a class=\"Contact-contentGridLink Contact-contentGridLink--quality js-openQualityForm\" href=\"#discuss-quality\">\n                            <span>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 46, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Поделиться мнением о&nbsp;нашей продукции"]))), env.opts.autoescape);
output += "</span>\n                        </a>\n                    </li>\n                </ul>\n\n                <div class=\"Contact-contentInfo\">\n                    <div class=\"Contact-contentInfoSection\">\n                        <div class=\"Contact-contentInfoSectionTitle\">";
output += runtime.suppressValue((lineno = 53, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Остались вопросы?"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"Contact-contentInfoSectionText\">\n                            <p>";
output += runtime.suppressValue((lineno = 55, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Звоните"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"phone"), env.opts.autoescape);
output += "</p>\n                            <p>";
output += runtime.suppressValue((lineno = 56, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Пишите"])), env.opts.autoescape);
output += ": <a class=\"Contact-link\" href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"email"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"email"), env.opts.autoescape);
output += "</a></p>\n                        </div>\n                    </div>\n\n                    <div class=\"Contact-contentInfoSection\">\n                        <div class=\"Contact-contentInfoSectionTitle\">";
output += runtime.suppressValue((lineno = 61, colno = 73, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"Contact-contentInfoSectionText\">\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address"), env.opts.autoescape);
output += "\n                        </div>\n                        <a\n                            class=\"Button Button--grow Button--small Button--transparent Contact-button\"\n                            href=\"http://maps.yandex.ru/?mode=routes&sll=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")),",",".") + "," + env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat")),",","."))), env.opts.autoescape);
output += "&rtext=~";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, (env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lat")),",",".") + "," + env.getFilter("replace").call(context, env.getFilter("string").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"lon")),",","."))), env.opts.autoescape);
output += "&rtt=auto&stext=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address")), env.opts.autoescape);
output += "&text=";
output += runtime.suppressValue(env.getFilter("urlencode").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "contactPage")),"address")), env.opts.autoescape);
output += "\"\n                            target=\"_blank\"\n                            rel=\"nofollow noopener\"\n                        >\n                            ";
output += runtime.suppressValue((lineno = 71, colno = 32, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Маршрут"])), env.opts.autoescape);
output += "\n                        </a>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"Contact-qualityFormWrapper\">\n            <div class=\"Contact-qualityFormClose\">";
output += runtime.suppressValue((lineno = 79, colno = 63, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, ["js-closeQualityForm"])), env.opts.autoescape);
output += "</div>\n            <h3 class=\"Contact-title\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 80, colno = 42, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Поделиться мнением о&nbsp;нашей продукции"]))), env.opts.autoescape);
output += "</h2>\n            <div class=\"Contact-qualityFormText\">\n                <p>";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 82, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Ваши сообщения о&nbsp;качестве нашей продукции помогают нам становиться еще лучше. Все поля обязательны для заполнения."]))), env.opts.autoescape);
output += "</p>\n            </div>\n\n            <div class=\"Contact-qualityForm\">\n                ";
output += runtime.suppressValue((lineno = 86, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "QualityForm"), "QualityForm", context, [runtime.makeKeywordArgs({"lang": runtime.contextOrFrameLookup(context, frame, "LANGUAGE_CODE"),"caller": (function (){var macro_t_52 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_53 = "";;
frame = frame.pop();
return new runtime.SafeString(t_53);
});
return macro_t_52;})()})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_libs(env, context, frame, runtime, cb) {
var lineno = 93;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "libs", b_libs, frame, runtime, function(t_54,hole_139) {
if(t_54) { cb(t_54); return; }
hole_139 = runtime.markSafe(hole_139);
output += "\n    ";
output += runtime.suppressValue(hole_139, env.opts.autoescape);
output += "\n    <script src='https://api.tiles.mapbox.com/mapbox-gl-js/v0.47.0/mapbox-gl.js'></script>\n    <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v0.47.0/mapbox-gl.css' rel='stylesheet' />\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
b_libs: b_libs,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Contact/Contact.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Button/Button.jinja"] = require( "mobile/components/Button/Button.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/SubscribeSections/SubscribeSections.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/SubscribeSections/SubscribeSections.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += " \n\n";
env.getTemplate("mobile/components/Button/Button.jinja", false, "assets/app/mobile/components/SubscribeSections/SubscribeSections.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "Button")) {
var t_8 = t_5.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_8);
output += " \n\n";
var macro_t_9 = runtime.makeMacro(
[], 
["sections"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("sections", Object.prototype.hasOwnProperty.call(kwargs, "sections") ? kwargs["sections"] : []);var t_10 = "";t_10 += "\n    <div class=\"SubscribeSections\">\n        <div class=\"SubscribeSections-content\">\n            <div class=\"SubscribeSections-close\">";
t_10 += runtime.suppressValue((lineno = 7, colno = 61, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["cross"])), env.opts.autoescape);
t_10 += "</div>\n            \n            <header class=\"SubscribeSections-header\">";
t_10 += runtime.suppressValue((lineno = 9, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["На что подписаться?"])), env.opts.autoescape);
t_10 += "</header>\n\n            <div class=\"SubscribeSections-inputList\">\n                ";
frame = frame.push();
var t_13 = runtime.contextOrFrameLookup(context, frame, "sections");
if(t_13) {t_13 = runtime.fromIterator(t_13);
var t_12 = t_13.length;
for(var t_11=0; t_11 < t_13.length; t_11++) {
var t_14 = t_13[t_11];
frame.set("s", t_14);
frame.set("loop.index", t_11 + 1);
frame.set("loop.index0", t_11);
frame.set("loop.revindex", t_12 - t_11);
frame.set("loop.revindex0", t_12 - t_11 - 1);
frame.set("loop.first", t_11 === 0);
frame.set("loop.last", t_11 === t_12 - 1);
frame.set("loop.length", t_12);
t_10 += "\n                    ";
if(runtime.memberLookup((t_14),"slug") != "podcasts") {
t_10 += "\n                    <span class=\"SubscribeSections-input\">\n                        <input id=\"SubscribeSections-input--";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"slug"), env.opts.autoescape);
t_10 += "\"\n                            type=\"checkbox\"\n                            name=\"sections\"\n                            checked=\"checked\"\n                                value=\"";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"slug"), env.opts.autoescape);
t_10 += "\">\n                        <span class=\"SubscribeSections-inputCheckbox\">";
t_10 += runtime.suppressValue((lineno = 20, colno = 82, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["checkMark"])), env.opts.autoescape);
t_10 += "</span>\n                        <label for=\"SubscribeSections-input--";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"slug"), env.opts.autoescape);
t_10 += "\">";
t_10 += runtime.suppressValue(runtime.memberLookup((t_14),"title"), env.opts.autoescape);
t_10 += "</label>\n                    </span>\n                    ";
;
}
t_10 += "\n                ";
;
}
}
frame = frame.pop();
t_10 += "\n            </div>\n\n            <div class=\"SubscribeSections-submit\">\n                ";
var t_15;
t_15 = (lineno = 28, colno = 37, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Подписаться"]));
frame.set("buttonText", t_15, true);
if(frame.topLevel) {
context.setVariable("buttonText", t_15);
}
if(frame.topLevel) {
context.addExport("buttonText", t_15);
}
t_10 += "\n                        \n                ";
t_10 += runtime.suppressValue((lineno = 30, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": runtime.contextOrFrameLookup(context, frame, "buttonText"),"classes": "Button--grow"})])), env.opts.autoescape);
t_10 += "\n            </div>\n        </div>\n        \n        <div class=\"SubscribeSections-message\"><span>";
t_10 += runtime.suppressValue((lineno = 34, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Спасибо!"])), env.opts.autoescape);
t_10 += "</span></div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_10);
});
context.addExport("SubscribeSections");
context.setVariable("SubscribeSections", macro_t_9);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/SubscribeSections/SubscribeSections.jinja"] , dependencies)
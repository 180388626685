var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Brand/BrandImage.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Brand/BrandImage.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["brand"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("brand", Object.prototype.hasOwnProperty.call(kwargs, "brand") ? kwargs["brand"] : {});var t_6 = "";t_6 += "\n    <div class=\"BrandImage owl-carousel\">\n        <div class=\"BrandImage-item\">\n            <img src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w500_square"), env.opts.autoescape);
t_6 += "\" srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"img")),"w1000_square"), env.opts.autoescape);
t_6 += " 2x\" alt=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_6 += "\">\n        </div>\n    ";
frame = frame.push();
var t_9 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"images");
if(t_9) {t_9 = runtime.fromIterator(t_9);
var t_8 = t_9.length;
for(var t_7=0; t_7 < t_9.length; t_7++) {
var t_10 = t_9[t_7];
frame.set("img", t_10);
frame.set("loop.index", t_7 + 1);
frame.set("loop.index0", t_7);
frame.set("loop.revindex", t_8 - t_7);
frame.set("loop.revindex0", t_8 - t_7 - 1);
frame.set("loop.first", t_7 === 0);
frame.set("loop.last", t_7 === t_8 - 1);
frame.set("loop.length", t_8);
t_6 += "<div class=\"BrandImage-item\">\n            <img src=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_10),"img")),"w500_square"), env.opts.autoescape);
t_6 += "\" srcset=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_10),"img")),"w1000_square"), env.opts.autoescape);
t_6 += " 2x\" alt=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "brand")),"title"), env.opts.autoescape);
t_6 += "\">\n        </div>\n        ";
;
}
}
frame = frame.pop();
t_6 += "</div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("BrandImage");
context.setVariable("BrandImage", macro_t_5);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 16, colno = 17, runtime.callWrap(macro_t_5, "BrandImage", context, [runtime.makeKeywordArgs({"brand": runtime.contextOrFrameLookup(context, frame, "brand")})])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Brand/BrandImage.jinja"] , dependencies)
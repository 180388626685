var Backbone = require('backbone');
var _ = require('underscore');
var waitForTransitionEnd = require('waitForTransitionEnd');

require('mobile/components/Button/Button');
require('mobile/components/StyleTag/StyleTag');

require('./TagListMedia.less');

module.exports = Backbone.View.extend({
    el: '.TagListMedia',

    events: {
        'click .StyleTag': 'onChangeTag'
    },

    initialize: function (options) {
        this.data = options.data;

        this.state = new Backbone.Model({
            tagsInitial: [],
            tags: app.data.activeTags
        });

        this.$tags = this.$('.StyleTag');
    },

    onChangeTag: function (e) {
        var id = parseInt(
            $(e.target)
                .closest('.StyleTag')
                .attr('data-id'),
            10
        );

        var containedTags = _.findWhere(this.state.get('tags'), { id: id });
        if (containedTags) {
            var newTags = _.without(this.state.get('tags'), containedTags);
        } else {
            var addedTag = this.data.tags.filter(function (tag) {
                return tag.id === id;
            });
            var newTags = _.union(this.state.get('tags'), addedTag);
        }

        this.state.set('tags', newTags);
        this.trigger('change:tags', this.state.get('tags'));

        this.updateTags();
    },

    updateTags: function () {
        this.$tags.removeClass('isActive');
        this.$tags
            .filter(
                function (index, el) {
                    return _.chain(this.state.get('tags'))
                        .pluck('id')
                        .contains(parseInt($(el).attr('data-id'), 10))
                        .value();
                }.bind(this)
            )
            .addClass('isActive');
    }

});

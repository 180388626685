var Backbone = require('backbone');
var _ = require('underscore');
var queryString = require('query-string');
var Utils = require('front/utils/Utils');

require('front/components/StyleTag/StyleTag');
var BrandsGrid = require('mobile/components/BrandsGrid/BrandsGrid');

require('./BrandsList.less');

module.exports = Backbone.View.extend({
    template: require('./BrandsList.jinja'),

    events: {
        'click .BrandsList-subCategory': 'onChangeSubCategory'
    },

    initialize: function (options) {
        _.bindAll(this, 'onUpdateActiveSubcategory');

        this.options = options || {};

        this.data = options.data;

        this.state = new Backbone.Model({
            category: this.data.category,
            subCategory: this.data.subCategory,
            styles: this.data.styles
        });

        this.listenTo(this.state, 'change:subCategory', this.onChangeFilters);
        this.listenTo(this.state, 'change:styles', this.onChangeFilters);

        this.$subCategories = this.$('.BrandsList-subCategory');
        this.$stylesLabel = this.$('.BrandsList-subCategoriesLabel');
        this.brandsGrid = new BrandsGrid();
    },

    onChangeSubCategory: function (e) {
        var slug = $(e.target).attr('data-sub-category');
        var subCategory = this.data.category.subCategories.filter(function (subCategory) {
            return subCategory.slug === slug;
        })[0];

        if (!subCategory) {
            subCategory = {
                slug: 'all'
            };
        }

        this.state.set('subCategory', subCategory);
    },

    onUpdateActiveSubcategory: function (subcategory) {
        this.$subCategories.removeClass('isActive')
            .filter('[data-sub-category=' + (subcategory || 'all') + ']')
            .addClass('isActive');
    },

    onChangeFilters: function () {
        this.updateGetParams();
        this.$stylesLabel.toggleClass('BrandsList-subCategoriesLabel--active', this.state.get('styles').length != 0);
        this.brandsGrid.filterData(this.state.toJSON());
    },

    updateGetParams: function () {
        var params = queryString.parse(location.search);
        if (this.state.get('subCategory').slug !== 'all') {
            params.subCategory = this.state.get('subCategory').slug;
        } else {
            delete params.subCategory;
        }
        this.onUpdateActiveSubcategory(params.subCategory);

        if (this.state.get('styles').length) {
            params.styles = _.pluck(this.state.get('styles'), 'id').join(',');
        } else {
            delete params.styles;
        }

        var stringified = queryString.stringify(params);
        if (stringified) {
            app.router.navigate(window.location.pathname + '?' + stringified, {trigger: true});
        } else {
            app.router.navigate(window.location.pathname, {trigger: true});
        }
    }
});

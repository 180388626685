var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/BuildingCross/BuildingCross.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/BuildingCross/BuildingCross.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += " \n";
var macro_t_5 = runtime.makeMacro(
[], 
["class", "dataId", "state", "header", "description"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("class", Object.prototype.hasOwnProperty.call(kwargs, "class") ? kwargs["class"] : "");frame.set("dataId", Object.prototype.hasOwnProperty.call(kwargs, "dataId") ? kwargs["dataId"] : "");frame.set("state", Object.prototype.hasOwnProperty.call(kwargs, "state") ? kwargs["state"] : "");frame.set("header", Object.prototype.hasOwnProperty.call(kwargs, "header") ? kwargs["header"] : "");frame.set("description", Object.prototype.hasOwnProperty.call(kwargs, "description") ? kwargs["description"] : "");var t_6 = "";t_6 += "\n<div class=\"BuildingCross ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "class"), env.opts.autoescape);
t_6 += "\" data-id='";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "dataId"), env.opts.autoescape);
t_6 += "' state='";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "state"), env.opts.autoescape);
t_6 += "'>\n    <div class=\"BuildingCross-label\">\n            <div class=\"BuildingCross-header\">";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "header"), env.opts.autoescape);
t_6 += "</div>\n            <div class=\"BuildingCross-description\">";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "description"), env.opts.autoescape);
t_6 += "</div>\n    </div>\n    <div class=\"BuildingCross-cross\">\n        ";
t_6 += runtime.suppressValue((lineno = 8, colno = 20, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["buildingCross"])), env.opts.autoescape);
t_6 += "\n    </div>\n</div>\n    \n";
t_6 += runtime.suppressValue((lineno = 12, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_6 += " \n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("BuildingCross");
context.setVariable("BuildingCross", macro_t_5);
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/BuildingCross/BuildingCross.jinja"] , dependencies)
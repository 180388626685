var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/TagListMedia/TagListMedia.jinja"] = require( "mobile/components/TagListMedia/TagListMedia.jinja" );
dependencies["mobile/components/SectionBadge/SectionBadge.jinja"] = require( "mobile/components/SectionBadge/SectionBadge.jinja" );
dependencies["mobile/components/PostGrid/PostGrid.jinja"] = require( "mobile/components/PostGrid/PostGrid.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/TagListMedia/TagListMedia.jinja", false, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TagListMedia")) {
var t_11 = t_8.TagListMedia;
} else {
cb(new Error("cannot import 'TagListMedia'")); return;
}
context.setVariable("TagListMedia", t_11);
output += "\n";
env.getTemplate("mobile/components/SectionBadge/SectionBadge.jinja", false, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "SectionBadge")) {
var t_15 = t_12.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_15);
output += "\n";
env.getTemplate("mobile/components/PostGrid/PostGrid.jinja", false, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "PostGrid")) {
var t_19 = t_16.PostGrid;
} else {
cb(new Error("cannot import 'PostGrid'")); return;
}
context.setVariable("PostGrid", t_19);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "TopNav")) {
var t_23 = t_20.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_23);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "PostListPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"PostList\">\n        <div class=\"PostList-topNav\">\n            ";
output += runtime.suppressValue((lineno = 18, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"title": (lineno = 18, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])),"classes": "TopNav--media isPermanentActive","type": "postList","caller": (function (){var macro_t_34 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_35 = "";t_35 += "\n                <div class=\"TopNav-sections\">\n                    <a href=\"";
t_35 += runtime.suppressValue((lineno = 20, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:index"])), env.opts.autoescape);
t_35 += "\"\n                       data-section=\"all\"\n                       class=\"TopNav-sectionItem\">\n                        <div class=\"TopNav-sectionItemTitle\">";
t_35 += runtime.suppressValue((lineno = 23, colno = 65, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Все посты"])), env.opts.autoescape);
t_35 += "</div>\n                    </a>\n                    ";
frame = frame.push();
var t_38 = runtime.contextOrFrameLookup(context, frame, "sections");
if(t_38) {t_38 = runtime.fromIterator(t_38);
var t_37 = t_38.length;
for(var t_36=0; t_36 < t_38.length; t_36++) {
var t_39 = t_38[t_36];
frame.set("s", t_39);
frame.set("loop.index", t_36 + 1);
frame.set("loop.index0", t_36);
frame.set("loop.revindex", t_37 - t_36);
frame.set("loop.revindex0", t_37 - t_36 - 1);
frame.set("loop.first", t_36 === 0);
frame.set("loop.last", t_36 === t_37 - 1);
frame.set("loop.length", t_37);
t_35 += "\n                        ";
if(runtime.memberLookup((t_39),"slug") != "podcasts") {
t_35 += "\n                        <a\n                            href=\"";
t_35 += runtime.suppressValue((lineno = 28, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": [runtime.memberLookup((t_39),"slug")]})])), env.opts.autoescape);
t_35 += "\"\n                            data-section=\"";
t_35 += runtime.suppressValue(runtime.memberLookup((t_39),"slug"), env.opts.autoescape);
t_35 += "\"\n                            class=\"TopNav-sectionItem ";
t_35 += runtime.suppressValue((runtime.memberLookup((t_39),"slug") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "section")),"slug")?"isActive":""), env.opts.autoescape);
t_35 += "\"\n                        >\n                            <div class=\"TopNav-sectionItemTitle\">";
t_35 += runtime.suppressValue(runtime.memberLookup((t_39),"title"), env.opts.autoescape);
t_35 += "</div>\n                        </a>\n                        ";
;
}
t_35 += "\n                    ";
;
}
}
frame = frame.pop();
t_35 += "\n                    <a href=\"";
t_35 += runtime.suppressValue((lineno = 36, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:subscribe"])), env.opts.autoescape);
t_35 += "\"\n                        class=\"TopNav-sectionItem isActive\">\n                        <div class=\"TopNav-sectionItemTitle\">Наша рассылка</div>\n                    </a>\n                </div>\n            ";
;
frame = frame.pop();
return new runtime.SafeString(t_35);
});
return macro_t_34;})()})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"PostList-main\">\n            <div class=\"MediaSubscribe\">\n                <div class=\"MediaSubscribe-content\">\n                    <h1 class=\"MediaSubscribe-title\">Подпишитесь на&nbsp;нашу рассылку</h1>\n                    <p class=\"MediaSubscribe-text\">Раз в&nbsp;месяц мы&nbsp;будем присылать вам письмо о&nbsp;главных событиях с&nbsp;приятными бонусами. Вы&nbsp;всегда сможете отписаться от&nbsp;рассылки.</p>\n\n                    <form class=\"MediaSubscribe-form\" action=\"/api/subscribe/\" method=\"post\">\n                        <div class=\"MediaSubscribe-fieldGroup\">\n                            <input class=\"MediaSubscribe-field\" id=\"mediaSubscribeEmail\" type=\"email\" name=\"email\" placeholder=\"Email\">\n                            <button class=\"Button MediaSubscribe-btn\" type=\"submit\" value=\"\" name=\"subscribe\">Отправить</button>\n                        </div>\n\n                        <div class=\"MediaSubscribe-message MediaSubscribe-message--error\">";
output += runtime.suppressValue((lineno = 56, colno = 94, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес введен неверно"])), env.opts.autoescape);
output += "</div>\n                        <div class=\"MediaSubscribe-message MediaSubscribe-message--thanks\">";
output += runtime.suppressValue((lineno = 57, colno = 95, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Спасибо, что подписались!"])), env.opts.autoescape);
output += "</div>\n                    </form>\n                </div>\n            </div>\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/MediaSubscribe/MediaSubscribe.jinja"] , dependencies)
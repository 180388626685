var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/SectionBadge/SectionBadge.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/SectionBadge/SectionBadge.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
var macro_t_5 = runtime.makeMacro(
["section", "sectionRoute"], 
[], 
function (l_section, l_sectionRoute, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("section", l_section);
frame.set("sectionRoute", l_sectionRoute);
var t_6 = "";t_6 += "\n    <a class=\"SectionBadge\" href=\"";
t_6 += runtime.suppressValue((lineno = 3, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:section",runtime.makeKeywordArgs({"args": [runtime.memberLookup((l_section),"slug")]})])), env.opts.autoescape);
t_6 += "\" data-section=\"";
t_6 += runtime.suppressValue(runtime.memberLookup((l_section),"slug"), env.opts.autoescape);
t_6 += "\">\n        <span class=\"SectionBadge-title\" style=\"background: ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_section),"color"), env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue(runtime.memberLookup((l_section),"title"), env.opts.autoescape);
t_6 += "</span>\n        <span class=\"SectionBadge-end\" style=\"fill: ";
t_6 += runtime.suppressValue(runtime.memberLookup((l_section),"color"), env.opts.autoescape);
t_6 += "\">";
t_6 += runtime.suppressValue((lineno = 5, colno = 85, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["categoryBadge"])), env.opts.autoescape);
t_6 += "</span>\n    </a>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("SectionBadge");
context.setVariable("SectionBadge", macro_t_5);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 10, colno = 19, runtime.callWrap(macro_t_5, "SectionBadge", context, [runtime.contextOrFrameLookup(context, frame, "section")])), env.opts.autoescape);
output += "\n";
;
}
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/SectionBadge/SectionBadge.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/StyleTag/StyleTag.jinja"] = require( "mobile/components/StyleTag/StyleTag.jinja" );
dependencies["mobile/components/Button/Button.jinja"] = require( "mobile/components/Button/Button.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/TagList/TagList.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/TagList/TagList.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += "\n\n";
env.getTemplate("mobile/components/StyleTag/StyleTag.jinja", false, "assets/app/mobile/components/TagList/TagList.jinja", false, function(t_6,t_5) {
if(t_6) { cb(t_6); return; }
t_5.getExported(function(t_7,t_5) {
if(t_7) { cb(t_7); return; }
if(Object.prototype.hasOwnProperty.call(t_5, "StyleTag")) {
var t_8 = t_5.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_8);
output += "\n";
env.getTemplate("mobile/components/Button/Button.jinja", false, "assets/app/mobile/components/TagList/TagList.jinja", false, function(t_10,t_9) {
if(t_10) { cb(t_10); return; }
t_9.getExported(function(t_11,t_9) {
if(t_11) { cb(t_11); return; }
if(Object.prototype.hasOwnProperty.call(t_9, "Button")) {
var t_12 = t_9.Button;
} else {
cb(new Error("cannot import 'Button'")); return;
}
context.setVariable("Button", t_12);
output += "\n\n";
var macro_t_13 = runtime.makeMacro(
["tags", "activeTags"], 
["classes"], 
function (l_tags, l_activeTags, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("tags", l_tags);
frame.set("activeTags", l_activeTags);
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_14 = "";t_14 += "\n    <div class=\"TagList ";
t_14 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_14 += "\">\n        <div class=\"TagList-overlay\"></div>\n\n        <div class=\"TagList-content\">\n            <div class=\"TagList-close\">";
t_14 += runtime.suppressValue((lineno = 10, colno = 51, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["crossBold"])), env.opts.autoescape);
t_14 += "</div>\n            <div class=\"TagList-list\">\n                ";
frame = frame.push();
var t_17 = l_tags;
if(t_17) {t_17 = runtime.fromIterator(t_17);
var t_16 = t_17.length;
for(var t_15=0; t_15 < t_17.length; t_15++) {
var t_18 = t_17[t_15];
frame.set("t", t_18);
frame.set("loop.index", t_15 + 1);
frame.set("loop.index0", t_15);
frame.set("loop.revindex", t_16 - t_15);
frame.set("loop.revindex0", t_16 - t_15 - 1);
frame.set("loop.first", t_15 === 0);
frame.set("loop.last", t_15 === t_16 - 1);
frame.set("loop.length", t_16);
t_14 += "\n                    ";
t_14 += runtime.suppressValue((lineno = 13, colno = 31, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_18,runtime.makeKeywordArgs({"classes": (runtime.inOperator(runtime.memberLookup((t_18),"id"),env.getFilter("map").call(context, l_activeTags,runtime.makeKeywordArgs({"attribute": "id"})))?"isActive":"")})])), env.opts.autoescape);
t_14 += "\n                ";
;
}
}
frame = frame.pop();
t_14 += "\n            </div>\n\n            <div class=\"TagList-submit\">\n                ";
t_14 += runtime.suppressValue((lineno = 18, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Button"), "Button", context, [runtime.makeKeywordArgs({"title": (lineno = 18, colno = 33, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Применить"])),"classes": "Button--grow"})])), env.opts.autoescape);
t_14 += "\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_14);
});
context.addExport("TagList");
context.setVariable("TagList", macro_t_13);
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "render") == true) {
output += "\n    ";
output += runtime.suppressValue((lineno = 25, colno = 14, runtime.callWrap(macro_t_13, "TagList", context, [runtime.contextOrFrameLookup(context, frame, "tags"),runtime.contextOrFrameLookup(context, frame, "activeTags"),runtime.contextOrFrameLookup(context, frame, "classes")])), env.opts.autoescape);
output += "\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/TagList/TagList.jinja"] , dependencies)
var _ = require('underscore');

var QualityForm = require('front/components/QualityForm/QualityForm');
var Page = require('front/pages/Page/Page');

require('./Contact.less');

module.exports = Page.extend({
    template: require('./Contact.jinja'),

    el: '.Contact',

    events: {
        'click .Contact-zoomIn': 'onZoomIn',
        'click .Contact-zoomOut': 'onZoomOut',
        'click .js-openQualityForm': 'openQualityForm',
        'click .js-closeQualityForm': 'closeQualityForm',
    },

    title: window.gettext('Контакты'),

    initialize: function () {
        this.$QualityForm = new QualityForm();
        var $contactMap = $('.Contact-map');

        var center = _.map(($contactMap.attr('data-center') || '').split(','), function (i) {
            return parseFloat(i);
        });

        if (center.length && !center[0]) {
            center = [37.720231, 55.930972];
        }

        mapboxgl.accessToken =
            'pk.eyJ1IjoiYW50b25pb2Rjb3JyZWEiLCJhIjoiY2prbnB1bnpsMmhhdzN4cWg2ZmJ0b2JnciJ9.CeZTFMMfjDZTJnC5Jg_V5w';
        this.map = new mapboxgl.Map({
            container: 'Contact-map',
            zoom: 13,
            center: center,
            style: 'mapbox://styles/mapbox/satellite-v9'
        });

        this.map.on(
            'load',
            function () {
                this.map.resize();
                $contactMap.addClass('Contact-map--loaded');
            }.bind(this)
        );
        var geojson = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: center
                    },
                    properties: {
                        title: 'Mosbrew location',
                        description: 'We are here'
                    }
                }
            ]
        };
        geojson.features.forEach(function (marker) {
            var el = document.createElement('div');
            el.className = 'Contact-marker';
            var marker = new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates);
            marker.addTo(this.map);
        }.bind(this));
        // Add zoom and rotation controls to the map.
        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.scrollZoom.disable();
    },

    onZoomIn: function () {
        this.map.zoomIn();
    },

    onZoomOut: function () {
        this.map.zoomOut();
    },

    playIn() {},

    activate: function () { },

    openQualityForm: function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })

        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 10);

        $('.Contact-contentMain').hide();
        $('.Contact-qualityFormWrapper').show();
    },

    closeQualityForm: function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })

        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 10);

        $('.Contact-contentMain').show();
        $('.Contact-qualityFormWrapper').hide();
    }
});

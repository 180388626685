var Backbone = require('backbone');
var _ = require('underscore');
var Cookie = require('js-cookie');
require('imports-loader!jquery-ui/ui/tabbable');

require('mobile/components/Button/Button');

require('./PopupAge.less');

module.exports = Backbone.View.extend({
    template: require('./PopupAge.jinja'),

    events: {
        'click .PopupAge-control--accept': 'onAccept',
        'click .PopupAge-control--reject': 'onReject'
    },

    initialize: function () {
        if (!app.els.$body.hasClass('ResponsiblePage') && !Cookie.get('hasValidAge')) {
            app.els.$popupContainer.append(this.render().el);
            this.playIn();
        }
    },

    render: function () {
        this.setElement(
            this.template.render({
                render: true
            })
        );

        this.$content = this.$('.PopupAge-content');

        return this;
    },

    playIn: function () {
        // Allowing to tab only on elements inside popup
        this.$tabbableOutsideElements = $(':tabbable').not(this.$(':tabbable'));
        this.$tabbableOutsideElements.attr('tabindex', '-1');

        app.els.$body.css('overflow', 'hidden');
        this.$el.fadeIn();
        this.$content.addClass('isVisible');
    },

    playOut: function () {
        this.$el.fadeOut();
        this.$content.removeClass('isVisible');

        this.$tabbableOutsideElements.removeAttr('tabindex');
        app.els.$body.css('overflow', 'visible');
    },

    onAccept: function () {
        Cookie.set('hasValidAge', 'true');
        this.playOut();
    },

    onReject: function () {
        window.location = '/responsobility/';
    },

    destroy: function () {
        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});

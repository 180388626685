var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/Notify/Notify.jinja"] = require( "mobile/components/Notify/Notify.jinja" );
dependencies["mobile/components/LegalMenuTop/LegalMenuTop.jinja"] = require( "mobile/components/LegalMenuTop/LegalMenuTop.jinja" );
dependencies["mobile/components/LegalForms/LegalFormRestaurants/LegalFormRestaurants.jinja"] = require( "mobile/components/LegalForms/LegalFormRestaurants/LegalFormRestaurants.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TopNav")) {
var t_11 = t_8.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_11);
output += "\n";
env.getTemplate("mobile/components/Notify/Notify.jinja", false, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "Notify")) {
var t_15 = t_12.Notify;
} else {
cb(new Error("cannot import 'Notify'")); return;
}
context.setVariable("Notify", t_15);
output += "\n";
env.getTemplate("mobile/components/LegalMenuTop/LegalMenuTop.jinja", false, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "LegalMenuTop")) {
var t_19 = t_16.LegalMenuTop;
} else {
cb(new Error("cannot import 'LegalMenuTop'")); return;
}
context.setVariable("LegalMenuTop", t_19);
output += "\n";
env.getTemplate("mobile/components/LegalForms/LegalFormRestaurants/LegalFormRestaurants.jinja", false, "assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "LegalFormRestaurants")) {
var t_23 = t_20.LegalFormRestaurants;
} else {
cb(new Error("cannot import 'LegalFormRestaurants'")); return;
}
context.setVariable("LegalFormRestaurants", t_23);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 8, colno = 50, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Партнерство для ресторанов и баров"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 9, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 9, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Партнерство для ресторанов и баров"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 14, colno = 9, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--about","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 14, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Для партнеров"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Legal\">\n    <div class=\"Legal-floatNav\"></div>\n    <div class=\"Legal-menuTop\">\n        ";
output += runtime.suppressValue((lineno = 21, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalMenuTop"), "LegalMenuTop", context, [runtime.makeKeywordArgs({"classes": "LegalMenuTop--legal","subpage": "restaurants","caller": (function (){var macro_t_34 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_35 = "";;
frame = frame.pop();
return new runtime.SafeString(t_35);
});
return macro_t_34;})()})])), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"Legal-contentWrapper\">\n        <div class=\"Legal-header\">\n            <div class=\"Legal-subtitle\">\n                <span class=\"Legal-subtitleStroke\"></span>\n                <div class=\"Legal-subtitleText\">";
output += runtime.suppressValue((lineno = 27, colno = 52, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Рестораны и бары"])), env.opts.autoescape);
output += "</div>\n            </div>\n\n            <div class=\"Legal-title\">\n                Заявка\n            </div>\n        </div>\n        <div class=\"Legal-content\">\n            <div class=\"Legal-text\">\n                <p>Если вы&nbsp;хотите, чтобы в&nbsp;вашем заведении были представлены лучшие сорта разливного российского и&nbsp;импортного пива, фасованного пива и&nbsp;напитков, просим вас заполнить форму обратной связи.</p>\n            </div>\n\n            <div class=\"Legal-form\">\n                ";
output += runtime.suppressValue((lineno = 40, colno = 44, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "LegalFormRestaurants"), "LegalFormRestaurants", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_36 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_37 = "";;
frame = frame.pop();
return new runtime.SafeString(t_37);
});
return macro_t_36;})()})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/LegalRestaurants/LegalRestaurants.jinja"] , dependencies)
var Backbone = require('backbone/backbone');
var isEmail = require('validator/lib/isEmail');

require('./Subscribe.less');

module.exports = Backbone.View.extend({
    el: '.Subscribe',

    events: {
        submit: 'submitSubscription'
    },

    initialize: function (options) {
        this.initSubscribe();

        this.options = options || {};

        this.$input = this.$('.Subscribe-email');
        this.$messageError = this.$('.Subscribe-message--error');
        this.$messageThanks = this.$('.Subscribe-message--thanks');
    },

    initSubscribe: function () {
        var self = this;

        // // For testing purposes * * * * * * * * * * * * * * * * *
        // $('.Subscribe').submit(function (e) {
        //     e.preventDefault();
        //     self.successSubscribing();
        // });
        // // For testing purposes * * * * * * * * * * * * * * * * *

        var $form = this.$el;

        this.$el.submit(function (e) {
            e.preventDefault();
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: JSON.stringify({
                    email: $form.find('.Subscribe-email').val(),
                    lang: window.app.data.lang
                }),
                cache: false,
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                error: function (err) {
                    // console.log('Could not connect to the registration server. Please try again later.');
                },
                success: function (data) {
                    if (data.result != 'success') {
                        self.alreadyInList(e);
                    } else {
                        self.successSubscribing(e);
                    }
                }
            });
        });
    },

    resetState: function () {
        this.$messageError.hide();
        this.$messageThanks.hide();
        this.$input.val('');
    },

    submitSubscription: function (e) {
        var value = this.$input.val();
        e.preventDefault();

        if (isEmail(value)) {
            this.$input.addClass('submitted');

            if (this.options.type === 'post') {
                this.trigger('submitted', value);
            } else {
                this.$messageThanks.fadeIn(
                    'fast',
                    function () {
                        setTimeout(
                            function () {
                                this.$messageThanks.fadeOut(
                                    function () {
                                        this.$input.val('');
                                        this.$input.removeClass('submitted');
                                    }.bind(this)
                                );
                            }.bind(this),
                            2000
                        );
                    }.bind(this)
                );
            }
        } else {
            this.$input.addClass('wrongEmail');

            this.$messageError.fadeIn(
                'fast',
                function () {
                    setTimeout(
                        function () {
                            this.$messageError.fadeOut('fast');
                            this.$input.val('');
                            this.$input.removeClass('wrongEmail');
                        }.bind(this),
                        2000
                    );
                }.bind(this)
            );
        }
    }
});

var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/QualityForm/QualityForm.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
[], 
["lang"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("lang", Object.prototype.hasOwnProperty.call(kwargs, "lang") ? kwargs["lang"] : "ru");var t_2 = "";t_2 += "\n    <form class=\"QualityForm CommonNewForm\" action=\"/api/product-quality-form/\" method=\"post\" enctype=\"multipart/form-data\">\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-name\">";
t_2 += runtime.suppressValue((lineno = 4, colno = 78, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Имя"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"name\" id=\"QualityForm-name\" placeholder=\"";
t_2 += runtime.suppressValue((lineno = 5, colno = 114, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Иван"])), env.opts.autoescape);
t_2 += "\" data-required=\"true\">\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-phone\">";
t_2 += runtime.suppressValue((lineno = 11, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Телефон"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"tel\" name=\"phone\" id=\"QualityForm-phone\" placeholder=\"+7 999 456-78-90\" data-required=\"true\">\n            </div>\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-email\">";
t_2 += runtime.suppressValue((lineno = 15, colno = 79, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Электронная почта"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"email\" name=\"email\" id=\"QualityForm-email\" placeholder=\"example@email.com\" data-required=\"true\">\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-city\">";
t_2 += runtime.suppressValue((lineno = 22, colno = 78, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Город"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"city\" id=\"QualityForm-city\" placeholder=\"";
t_2 += runtime.suppressValue((lineno = 23, colno = 114, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Москва"])), env.opts.autoescape);
t_2 += "\" data-required=\"true\">\n            </div>\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-address\">";
t_2 += runtime.suppressValue((lineno = 26, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес приобретения продукции"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"address\" id=\"QualityForm-address\" placeholder=\"";
t_2 += runtime.suppressValue((lineno = 27, colno = 120, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Красная площадь"])), env.opts.autoescape);
t_2 += "\" data-required=\"true\">\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-product-name\">";
t_2 += runtime.suppressValue((lineno = 33, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Название продукции"])), env.opts.autoescape);
t_2 += " *</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"product_name\" id=\"QualityForm-product-name\" placeholder=\"";
t_2 += runtime.suppressValue((lineno = 34, colno = 130, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Жигули Барное"])), env.opts.autoescape);
t_2 += "\" data-required=\"true\">\n            </div>\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-city\">";
t_2 += runtime.suppressValue((lineno = 37, colno = 78, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Упаковка"])), env.opts.autoescape);
t_2 += "*</label>\n                <div class=\"CommonNewForm-selectWrapper\">\n                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"package\" id=\"package\">\n                        <option value=\"";
t_2 += runtime.suppressValue((lineno = 40, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Банка"])), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue((lineno = 40, colno = 61, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Банка"])), env.opts.autoescape);
t_2 += "</option>\n                        <option value=\"";
t_2 += runtime.suppressValue((lineno = 41, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["ПЭТ-бутылка"])), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue((lineno = 41, colno = 67, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["ПЭТ-бутылка"])), env.opts.autoescape);
t_2 += "</option>\n                        <option value=\"";
t_2 += runtime.suppressValue((lineno = 42, colno = 43, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стеклянная бутылка"])), env.opts.autoescape);
t_2 += "\">";
t_2 += runtime.suppressValue((lineno = 42, colno = 74, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стеклянная бутылка"])), env.opts.autoescape);
t_2 += "</option>\n                    </select>\n\n                    <div class=\"CommonNewForm-customSelectWrapper\">\n                        <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">";
t_2 += runtime.suppressValue((lineno = 46, colno = 121, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Банка"])), env.opts.autoescape);
t_2 += "</div>\n                        <ul class=\"CommonNewForm-customSelectList\">\n                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
t_2 += runtime.suppressValue((lineno = 48, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Банка"])), env.opts.autoescape);
t_2 += "\" data-name=\"package\">";
t_2 += runtime.suppressValue((lineno = 48, colno = 138, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Банка"])), env.opts.autoescape);
t_2 += "</button></li>\n                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
t_2 += runtime.suppressValue((lineno = 49, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["ПЭТ-бутылка"])), env.opts.autoescape);
t_2 += "\" data-name=\"package\">";
t_2 += runtime.suppressValue((lineno = 49, colno = 144, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["ПЭТ-бутылка"])), env.opts.autoescape);
t_2 += "</button></li>\n                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
t_2 += runtime.suppressValue((lineno = 50, colno = 100, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стеклянная бутылка"])), env.opts.autoescape);
t_2 += "\" data-name=\"package\">";
t_2 += runtime.suppressValue((lineno = 50, colno = 151, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Стеклянная бутылка"])), env.opts.autoescape);
t_2 += "</button></li>\n                        </ul>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-volume\">";
t_2 += runtime.suppressValue((lineno = 59, colno = 80, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Объем, л."])), env.opts.autoescape);
t_2 += " *</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"volume\" id=\"QualityForm-volume\" placeholder=\"2\" data-required=\"true\">\n            </div>\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-datetime\">";
t_2 += runtime.suppressValue((lineno = 63, colno = 82, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Дата и время производства продукции"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"datetime\" id=\"QualityForm-datetime\" placeholder=\"10.04.2024, 5:00\" data-required=\"true\">\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <label class=\"CommonNewForm-label\" for=\"QualityForm-message\">";
t_2 += runtime.suppressValue((lineno = 70, colno = 81, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Сообщение"])), env.opts.autoescape);
t_2 += "*</label>\n                <input class=\"CommonNewForm-field\" type=\"text\" name=\"message\" id=\"QualityForm-message\" placeholder=\"";
t_2 += runtime.suppressValue((lineno = 71, colno = 120, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Отличный продукт!"])), env.opts.autoescape);
t_2 += "\" data-required=\"true\">\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldRow CommonNewForm-fieldRow--files\">\n            <div class=\"CommonNewForm-fieldGroup\">\n                <div class=\"CommonNewForm-files\">\n                    <div class=\"CommonNewForm-filesItem\">\n                        <div class=\"CommonNewForm-file\">\n                            <div class=\"CommonNewForm-fileTitle\">\n                                ";
t_2 += runtime.suppressValue((lineno = 81, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Фотография продукции"])), env.opts.autoescape);
t_2 += "\n                                <span class=\"CommonNewForm-fileTitleNote\">";
t_2 += runtime.suppressValue((lineno = 82, colno = 78, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["максимальный размер изображения"])), env.opts.autoescape);
t_2 += " 4 ";
t_2 += runtime.suppressValue((lineno = 82, colno = 123, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["мб"])), env.opts.autoescape);
t_2 += "</span>\n                            </div>\n                            <div class=\"CommonNewForm-fileUploadWrapper\">\n                                <label class=\"Button CommonNewForm-fileUploadBtn\" for=\"QualityForm-uploadFiles\">";
t_2 += runtime.suppressValue((lineno = 85, colno = 116, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Загрузить"])), env.opts.autoescape);
t_2 += "</label>\n                                <input class=\"CommonNewForm-fieldFile js-uploadFiles\" type=\"file\" name=\"files\" id=\"QualityForm-uploadFiles\" data-required=\"false\" data-multiple=\"true\">\n\n                                <svg class=\"CommonNewForm-fileUploadSuccessIcon\" xmlns=\"http://www.w3.org/2000/svg\" width=\"40\" height=\"40\" fill=\"none\">\n                                    <path fill=\"#1C1630\" d=\"M34.365 10.709a1.25 1.25 0 0 1 0 1.768L17.55 29.29a1.247 1.247 0 0 1-.961.363c-.345.021-.696-.1-.96-.363l-9.995-9.995a1.25 1.25 0 0 1 1.768-1.768l9.188 9.187L32.597 10.71a1.25 1.25 0 0 1 1.768 0Z\"/>\n                                </svg>\n                            </div>\n                        </div>\n\n                        <div class=\"CommonNewForm-uploadedFiles\">\n                            <ul class=\"CommonNewForm-uploadedFilesList js-uploadFilesList\"></ul>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"CommonNewForm-fieldGroup\">\n            <input class=\"CommonNewForm-checkbox\" type=\"checkbox\" name=\"confidential\" id=\"CommonNewForm-confidential\" data-required=\"true\">\n            <label class=\"CommonNewForm-checkboxLabel\" for=\"CommonNewForm-confidential\">\n                ";
if(runtime.contextOrFrameLookup(context, frame, "lang") == "en") {
t_2 += "\n                I have read and agree with the <a href=\"";
t_2 += runtime.suppressValue((lineno = 106, colno = 62, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:confidential"])), env.opts.autoescape);
t_2 += "\">privacy and data processing policy</a>\n                ";
;
}
else {
t_2 += "\n                С&nbsp;<a href=\"";
t_2 += runtime.suppressValue((lineno = 108, colno = 38, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:confidential"])), env.opts.autoescape);
t_2 += "\">политикой защиты и&nbsp;обработки данных</a> ознакомлен(а), даю согласие на&nbsp;их&nbsp;обработку\n                ";
;
}
t_2 += "\n            </label>\n        </div>\n\n        <div class=\"CommonNewForm-fieldGroup\">\n            <input class=\"CommonNewForm-checkbox\" type=\"checkbox\" name=\"subscribe\" id=\"CommonNewForm-subscribe\" checked>\n            <label class=\"CommonNewForm-checkboxLabel\" for=\"CommonNewForm-subscribe\">\n                ";
if(runtime.contextOrFrameLookup(context, frame, "lang") == "en") {
t_2 += "\n                I agree to receive news from Mosbrew\n                ";
;
}
else {
t_2 += "\n                Хочу получать новости от Mosbrew\n                ";
;
}
t_2 += "\n            </label>\n        </div>\n\n        <div class=\"CommonNewForm-btnWrapper\">\n            <input type=\"hidden\" name=\"lang\" value=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "lang"), env.opts.autoescape);
t_2 += "\">\n            <button type=\"submit\" class=\"Button CommonNewForm-btn\">\n                <span class=\"Button-content\">";
t_2 += runtime.suppressValue((lineno = 127, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Отправить"])), env.opts.autoescape);
t_2 += "</span>\n                <span class=\"Button-loader\"></span>\n            </button>\n        </div>\n    </form>\n\n    ";
t_2 += runtime.suppressValue((lineno = 133, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_2 += "\n";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("QualityForm");
context.setVariable("QualityForm", macro_t_1);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/QualityForm/QualityForm.jinja"] , dependencies)
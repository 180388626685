var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/CareerResumeForm/CareerResumeForm.jinja"] = require( "mobile/components/CareerResumeForm/CareerResumeForm.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/CareerCategory/CareerCategory.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/CareerCategory/CareerCategory.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/CareerCategory/CareerCategory.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/CareerResumeForm/CareerResumeForm.jinja", false, "assets/app/mobile/pages/CareerCategory/CareerCategory.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "CareerResumeForm")) {
var t_11 = t_8.CareerResumeForm;
} else {
cb(new Error("cannot import 'CareerResumeForm'")); return;
}
context.setVariable("CareerResumeForm", t_11);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/CareerCategory/CareerCategory.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
output += t_16;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_19,t_18) {
if(t_19) { cb(t_19); return; }
output += t_18;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 6, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 7, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 9;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 12, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--career","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 12, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Career\">\n    <div class=\"Career-floatNav\"></div>\n    <div class=\"Career-bg\">\n        <picture>\n            <source srcset=\"/static/img/front/career/bg.png 1x, /static/img/front/career/bg@2x.png 2x\" media=\"(min-width: 768px)\" />\n            <img\n                class=\"Career-bgImage\"\n                src=\"/static/img/front/career/bg-mobile.png\"\n                srcset=\"/static/img/front/career/bg-mobile.png 1x, /static/img/front/career/bg-mobile@2x.png 2x\"\n                alt=\"\"\n                decoding=\"async\"\n            />\n        </picture>\n    </div>\n    <div class=\"Career-main\">\n        <div class=\"Career-header\">\n            <div class=\"Career-subtitle\">\n                <span class=\"Career-subtitleStroke\"></span>\n                <div class=\"Career-subtitleText\">";
output += runtime.suppressValue((lineno = 34, colno = 53, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</div>\n            </div>\n            <h1 class=\"Career-title\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"title"), env.opts.autoescape);
output += "\n            </h1>\n            <div class=\"Career-text\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"description"), env.opts.autoescape);
output += "\n            </div>\n        </div>\n        <div class=\"Career-container\">\n            ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "careerVacancies")) > 0) {
output += "<div class=\"Career-section Career-section--vacancies\">\n                <div class=\"CareerFilters\">\n                    <form class=\"CommonNewForm js-filters\" action=\"/api/filter-vacancies/\" method=\"post\">\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"location")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"location\">Локация</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"location\" id=\"location\">\n                                        ";
frame = frame.push();
var t_28 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"locations");
if(t_28) {t_28 = runtime.fromIterator(t_28);
var t_27 = t_28.length;
for(var t_26=0; t_26 < t_28.length; t_26++) {
var t_29 = t_28[t_26];
frame.set("location", t_29);
frame.set("loop.index", t_26 + 1);
frame.set("loop.index0", t_26);
frame.set("loop.revindex", t_27 - t_26);
frame.set("loop.revindex0", t_27 - t_26 - 1);
frame.set("loop.first", t_26 === 0);
frame.set("loop.last", t_26 === t_27 - 1);
frame.set("loop.length", t_27);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_29),"value"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_29),"text"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                    <div class=\"CommonNewForm-customSelectWrapper\">\n                                        <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"locations")),0)),"text"), env.opts.autoescape);
output += "</div>\n                                        <ul class=\"CommonNewForm-customSelectList\">\n                                            ";
frame = frame.push();
var t_32 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"locations");
if(t_32) {t_32 = runtime.fromIterator(t_32);
var t_31 = t_32.length;
for(var t_30=0; t_30 < t_32.length; t_30++) {
var t_33 = t_32[t_30];
frame.set("location", t_33);
frame.set("loop.index", t_30 + 1);
frame.set("loop.index0", t_30);
frame.set("loop.revindex", t_31 - t_30);
frame.set("loop.revindex0", t_31 - t_30 - 1);
frame.set("loop.first", t_30 === 0);
frame.set("loop.last", t_30 === t_31 - 1);
frame.set("loop.length", t_31);
output += "\n                                                <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_33),"value"), env.opts.autoescape);
output += "\" data-name=\"location\">";
output += runtime.suppressValue(runtime.memberLookup((t_33),"text"), env.opts.autoescape);
output += "</button></li>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </ul>\n                                    </div>\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"area")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"area\">Направление</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"area\" id=\"area\">\n                                        ";
frame = frame.push();
var t_36 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"areas");
if(t_36) {t_36 = runtime.fromIterator(t_36);
var t_35 = t_36.length;
for(var t_34=0; t_34 < t_36.length; t_34++) {
var t_37 = t_36[t_34];
frame.set("area", t_37);
frame.set("loop.index", t_34 + 1);
frame.set("loop.index0", t_34);
frame.set("loop.revindex", t_35 - t_34);
frame.set("loop.revindex0", t_35 - t_34 - 1);
frame.set("loop.first", t_34 === 0);
frame.set("loop.last", t_34 === t_35 - 1);
frame.set("loop.length", t_35);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_37),"slug"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_37),"name"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                    <div class=\"CommonNewForm-customSelectWrapper\">\n                                        <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"areas")),0)),"name"), env.opts.autoescape);
output += "</div>\n                                        <ul class=\"CommonNewForm-customSelectList\">\n                                            ";
frame = frame.push();
var t_40 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"areas");
if(t_40) {t_40 = runtime.fromIterator(t_40);
var t_39 = t_40.length;
for(var t_38=0; t_38 < t_40.length; t_38++) {
var t_41 = t_40[t_38];
frame.set("area", t_41);
frame.set("loop.index", t_38 + 1);
frame.set("loop.index0", t_38);
frame.set("loop.revindex", t_39 - t_38);
frame.set("loop.revindex0", t_39 - t_38 - 1);
frame.set("loop.first", t_38 === 0);
frame.set("loop.last", t_38 === t_39 - 1);
frame.set("loop.length", t_39);
output += "\n                                                <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_41),"slug"), env.opts.autoescape);
output += "\" data-name=\"area\">";
output += runtime.suppressValue(runtime.memberLookup((t_41),"name"), env.opts.autoescape);
output += "</button></li>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </ul>\n                                    </div>\n                                </div>\n                            </div>\n                        ";
;
}
output += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "careerPage")),"filters")),"experience")) {
output += "\n                            <div class=\"CommonNewForm-fieldGroup\">\n                                <label class=\"CommonNewForm-label\" for=\"experience\">Опыт работы</label>\n                                <div class=\"CommonNewForm-selectWrapper\">\n                                    <select class=\"CommonNewForm-field CommonNewForm-field--select\" name=\"experience\" id=\"experience\">\n                                        ";
frame = frame.push();
var t_44 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"experience");
if(t_44) {t_44 = runtime.fromIterator(t_44);
var t_43 = t_44.length;
for(var t_42=0; t_42 < t_44.length; t_42++) {
var t_45 = t_44[t_42];
frame.set("experience", t_45);
frame.set("loop.index", t_42 + 1);
frame.set("loop.index0", t_42);
frame.set("loop.revindex", t_43 - t_42);
frame.set("loop.revindex0", t_43 - t_42 - 1);
frame.set("loop.first", t_42 === 0);
frame.set("loop.last", t_42 === t_43 - 1);
frame.set("loop.length", t_43);
output += "\n                                            <option value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_45),"value"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.memberLookup((t_45),"text"), env.opts.autoescape);
output += "</option>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </select>\n\n                                <div class=\"CommonNewForm-customSelectWrapper\">\n                                    <div class=\"CommonNewForm-field CommonNewForm-field--select CommonNewForm-customSelectValue\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"experience")),0)),"text"), env.opts.autoescape);
output += "</div>\n                                    <ul class=\"CommonNewForm-customSelectList\">\n                                        ";
frame = frame.push();
var t_48 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "filters")),"experience");
if(t_48) {t_48 = runtime.fromIterator(t_48);
var t_47 = t_48.length;
for(var t_46=0; t_46 < t_48.length; t_46++) {
var t_49 = t_48[t_46];
frame.set("experience", t_49);
frame.set("loop.index", t_46 + 1);
frame.set("loop.index0", t_46);
frame.set("loop.revindex", t_47 - t_46);
frame.set("loop.revindex0", t_47 - t_46 - 1);
frame.set("loop.first", t_46 === 0);
frame.set("loop.last", t_46 === t_47 - 1);
frame.set("loop.length", t_47);
output += "\n                                            <li><button class=\"js-customSelectOption\" type=\"button\" data-value=\"";
output += runtime.suppressValue(runtime.memberLookup((t_49),"value"), env.opts.autoescape);
output += "\" data-name=\"experience\">";
output += runtime.suppressValue(runtime.memberLookup((t_49),"text"), env.opts.autoescape);
output += "</button></li>\n                                        ";
;
}
}
frame = frame.pop();
output += "\n                                    </ul>\n                                </div>\n                            </div>\n                        </div>\n                        ";
;
}
output += "\n                    </form>\n                </div>\n                <div class=\"Career-vacanciesList\" id=\"CareerVacanciesList\">\n                    ";
frame = frame.push();
var t_52 = runtime.contextOrFrameLookup(context, frame, "careerVacancies");
if(t_52) {t_52 = runtime.fromIterator(t_52);
var t_51 = t_52.length;
for(var t_50=0; t_50 < t_52.length; t_50++) {
var t_53 = t_52[t_50];
frame.set("vacancy", t_53);
frame.set("loop.index", t_50 + 1);
frame.set("loop.index0", t_50);
frame.set("loop.revindex", t_51 - t_50);
frame.set("loop.revindex0", t_51 - t_50 - 1);
frame.set("loop.first", t_50 === 0);
frame.set("loop.last", t_50 === t_51 - 1);
frame.set("loop.length", t_51);
output += "<div class=\"CareerVacancyCard\">\n                        <a class=\"CareerVacancyCard-link\" href=\"/career/vacancy/";
output += runtime.suppressValue(runtime.memberLookup((t_53),"id"), env.opts.autoescape);
output += "/\">\n                            <h3 class=\"CareerVacancyCard-title\">";
output += runtime.suppressValue(runtime.memberLookup((t_53),"name"), env.opts.autoescape);
output += "</h3>\n                            <div class=\"CareerVacancyCard-info\">\n                                <div class=\"CareerVacancyCard-description\">Опыт: ";
output += runtime.suppressValue(runtime.memberLookup((t_53),"experience"), env.opts.autoescape);
if(runtime.memberLookup((t_53),"city")) {
output += ", Город: ";
output += runtime.suppressValue(runtime.memberLookup((t_53),"city"), env.opts.autoescape);
;
}
output += "</div>\n                                <div class=\"CareerVacancyCard-linkMore\">\n                                    <span class=\"CareerVacancyCard-linkMoreText\">Подробнее</span>\n                                    <svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 19 12\" class=\"CareerVacancyCard-linkMoreIcon\" width=\"19\" height=\"12\"><path fill=\"#202020\" d=\"m19 6.001-5.045 5.043-1.414-1.414L15.17 7H.57V5h14.6l-2.63-2.63L13.955.956 19 6.001Z\"/></svg>\n                                </div>\n                            </div>\n                        </a>\n                    </div>\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n            </div>\n            ";
;
}
else {
output += "\n                ";
if(env.getFilter("length").call(context, runtime.contextOrFrameLookup(context, frame, "careerDirections")) > 0) {
output += "<section class=\"Career-section\">\n                    <h2 class=\"Career-sectionTitle\">Направления</h2>\n                    <ul class=\"CareerDirections CareerDirections--";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "careerCategory"), env.opts.autoescape);
output += "\">\n                        ";
frame = frame.push();
var t_56 = runtime.contextOrFrameLookup(context, frame, "careerDirections");
if(t_56) {t_56 = runtime.fromIterator(t_56);
var t_55 = t_56.length;
for(var t_54=0; t_54 < t_56.length; t_54++) {
var t_57 = t_56[t_54];
frame.set("item", t_57);
frame.set("loop.index", t_54 + 1);
frame.set("loop.index0", t_54);
frame.set("loop.revindex", t_55 - t_54);
frame.set("loop.revindex0", t_55 - t_54 - 1);
frame.set("loop.first", t_54 === 0);
frame.set("loop.last", t_54 === t_55 - 1);
frame.set("loop.length", t_55);
output += "<li class=\"CareerDirections-item\">\n                            <a class=\"CareerDirections-itemLink\" href=\"/career/";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "careerCategory"), env.opts.autoescape);
output += "/";
output += runtime.suppressValue(runtime.memberLookup((t_57),"slug"), env.opts.autoescape);
output += "/\">\n                                <div class=\"CareerDirections-itemContent\">\n                                    <div class=\"CareerDirections-itemBg\">\n                                        <img\n                                            src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_57),"img")),"w370"), env.opts.autoescape);
output += "\"\n                                            srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_57),"img")),"w370"), env.opts.autoescape);
output += " 1x, ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_57),"img")),"w768"), env.opts.autoescape);
output += " 2x\"\n                                            alt=\"\"\n                                            loading=\"lazy\"\n                                            decoding=\"async\"\n                                        >\n                                    </div>\n                                    <h3 class=\"CareerDirections-itemTitle\">";
output += runtime.suppressValue(runtime.memberLookup((t_57),"name"), env.opts.autoescape);
output += "</h3>\n                                </div>\n                            </a>\n                            ";
if(runtime.memberLookup((t_57),"vacancies_count")) {
output += "\n                            <div class=\"CareerDirections-itemNote\">";
output += runtime.suppressValue(runtime.memberLookup((t_57),"vacancies_count"), env.opts.autoescape);
output += " ";
output += runtime.suppressValue(env.getFilter("pluralize").call(context, runtime.memberLookup((t_57),"vacancies_count"),"вакансия,вакансии,вакансий"), env.opts.autoescape);
output += "</div>\n                            ";
;
}
output += "\n                        </li>\n                        ";
;
}
}
frame = frame.pop();
output += "</ul>\n                </section>\n                ";
;
}
output += "\n            ";
;
}
output += "\n\n            ";
if(runtime.contextOrFrameLookup(context, frame, "careerCategory") != "internships") {
output += "\n            <section class=\"Career-section\">\n                <h2 class=\"Career-sectionTitle\">Нет подходящей вакансии?</h2>\n                <p class=\"Career-sectionText\">Оставьте свои данные, и&nbsp;мы&nbsp;попробуем подобрать варианты</p>\n\n                ";
output += runtime.suppressValue((lineno = 166, colno = 40, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerResumeForm"), "CareerResumeForm", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_58 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_59 = "";;
frame = frame.pop();
return new runtime.SafeString(t_59);
});
return macro_t_58;})()})])), env.opts.autoescape);
output += "\n            </section>\n            ";
;
}
else {
output += "\n            <div class=\"Career-section\">\n                <p class=\"Career-sectionText\"><b>К сожалению, сейчас нет открытых стажировок.</b></p>\n                <p class=\"Career-sectionText\">\n                    Мы будем очень рады получить ваш отклик! <br>\n                    Оставьте свой email, и вам придет уведомление, когда стажировки появятся.\n                </p>\n\n                <a href=\"/career/internships/apply\" class=\"Button\">Оставить заявку</a>\n            </div>\n            ";
;
}
output += "\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/CareerCategory/CareerCategory.jinja"] , dependencies)
var Backbone = require('backbone');
var _ = require('underscore');
var Stickyfill = require('stickyfilljs');

require('mobile/components/Button/Button');

require('./TopNav.less');

var MIN_SCREEN_SIZE = 1024;

module.exports = Backbone.View.extend({
    template: require('./TopNav.jinja'),

    el: '.TopNav',

    events: {
        'click .TopNav-button': 'onToggleMenu',
        'click button.TopNav-title': 'onShowSections',
        'click .TopNav-overlay': 'onCloseSections',
        'touchmove': 'onTouchMove'
    },

    initialize: function (options) {
        _.bindAll(this, '_checkScroll', 'checkHorizontalScroll');

        this.options = options || {};
        this.data = this.options.data || {};

        this.state = new Backbone.Model({
            isOpened: false
        });

        if (!app.els.$body.hasClass('ResponsiblePage')) {
            this.checkScroll = _.throttle(this._checkScroll, 50);
        }

        this.checkScroll();
    },

    onTouchMove: function (e) {
        e.preventDefault();
    },

    onToggleMenu: function () {
        if (this.$el.hasClass('isOpened')) {
            this.$el.removeClass('isOpened');
        } else {
            this.$el.addClass('isOpened');
        }
    },

    render: function () {
        var params = {};
        if (this.options.type) {
            params.classes = 'TopNav--' + this.options.type;
        }

        this.setElement(this.template.render(_.extend(params, { currentUrl: window.location.pathname, render: true })));

        this.$inner = this.$('.TopNav-inner');

        return this;
    },

    _checkScroll: function (e) {
        if (app.els.$window.scrollTop() >= 50) {
            this.$el.addClass('isActive');
        } else {
            this.$el.removeClass('isActive');
        }
    },

    checkHorizontalScroll: function () {
        if (this.$el.hasClass('isActive') && app.els.$window.width() < MIN_SCREEN_SIZE && this.$inner) {
            this.$inner.css({ left: -app.els.$window.scrollLeft() });
        }
    },

    activate: function () {
        app.els.$window.on('scroll', this.checkScroll);
        app.els.$window.on('scroll', this.checkHorizontalScroll);

        if (this.options.type && this.options.type === 'sticky') {
            Stickyfill.add(this.$el);
        }
    },

    onShowSections: function () {
        this.$el.toggleClass('isDropdownOpened');
    },

    onCloseSections: function () {
        this.$el.removeClass('isDropdownOpened');
    },

    detach: function () {
        app.els.$window.off('scroll', this.checkScroll);
        this.undelegateEvents();
        this.$el.removeData().unbind();
    },

    destroy: function () {
        app.els.$window.off('scroll', this.checkScroll);

        this.undelegateEvents();
        this.$el.removeData().unbind();
        this.remove();
    }
});

var Page = require('front/pages/Page/Page');
var isEmail = require('validator/lib/isEmail');

require('./MediaSubscribe.less');

module.exports = Page.extend({
    template: require('./MediaSubscribe.jinja'),

    title: 'Подпишитесь на нашу рассылку',

    el: '.MediaSubscribe',

    events: {
        submit: 'submitSubscription',
    },

    initialize: function (options) {
        Page.prototype.initialize.call(this, options);
    },

    submitSubscription: function (e) {
        e.preventDefault();

        var $form = this.$('.MediaSubscribe-form');
        var $submitBtn = this.$('.MediaSubscribe-btn');
        var value = this.$('#mediaSubscribeEmail').val();

        $form.removeClass('isInvalid');

        if (isEmail(value)) {
            $submitBtn.attr('disabled', true);

            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: JSON.stringify({
                    email: value,
                    lang: window.app.data.lang
                }),
                cache: false,
                dataType: 'json',
                contentType: 'application/json; charset=utf-8',
                error: function (err) {
                    console.log('error response', err);

                    // Мда…
                    if (err.status === 200 && err.responseText === 'success') {
                        $form.addClass('isSended');
                    } else {
                        $form.addClass('isInvalid');
                    }
                },
                success: function (response) {
                    console.log('response', response);

                    if (response === 'success') {
                        $form.addClass('isSended');
                    } else {
                        $submitBtn.removeAttr('disabled');
                        $form.addClass('isInvalid');
                    }
                }
            });
        } else {
            $form.addClass('isInvalid');
        }
    }
});

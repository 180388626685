var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/Slider/SliderIndex.jinja"] = require( "mobile/components/Slider/SliderIndex.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/PostItem/PostItem.jinja"] = require( "mobile/components/PostItem/PostItem.jinja" );
dependencies["mobile/components/ViewCount/ViewCount.jinja"] = require( "mobile/components/ViewCount/ViewCount.jinja" );
dependencies["mobile/components/Notify/Notify.jinja"] = require( "mobile/components/Notify/Notify.jinja" );
dependencies["mobile/components/MenuTop/MenuTop.jinja"] = require( "mobile/components/MenuTop/MenuTop.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/People/People.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/People/People.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/Slider/SliderIndex.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "SliderIndex")) {
var t_11 = t_8.SliderIndex;
} else {
cb(new Error("cannot import 'SliderIndex'")); return;
}
context.setVariable("SliderIndex", t_11);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "TopNav")) {
var t_15 = t_12.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_15);
output += "\n";
env.getTemplate("mobile/components/PostItem/PostItem.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "PostItem")) {
var t_19 = t_16.PostItem;
} else {
cb(new Error("cannot import 'PostItem'")); return;
}
context.setVariable("PostItem", t_19);
output += "\n";
env.getTemplate("mobile/components/ViewCount/ViewCount.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "ViewCount")) {
var t_23 = t_20.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_23);
output += "\n";
env.getTemplate("mobile/components/Notify/Notify.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "Notify")) {
var t_27 = t_24.Notify;
} else {
cb(new Error("cannot import 'Notify'")); return;
}
context.setVariable("Notify", t_27);
output += "\n";
env.getTemplate("mobile/components/MenuTop/MenuTop.jinja", false, "assets/app/mobile/pages/People/People.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "MenuTop")) {
var t_31 = t_28.MenuTop;
} else {
cb(new Error("cannot import 'MenuTop'")); return;
}
context.setVariable("MenuTop", t_31);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 10, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 11, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 15;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 16, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--people","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 16, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Люди"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 19;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"People\">\n        <div class=\"People-floatNav\"></div>\n        <div class=\"People-background\">\n            <img class=\"People-backgroundImage\"\n            src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img"), env.opts.autoescape);
output += "\"\n            srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peopleData")),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n            alt=\"\">\n        </div>\n        <div class=\"People-menuTop\">\n            ";
output += runtime.suppressValue((lineno = 32, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "MenuTop"), "MenuTop", context, [runtime.makeKeywordArgs({"subpage": "people","caller": (function (){var macro_t_42 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_43 = "";;
frame = frame.pop();
return new runtime.SafeString(t_43);
});
return macro_t_42;})()})])), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"People-contentWrapper\">\n            <div class=\"People-header\">\n                <div class=\"People-subtitle\">\n                    <span class=\"People-subtitleStroke\"></span>\n                    <div class=\"People-subtitleText\">";
output += runtime.suppressValue((lineno = 38, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["ЛЮДИ"])), env.opts.autoescape);
output += "</div>\n                </div>\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"text")) {
output += "<div class=\"People-description\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"text"), env.opts.autoescape);
output += "</div>";
;
}
output += "\n            </div>\n            ";
frame = frame.push();
var t_46 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "peoplePage")),"persons");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("person", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
output += "\n                <div class=\"People-item\">\n                    <img class=\"People-image\"\n                    src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"img")),"w1024"), env.opts.autoescape);
output += "\"\n                    srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"img")),"w720"), env.opts.autoescape);
output += " 720w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_47),"img")),"w1440"), env.opts.autoescape);
output += " 1440w\"\n                    sizes=\"\"\n                    alt=\"\">\n                    <div class=\"People-text\">\n                        <div class=\"People-name\">";
output += runtime.suppressValue(runtime.memberLookup((t_47),"name"), env.opts.autoescape);
output += "</div>\n                        <div class=\"People-position\">";
output += runtime.suppressValue(runtime.memberLookup((t_47),"department"), env.opts.autoescape);
output += "</div>\n                    </div>\n                </div>\n            ";
;
}
}
frame = frame.pop();
output += "\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/People/People.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/Grid/Grid.jinja"] = require( "front/components/Grid/Grid.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["front/components/MenuLeft/MenuLeft.jinja"] = require( "front/components/MenuLeft/MenuLeft.jinja" );
dependencies["mobile/components/Story/Story.jinja"] = require( "mobile/components/Story/Story.jinja" );
dependencies["mobile/components/MenuTop/MenuTop.jinja"] = require( "mobile/components/MenuTop/MenuTop.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/History/History.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/History/History.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += " \n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "svgSprite")) {
var t_10 = t_7.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_10);
output += " \n";
env.getTemplate("front/components/Grid/Grid.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
t_11.getExported(function(t_13,t_11) {
if(t_13) { cb(t_13); return; }
if(Object.prototype.hasOwnProperty.call(t_11, "Grid")) {
var t_14 = t_11.Grid;
} else {
cb(new Error("cannot import 'Grid'")); return;
}
context.setVariable("Grid", t_14);
output += " \n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
t_15.getExported(function(t_17,t_15) {
if(t_17) { cb(t_17); return; }
if(Object.prototype.hasOwnProperty.call(t_15, "TopNav")) {
var t_18 = t_15.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_18);
output += "\n";
env.getTemplate("front/components/MenuLeft/MenuLeft.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
t_19.getExported(function(t_21,t_19) {
if(t_21) { cb(t_21); return; }
if(Object.prototype.hasOwnProperty.call(t_19, "MenuLeft")) {
var t_22 = t_19.MenuLeft;
} else {
cb(new Error("cannot import 'MenuLeft'")); return;
}
context.setVariable("MenuLeft", t_22);
output += " \n";
env.getTemplate("mobile/components/Story/Story.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_24,t_23) {
if(t_24) { cb(t_24); return; }
t_23.getExported(function(t_25,t_23) {
if(t_25) { cb(t_25); return; }
if(Object.prototype.hasOwnProperty.call(t_23, "Story")) {
var t_26 = t_23.Story;
} else {
cb(new Error("cannot import 'Story'")); return;
}
context.setVariable("Story", t_26);
output += "\n";
env.getTemplate("mobile/components/MenuTop/MenuTop.jinja", false, "assets/app/mobile/pages/History/History.jinja", false, function(t_28,t_27) {
if(t_28) { cb(t_28); return; }
t_27.getExported(function(t_29,t_27) {
if(t_29) { cb(t_29); return; }
if(Object.prototype.hasOwnProperty.call(t_27, "MenuTop")) {
var t_30 = t_27.MenuTop;
} else {
cb(new Error("cannot import 'MenuTop'")); return;
}
context.setVariable("MenuTop", t_30);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_32,t_31) {
if(t_32) { cb(t_32); return; }
output += t_31;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_34,t_33) {
if(t_34) { cb(t_34); return; }
output += t_33;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_36,t_35) {
if(t_36) { cb(t_36); return; }
output += t_35;
output += " \n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_38,t_37) {
if(t_38) { cb(t_38); return; }
output += t_37;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("libs"))(env, context, frame, runtime, function(t_40,t_39) {
if(t_40) { cb(t_40); return; }
output += t_39;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 10, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 10, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["История"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Компания"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue((lineno = 11, colno = 47, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["История"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 13;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 14, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--history","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 14, colno = 87, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["История"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 17;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "<div class=\"History\">\n\n        <div class=\"History-floatNav\"></div>\n        <div class=\"History-menuTop\">\n            ";
output += runtime.suppressValue((lineno = 22, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "MenuTop"), "MenuTop", context, [runtime.makeKeywordArgs({"classes": "MenuTop--principles","subpage": "history","caller": (function (){var macro_t_41 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_42 = "";;
frame = frame.pop();
return new runtime.SafeString(t_42);
});
return macro_t_41;})()})])), env.opts.autoescape);
output += "\n        </div>\n\n        <div class=\"History-years\">\n            <div class=\"History-years-inner\">\n            ";
var t_43;
t_43 = runtime.contextOrFrameLookup(context, frame, "None");
frame.set("previous_year", t_43, true);
if(frame.topLevel) {
context.setVariable("previous_year", t_43);
}
if(frame.topLevel) {
context.addExport("previous_year", t_43);
}
output += "\n\n            ";
frame = frame.push();
var t_46 = runtime.contextOrFrameLookup(context, frame, "historyStories");
if(t_46) {t_46 = runtime.fromIterator(t_46);
var t_45 = t_46.length;
for(var t_44=0; t_44 < t_46.length; t_44++) {
var t_47 = t_46[t_44];
frame.set("years", t_47);
frame.set("loop.index", t_44 + 1);
frame.set("loop.index0", t_44);
frame.set("loop.revindex", t_45 - t_44);
frame.set("loop.revindex0", t_45 - t_44 - 1);
frame.set("loop.first", t_44 === 0);
frame.set("loop.last", t_44 === t_45 - 1);
frame.set("loop.length", t_45);
if(runtime.memberLookup((t_47),"year") == runtime.contextOrFrameLookup(context, frame, "previous_year")) {
;
}
else {
output += "<a href=\"#";
output += runtime.suppressValue(runtime.memberLookup((t_47),"year"), env.opts.autoescape);
output += "\" data-year=\"";
output += runtime.suppressValue(runtime.memberLookup((t_47),"year"), env.opts.autoescape);
output += "\" class=\"History-yearLink\">";
output += runtime.suppressValue(runtime.memberLookup((t_47),"year"), env.opts.autoescape);
output += "</a>";
;
}
var t_48;
t_48 = runtime.memberLookup((t_47),"year");
frame.set("previous_year", t_48, true);
if(frame.topLevel) {
context.setVariable("previous_year", t_48);
}
if(frame.topLevel) {
context.addExport("previous_year", t_48);
}
;
}
}
frame = frame.pop();
output += "\n\n            <a href=\"#2006\" data-year=\"2006\" class=\"History-yearLink\">2006</a>\n            </div>\n        </div>\n\n        <div class=\"History-content\">\n            <div class=\"History-header\">\n                <div class=\"Principles-subtitle\">\n                    <span class=\"History-subtitleStroke\"></span>\n                    <div class=\"History-subtitleText\">";
output += runtime.suppressValue((lineno = 45, colno = 58, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["История"])), env.opts.autoescape);
output += "</div>\n                </div>\n                <div class=\"History-description\">\n                    ";
output += runtime.suppressValue(env.getFilter("safe").call(context, (lineno = 48, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Московская Пивоваренная Компания вышла на российский рынок в сентябре 2008 года.\n                    C&nbsp;тех пор мы радуем вас качественными напитками мирового уровня."]))), env.opts.autoescape);
output += "\n                </div>\n            </div>\n\n            <div class=\"History-list\">\n                ";
var t_49;
t_49 = runtime.contextOrFrameLookup(context, frame, "None");
frame.set("previous_year", t_49, true);
if(frame.topLevel) {
context.setVariable("previous_year", t_49);
}
if(frame.topLevel) {
context.addExport("previous_year", t_49);
}
output += "\n\n                ";
frame = frame.push();
var t_52 = runtime.contextOrFrameLookup(context, frame, "historyStories");
if(t_52) {t_52 = runtime.fromIterator(t_52);
var t_51 = t_52.length;
for(var t_50=0; t_50 < t_52.length; t_50++) {
var t_53 = t_52[t_50];
frame.set("years", t_53);
frame.set("loop.index", t_50 + 1);
frame.set("loop.index0", t_50);
frame.set("loop.revindex", t_51 - t_50);
frame.set("loop.revindex0", t_51 - t_50 - 1);
frame.set("loop.first", t_50 === 0);
frame.set("loop.last", t_50 === t_51 - 1);
frame.set("loop.length", t_51);
if(runtime.memberLookup((t_53),"year") == runtime.contextOrFrameLookup(context, frame, "previous_year")) {
;
}
else {
output += "<div class=\"History-listBlock\">\n                            <h2 id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_53),"year"), env.opts.autoescape);
output += "\"\n                                class=\"History-year\"\n                                data-year=\"";
output += runtime.suppressValue(runtime.memberLookup((t_53),"year"), env.opts.autoescape);
output += "\"><span>";
output += runtime.suppressValue(runtime.memberLookup((t_53),"year"), env.opts.autoescape);
output += "</span></h2>\n\n                            <div class=\"History-news\" data-year=\"";
output += runtime.suppressValue(runtime.memberLookup((t_53),"year"), env.opts.autoescape);
output += "\">\n                                ";
frame = frame.push();
var t_56 = runtime.contextOrFrameLookup(context, frame, "historyStories");
if(t_56) {t_56 = runtime.fromIterator(t_56);
var t_55 = t_56.length;
for(var t_54=0; t_54 < t_56.length; t_54++) {
var t_57 = t_56[t_54];
frame.set("story", t_57);
frame.set("loop.index", t_54 + 1);
frame.set("loop.index0", t_54);
frame.set("loop.revindex", t_55 - t_54);
frame.set("loop.revindex0", t_55 - t_54 - 1);
frame.set("loop.first", t_54 === 0);
frame.set("loop.last", t_54 === t_55 - 1);
frame.set("loop.length", t_55);
if(runtime.memberLookup((t_57),"year") == runtime.memberLookup((t_53),"year")) {
output += runtime.suppressValue((lineno = 67, colno = 48, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Story"), "Story", context, [t_57])), env.opts.autoescape);
;
}
;
}
}
frame = frame.pop();
output += "\n                            </div>\n                        </div>";
;
}
var t_58;
t_58 = runtime.memberLookup((t_53),"year");
frame.set("previous_year", t_58, true);
if(frame.topLevel) {
context.setVariable("previous_year", t_58);
}
if(frame.topLevel) {
context.addExport("previous_year", t_58);
}
;
}
}
frame = frame.pop();
output += "\n\n                <div class=\"History-launchBlock\">\n                    <h2 id=\"2006\" class=\"History-year\" data-year=\"2006\"><span>2006</span></h2>\n\n                    <p class=\"History-launch\">\n                        ";
output += runtime.suppressValue((lineno = 80, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Создание Московской Пивоваренной Компании.\n                        Начало строительства пивоваренного завода в городе Мытищи Московской области."])), env.opts.autoescape);
output += "\n                    </p>\n                </div>\n            </div>\n        </div>\n    </div>";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_libs(env, context, frame, runtime, cb) {
var lineno = 89;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "libs", b_libs, frame, runtime, function(t_59,hole_217) {
if(t_59) { cb(t_59); return; }
hole_217 = runtime.markSafe(hole_217);
output += "\n    ";
output += runtime.suppressValue(hole_217, env.opts.autoescape);
output += "\n\n    <script src=\"";
output += runtime.suppressValue((lineno = 92, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["js/lottie.min.js"])), env.opts.autoescape);
output += "\"></script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_content: b_content,
b_libs: b_libs,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/History/History.jinja"] , dependencies)
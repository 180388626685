require('./CommonNewForm.less');

module.exports = (function() {
    function CommonNewForm($form, additionalFilesSettings) {
        var _this = this;
        this.$form = $form;

        this.additionalFilesSettings = additionalFilesSettings;

        // Custom selects events
        this.$form.on('click', '.CommonNewForm-customSelectWrapper', this.clickSelect);
        this.$form.on('click', '.js-customSelectOption', this.clickSelectOption);

        // File upload events
        this.$form.on('change', '.js-uploadFiles', this.uploadFiles.bind(this));
        this.$form.on('click', '.js-removeFile', this.removeFile.bind(this));

        $(document).on('click', function(e) {
            if ($('.CommonNewForm-customSelectWrapper.is-open').length) {
                $('.CommonNewForm-customSelectWrapper.is-open').each(function() {
                    if (!$(this)[0].contains(e.target)) {
                        $(this).removeClass('is-open');
                    }
                });
            }
        });

        this.$requiredFields = this.$form.find('[data-required="true"]');
        this.$requiredFields.on('input', function() {
            _this.validateField($(this));
        });
    }

    CommonNewForm.prototype.validateField = function(field) {
        var value = $.trim($(field).val());
        var isEmpty = value === '';

        var $el = $(field).attr('type') === 'file' ? $(field).parents('.CommonNewForm-file') : $(field);

        if ($el.attr('type') === 'checkbox') {
            var isChecked = $el.is(':checked');

            // Почему-то не добавляется класс непосредственно к чекбоксу…
            $el.parent().toggleClass('isInvalid', !isChecked);
        } else if ($el.attr('type') === 'email') {
            var isValidEmail = /.+@.+\..+/i.test(value);
            $el.toggleClass('isInvalid', !isValidEmail || isEmpty);
        } else if ($el.attr('type') === 'number' || $el.attr('inputmode') === 'numeric') {
            var isValidNumber = /^\d*$/.test(value);
            $el.toggleClass('isInvalid', !isValidNumber || isEmpty);
        } else {
            $el.toggleClass('isInvalid', isEmpty);
        }
    };

    CommonNewForm.prototype.validate = function() {
        var _this = this;

        this.$form.find('[data-required="true"]').each(function(key, field) {
            _this.validateField(field);
        });

        return this.$form.find('.isInvalid').length === 0;
    };

    CommonNewForm.prototype.submit = function (e, successCallback, errorCallback) {
        e.preventDefault();
        var _this = this;

        var $submitBtn = this.$form.find('[type="submit"]');
        var formData = new FormData(this.$form[0]);

        var isValid = this.validate();
        if (!isValid) {
            return;
        }

        $submitBtn.attr('disabled', true);
        this.$form.find('.js-formMessage').remove();

        $.ajax({
            cache: false,
            contentType: false,
            processData: false,
            data: formData,
            type: this.$form.attr('method'),
            url: this.$form.attr('action'),
            error: function (err) {
                $submitBtn
                    .removeAttr('disabled')
                    .after('<div class="CommonNewForm-message CommonNewForm-message--error js-formMessage">Ошибка при отправке. Пожалуйста, попробуйте еще раз через 10 мин</div>');

                if (typeof errorCallback === 'function') {
                    errorCallback(err.responseJSON);
                }
            },
            success: function (response) {
                $submitBtn.after('<div class="CommonNewForm-message CommonNewForm-message--thanks js-formMessage">Спасибо за заявку! Мы свяжемся с вами.</div>');

                if (typeof successCallback === 'function') {
                    successCallback(response);
                }
            }
        });
    };

    CommonNewForm.prototype.clickSelect = function (e) {
        var $select = $(e.currentTarget);

        $select.toggleClass('is-open');
    };

    CommonNewForm.prototype.clickSelectOption = function (e) {
        var $option = $(e.currentTarget);
        var $select = $option.parents('.CommonNewForm-selectWrapper');

        var $value = $select.find('.CommonNewForm-customSelectValue'),
            selectName = $option.data('name'),
            currentValue = $option.data('value'),
            currentText = $option.text();

        $value.html(currentText);
        $select.find('select[name="' + selectName + '"]').val(currentValue).trigger('change');
    };

    CommonNewForm.prototype.uploadFiles = function (e) {
        var _this = this;

        var $input = $(e.currentTarget),
            files = e.currentTarget.files,
            isMultiple = Boolean(e.currentTarget.dataset.multiple);

        if (files.length) {
            var template = [];
            var skip = false;
            var $filesList = $input.parents('.CommonNewForm-filesItem').find('.js-uploadFilesList');
            var index = $input.parents('.CommonNewForm-filesItem').find('.js-uploadFiles:last-of-type').data('index') || 0;

            var fileNames = [];
            $filesList.find('.CommonNewForm-uploadedFilesListItemName span').each(function(idx, fileNameSpan) {
                fileNames.push($(fileNameSpan).text());
            });

            for (const file of files) {
                if (fileNames.indexOf(file.name) !== -1) {
                    skip = true;

                    break;
                }

                template.push(
                    '<li class="CommonNewForm-uploadedFilesListItem">' +
                        '<div class="CommonNewForm-uploadedFilesListItemName">' +
                            '<span>' + file.name + '</span>' +
                        '</div>' +
                        '<button type="button" class="CommonNewForm-uploadedFilesListItemButton js-removeFile" data-single="' + isMultiple + '" data-index="' + index +'">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M9.197 9.197a.5.5 0 0 1 .707 0l2.192 2.192 2.193-2.192a.5.5 0 1 1 .707.707l-2.193 2.192 2.193 2.193a.5.5 0 1 1-.707.707l-2.193-2.193-2.192 2.193a.5.5 0 1 1-.707-.707l2.192-2.193-2.192-2.192a.5.5 0 0 1 0-.707Z"/><path fill-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-1a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" clip-rule="evenodd"/></svg>' +
                        '</button>' +
                    '</li>'
                );
            }

            if (!skip) {
                var filesList = template.join('');

                if (isMultiple) {
                    var $after = _this.additionalFilesSettings
                        ? '<input class="CommonNewForm-fieldFile js-uploadFiles" type="file" name="' + _this.additionalFilesSettings.name + '" id="' + _this.additionalFilesSettings.id + '"  data-multiple="true" data-index="' + (index + 1) + '">'
                        : '<input class="CommonNewForm-fieldFile js-uploadFiles" type="file" name="additionalFiles[]" id="CommonNewForm-uploadAdditionalFiles"  accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" data-multiple="true" data-index="' + (index + 1) + '">';

                    $filesList.append(filesList);
                    $input
                        .removeAttr('id')
                        .after($after);

                    // accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                } else {
                    $input.parent().find('.CommonNewForm-fileUploadBtn').hide();
                    $input.parent().find('.CommonNewForm-fileUploadSuccessIcon').show();

                    $filesList.html(filesList);
                }

                // Для обновления карты на странице контактов
                setTimeout(function() {
                    window.dispatchEvent(new Event('resize'));
                }, 10);
            }
        }
    };

    CommonNewForm.prototype.removeFile = function (e) {
        var $btn = $(e.currentTarget),
            isMultiple = Boolean($btn.data('single'));

        if (!isMultiple) {
            var $formFile = $btn.parents('.CommonNewForm-filesItem');

            $formFile.find('.CommonNewForm-fileUploadBtn').show();
            $formFile.find('.CommonNewForm-fileUploadSuccessIcon').hide();
            $formFile.find('input[type="file"]')[0].value = '';

            $btn.parent().remove();
        } else {
            $('.js-uploadFiles[data-index="' + $btn.data('index') + '"]').remove();
        }

        $btn.parent().remove();

        // Для обновления карты на странице контактов
        setTimeout(function() {
            window.dispatchEvent(new Event('resize'));
        }, 10);
    }

    return CommonNewForm;
})();

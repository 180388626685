var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/Video/Video.jinja"] = require( "mobile/components/Video/Video.jinja" );
dependencies["mobile/components/BrandsList/BrandsList.jinja"] = require( "mobile/components/BrandsList/BrandsList.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Brands/Brands.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Brands/Brands.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Brands/Brands.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/Brands/Brands.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TopNav")) {
var t_11 = t_8.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_11);
output += "\n";
env.getTemplate("mobile/components/Video/Video.jinja", false, "assets/app/mobile/pages/Brands/Brands.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "Video")) {
var t_15 = t_12.Video;
} else {
cb(new Error("cannot import 'Video'")); return;
}
context.setVariable("Video", t_15);
output += "\n";
env.getTemplate("mobile/components/BrandsList/BrandsList.jinja", false, "assets/app/mobile/pages/Brands/Brands.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "BrandsList")) {
var t_19 = t_16.BrandsList;
} else {
cb(new Error("cannot import 'BrandsList'")); return;
}
context.setVariable("BrandsList", t_19);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
output += t_20;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_23,t_22) {
if(t_23) { cb(t_23); return; }
output += t_22;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
output += t_24;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_27,t_26) {
if(t_27) { cb(t_27); return; }
output += t_26;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
output += t_28;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_31,t_30) {
if(t_31) { cb(t_31); return; }
output += t_30;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 7;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 7, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 8, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 10;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 11, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--floatNav TopNav--brands","title": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"title")})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "BrandsPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Brands\">\n        <header class=\"Brands-head\" data-head-type=\"";
output += runtime.suppressValue((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"video")?"video":"image"), env.opts.autoescape);
output += "\">\n            <div class=\"Brands-headNav\">\n";
output += "\n            </div>\n\n            <div class=\"Brands-headContent\">\n                <div class=\"Brands-headMedia\">\n                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"video")) {
output += "\n                        <div class=\"Brands-video\">\n                            <video id=\"video\" class=\"Production-mediaVideo\" playsinline autoplay muted loop>\n                                <source src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"video"), env.opts.autoescape);
output += "\">\n                                Your browser does not support the video tag.\n                            </video>\n                        </div>\n                    ";
;
}
else {
output += "\n                        <div class=\"Brands-img\">\n                            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"img")),"w1280"), env.opts.autoescape);
output += "\"\n                                srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"img")),"w1024"), env.opts.autoescape);
output += " 1024w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"img")),"w1280"), env.opts.autoescape);
output += " 1280w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"img")),"w1440"), env.opts.autoescape);
output += " 1440w,\n                                        ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"img")),"w1920"), env.opts.autoescape);
output += " 1920w\"\n                                alt=\"\">\n                        </div>\n                    ";
;
}
output += "\n                </div>\n\n                <div class=\"Brands-header\">\n                    <h1 class=\"Brands-title\">";
output += runtime.suppressValue((lineno = 45, colno = 49, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Бренды"])), env.opts.autoescape);
output += "</h1>\n                    <div class=\"Brands-description\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "category")),"text")), env.opts.autoescape);
output += "</div>\n                </div>\n            </div>\n        </header>\n\n        <div class=\"Brands-list\">\n            ";
output += runtime.suppressValue((lineno = 52, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "BrandsList"), "BrandsList", context, [runtime.makeKeywordArgs({"activeCategory": runtime.contextOrFrameLookup(context, frame, "category"),"activeSubCategory": runtime.contextOrFrameLookup(context, frame, "subCategory"),"activeStyles": runtime.contextOrFrameLookup(context, frame, "activeStyles"),"categories": runtime.contextOrFrameLookup(context, frame, "brandCategories"),"brands": runtime.contextOrFrameLookup(context, frame, "beerBrands")})])), env.opts.autoescape);
output += "\n        </div>\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 61;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_32,hole_170) {
if(t_32) { cb(t_32); return; }
hole_170 = runtime.markSafe(hole_170);
output += "\n    ";
output += runtime.suppressValue(hole_170, env.opts.autoescape);
output += "\n    <script>\n        app.data.hasMore = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "hasMore"))), env.opts.autoescape);
output += ";\n        app.data.category = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "category"))), env.opts.autoescape);
output += ";\n        app.data.subCategory = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "subCategory"))), env.opts.autoescape);
output += ";\n        app.data.styles = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "styles"))), env.opts.autoescape);
output += ";\n        app.data.activeStyles = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "activeStyles"))), env.opts.autoescape);
output += ";\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_content: b_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Brands/Brands.jinja"] , dependencies)
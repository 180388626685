var Backbone = require('backbone/backbone');

require('./../LegalForm/LegalForm.less');

module.exports = Backbone.View.extend({
    el: '.LegalForm',

    events: {
        'submit': 'submitForm',
        'click .LegalForm-customSelectWrapper': 'clickSelect',
        'click .js-customSelectOption': 'clickSelectOption',
    },

    initialize: function () {
        var _this = this;

        this.$submitBtn = this.$('.js-legalFormSubmit');

        this.$requiredFields = this.$('[data-required="true"]');
        this.$requiredFields.on('input', function() {
            _this._validateField($(this));
        });

        $(document).on('click', function(e) {
            if ($('.LegalForm-customSelectWrapper.is-open').length) {
                if (!$('.LegalForm-customSelectWrapper')[0].contains(e.target)) {
                    $('.LegalForm-customSelectWrapper.is-open').removeClass('is-open');
                }
            }
        });
    },

    _validateField: function(field) {
        var value = $.trim($(field).val());
        var isEmpty = value === '';

        var $el = $(field).attr('type') === 'file' ? $(field).parents('.LegalForm-file') : $(field);

        if ($el.attr('type') === 'checkbox') {
            var isChecked = $el.is(':checked');

            // Почему-то не добавляется класс непосредственно к чекбоксу…
            $el.parent().toggleClass('isInvalid', !isChecked);
        } if ($el.attr('type') === 'email') {
            var isValidEmail = /.+@.+\..+/i.test(value);
            $el.toggleClass('isInvalid', !isValidEmail || isEmpty);
        }
        // else if ($el.attr('type') === 'tel') {
        //     var isValidPhone = /^(\+\d*|\d*)$/i.test(value);
        //     $el.toggleClass('isInvalid', !isValidPhone || isEmpty);
        // }
        else if ($el.attr('type') === 'number' || $el.attr('inputmode') === 'numeric') {
            var isValidNumber = /^\d*$/.test(value);
            $el.toggleClass('isInvalid', !isValidNumber || isEmpty);
        } else {
            $el.toggleClass('isInvalid', isEmpty);
        }
    },

    _validateForm: function() {
        var _this = this;
        var $form = this.$el;
        var isValid = true;

        $form.find('[data-required="true"]').each(function(key, field) {
            _this._validateField(field);
        });

        return $form.find('.isInvalid').length === 0;
    },

    submitForm: function (e) {
        e.preventDefault();
        var _this = this;

        var $form = this.$el;
        var formData = new FormData($form[0]);

        var isValid = this._validateForm();
        if (!isValid) {
            return;
        }

        this.$submitBtn.attr('disabled', true);
        this.$('.js-formMessage').remove();

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: formData,
            cache: false,
            processData: false,
            contentType: false,
            error: function (err) {
                _this.$submitBtn
                    .removeAttr('disabled')
                    .after('<div class="LegalForm-message LegalForm-message--error js-formMessage">Ошибка при отправке. Пожалуйста, попробуйте еще раз через 10 мин</div>');
            },
            success: function (response) {
                _this.$submitBtn.after('<div class="LegalForm-message LegalForm-message--thanks js-formMessage">Спасибо за заявку! Мы свяжемся с вами.</div>');
            }
        });
    },

    clickSelect: function (e) {
        var $select = $(e.currentTarget);

        $select.toggleClass('is-open');
    },

    clickSelectOption: function (e) {
        var $option = $(e.currentTarget);
        var $select = $option.parents('.LegalForm-customSelectWrapper');

        var $value = $select.find('.LegalForm-customSelectValue'),
            selectName = $option.data('name'),
            currentValue = $option.data('value'),
            currentText = $option.text();

        $value.html(currentText);
        $('select[name="' + selectName + '"]').val(currentValue);
    }

});

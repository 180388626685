var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/ButtonIcon/ButtonIcon.jinja"] = require( "mobile/components/ButtonIcon/ButtonIcon.jinja" );
dependencies["mobile/components/SectionBadge/SectionBadge.jinja"] = require( "mobile/components/SectionBadge/SectionBadge.jinja" );
dependencies["mobile/components/ViewCount/ViewCount.jinja"] = require( "mobile/components/ViewCount/ViewCount.jinja" );
dependencies["mobile/components/PostItem/PostItem.jinja"] = require( "mobile/components/PostItem/PostItem.jinja" );
dependencies["mobile/components/Subscribe/Subscribe.jinja"] = require( "mobile/components/Subscribe/Subscribe.jinja" );
dependencies["mobile/components/StyleTag/StyleTag.jinja"] = require( "mobile/components/StyleTag/StyleTag.jinja" );
dependencies["mobile/components/SubscribeSections/SubscribeSections.jinja"] = require( "mobile/components/SubscribeSections/SubscribeSections.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/Post/Post.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/ButtonIcon/ButtonIcon.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "ButtonIcon")) {
var t_11 = t_8.ButtonIcon;
} else {
cb(new Error("cannot import 'ButtonIcon'")); return;
}
context.setVariable("ButtonIcon", t_11);
output += "\n";
env.getTemplate("mobile/components/SectionBadge/SectionBadge.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "SectionBadge")) {
var t_15 = t_12.SectionBadge;
} else {
cb(new Error("cannot import 'SectionBadge'")); return;
}
context.setVariable("SectionBadge", t_15);
output += "\n";
env.getTemplate("mobile/components/ViewCount/ViewCount.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "ViewCount")) {
var t_19 = t_16.ViewCount;
} else {
cb(new Error("cannot import 'ViewCount'")); return;
}
context.setVariable("ViewCount", t_19);
output += "\n";
env.getTemplate("mobile/components/PostItem/PostItem.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "PostItem")) {
var t_23 = t_20.PostItem;
} else {
cb(new Error("cannot import 'PostItem'")); return;
}
context.setVariable("PostItem", t_23);
output += "\n";
env.getTemplate("mobile/components/Subscribe/Subscribe.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "Subscribe")) {
var t_27 = t_24.Subscribe;
} else {
cb(new Error("cannot import 'Subscribe'")); return;
}
context.setVariable("Subscribe", t_27);
output += "\n";
env.getTemplate("mobile/components/StyleTag/StyleTag.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "StyleTag")) {
var t_31 = t_28.StyleTag;
} else {
cb(new Error("cannot import 'StyleTag'")); return;
}
context.setVariable("StyleTag", t_31);
output += "\n";
env.getTemplate("mobile/components/SubscribeSections/SubscribeSections.jinja", false, "assets/app/mobile/pages/Post/Post.jinja", false, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
t_32.getExported(function(t_34,t_32) {
if(t_34) { cb(t_34); return; }
if(Object.prototype.hasOwnProperty.call(t_32, "SubscribeSections")) {
var t_35 = t_32.SubscribeSections;
} else {
cb(new Error("cannot import 'SubscribeSections'")); return;
}
context.setVariable("SubscribeSections", t_35);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_43,t_42) {
if(t_43) { cb(t_43); return; }
output += t_42;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("popup"))(env, context, frame, runtime, function(t_45,t_44) {
if(t_45) { cb(t_45); return; }
output += t_44;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_47,t_46) {
if(t_47) { cb(t_47); return; }
output += t_46;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 12, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Медиа"])), env.opts.autoescape);
output += ": ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "PostPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_popup(env, context, frame, runtime, cb) {
var lineno = 18;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <div class=\"Post ";
if(!runtime.contextOrFrameLookup(context, frame, "server")) {
output += "Post--toAnimate";
;
}
output += "\">\n        <div class=\"Post-floatNav\"></div>\n        <div class=\"Post-close\">";
output += runtime.suppressValue((lineno = 21, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ButtonIcon"), "ButtonIcon", context, [])), env.opts.autoescape);
output += "</div>\n\n        <div class=\"Post-header\">\n            <div class=\"Post-img\">\n                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"mobile")),"w768") || runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"preview")),"w768"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
output += "\">\n            </div>\n        </div>\n\n        <div class=\"Post-body\">\n            <div class=\"Post-content\">\n                <div class=\"Post-meta\">\n                    <div class=\"Post-badge\">\n                        ";
output += runtime.suppressValue((lineno = 33, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SectionBadge"), "SectionBadge", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"section")])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"Post-date\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"dateText"), env.opts.autoescape);
output += "</div>\n                    <div class=\"Post-viewCount\">";
output += runtime.suppressValue((lineno = 36, colno = 60, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "ViewCount"), "ViewCount", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"viewCount"),runtime.makeKeywordArgs({"classes": "ViewCount--post"})])), env.opts.autoescape);
output += "</div>\n                </div>\n\n                <h1 class=\"Post-title\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"title"), env.opts.autoescape);
output += "</h1>\n\n                <div class=\"Post-text\">";
output += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"text")), env.opts.autoescape);
output += "</div>\n\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"tags")) {
output += "<div class=\"Post-tags\">\n                    ";
frame = frame.push();
var t_50 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"tags");
if(t_50) {t_50 = runtime.fromIterator(t_50);
var t_49 = t_50.length;
for(var t_48=0; t_48 < t_50.length; t_48++) {
var t_51 = t_50[t_48];
frame.set("t", t_51);
frame.set("loop.index", t_48 + 1);
frame.set("loop.index0", t_48);
frame.set("loop.revindex", t_49 - t_48);
frame.set("loop.revindex0", t_49 - t_48 - 1);
frame.set("loop.first", t_48 === 0);
frame.set("loop.last", t_48 === t_49 - 1);
frame.set("loop.length", t_49);
output += "\n                        ";
output += runtime.suppressValue((lineno = 46, colno = 35, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "StyleTag"), "StyleTag", context, [t_51,runtime.makeKeywordArgs({"tag": "a","link": (lineno = 46, colno = 56, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["posts:index"])) + "?tags=" + env.getFilter("string").call(context, runtime.memberLookup((t_51),"id"))})])), env.opts.autoescape);
output += "\n                    ";
;
}
}
frame = frame.pop();
output += "\n                </div>\n                ";
;
}
output += "<div class=\"Post-bodyFooter\">\n                    <div class=\"Post-share Post-share--tg\">\n                        <div class=\"Post-shareIcon\">";
output += runtime.suppressValue((lineno = 53, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["telegramIcon"])), env.opts.autoescape);
output += "</div>\n                    </div>\n                    <div class=\"Post-share Post-share--vk\">\n                        <div class=\"Post-shareIcon\">";
output += runtime.suppressValue((lineno = 56, colno = 64, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["vkIcon"])), env.opts.autoescape);
output += "</div>\n                    </div>\n                    ";
output += "\n                </div>\n            </div>\n        </div>\n\n        <div class=\"Post-footer\">\n            <div class=\"Post-similar\">\n                <header class=\"Post-similarHeader\">";
output += runtime.suppressValue((lineno = 72, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Похожие материалы"])), env.opts.autoescape);
output += "</header>\n\n                <div class=\"Post-similarList\">\n                    <div class=\"Post-similarListInner\">\n                        ";
frame = frame.push();
var t_54 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"similar");
if(t_54) {t_54 = runtime.fromIterator(t_54);
var t_53 = t_54.length;
for(var t_52=0; t_52 < t_54.length; t_52++) {
var t_55 = t_54[t_52];
frame.set("p", t_55);
frame.set("loop.index", t_52 + 1);
frame.set("loop.index0", t_52);
frame.set("loop.revindex", t_53 - t_52);
frame.set("loop.revindex0", t_53 - t_52 - 1);
frame.set("loop.first", t_52 === 0);
frame.set("loop.last", t_52 === t_53 - 1);
frame.set("loop.length", t_53);
output += "\n                            ";
output += runtime.suppressValue((lineno = 77, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "PostItem"), "PostItem", context, [runtime.makeKeywordArgs({"post": t_55,"classes": "PostItem--post"})])), env.opts.autoescape);
output += "\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"Post-subscribe\">\n                ";
output += runtime.suppressValue((lineno = 84, colno = 28, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "Subscribe"), "Subscribe", context, [runtime.makeKeywordArgs({"classes": "Subscribe--post"})])), env.opts.autoescape);
output += "\n            </div>\n\n            <div class=\"Post-subscribeSections\">\n                ";
output += runtime.suppressValue((lineno = 88, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "SubscribeSections"), "SubscribeSections", context, [runtime.makeKeywordArgs({"sections": runtime.contextOrFrameLookup(context, frame, "sections")})])), env.opts.autoescape);
output += "\n            </div>\n        </div>\n\n        <img src=\"/p/";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "post")),"id"), env.opts.autoescape);
output += ".gif\" alt=\"util\">\n    </div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 96;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_56,hole_52) {
if(t_56) { cb(t_56); return; }
hole_52 = runtime.markSafe(hole_52);
output += "\n    ";
output += runtime.suppressValue(hole_52, env.opts.autoescape);
output += "\n\n    <script>\n        app.data.tags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "tags"))), env.opts.autoescape);
output += ";\n        app.data.activeTags = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "activeTags"))), env.opts.autoescape);
output += ";\n        app.data.activeSection = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "section"))), env.opts.autoescape);
output += ";\n        app.data.sections = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "sections"))), env.opts.autoescape);
output += ";\n        app.data.hasMore = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "hasMore"))), env.opts.autoescape);
output += ";\n        app.data.posts = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "posts"))), env.opts.autoescape);
output += ";\n        app.data.favorite = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "favorite"))), env.opts.autoescape);
output += ";\n        app.data.news = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "news"))), env.opts.autoescape);
output += ";\n\n        app.data.post = ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "post"))), env.opts.autoescape);
output += ";\n    </script>\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_topNav: b_topNav,
b_body_class: b_body_class,
b_popup: b_popup,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/Post/Post.jinja"] , dependencies)
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["mobile/Base.jinja"] = require( "mobile/Base.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["mobile/components/TopNav/TopNav.jinja"] = require( "mobile/components/TopNav/TopNav.jinja" );
dependencies["mobile/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja"] = require( "mobile/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja" );
dependencies["mobile/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja"] = require( "mobile/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja" );
dependencies["mobile/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja"] = require( "mobile/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja" );
dependencies["mobile/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja"] = require( "mobile/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja" );
dependencies["mobile/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja"] = require( "mobile/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("mobile/Base.jinja", true, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "svgSprite")) {
var t_7 = t_4.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_7);
output += "\n";
env.getTemplate("mobile/components/TopNav/TopNav.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
t_8.getExported(function(t_10,t_8) {
if(t_10) { cb(t_10); return; }
if(Object.prototype.hasOwnProperty.call(t_8, "TopNav")) {
var t_11 = t_8.TopNav;
} else {
cb(new Error("cannot import 'TopNav'")); return;
}
context.setVariable("TopNav", t_11);
output += "\n\n";
env.getTemplate("mobile/components/CareerVacancyApply/CareerVacancyApplyStep1.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_13,t_12) {
if(t_13) { cb(t_13); return; }
t_12.getExported(function(t_14,t_12) {
if(t_14) { cb(t_14); return; }
if(Object.prototype.hasOwnProperty.call(t_12, "CareerVacancyApplyStep1")) {
var t_15 = t_12.CareerVacancyApplyStep1;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep1'")); return;
}
context.setVariable("CareerVacancyApplyStep1", t_15);
output += "\n";
env.getTemplate("mobile/components/CareerVacancyApply/CareerVacancyApplyStep2.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_17,t_16) {
if(t_17) { cb(t_17); return; }
t_16.getExported(function(t_18,t_16) {
if(t_18) { cb(t_18); return; }
if(Object.prototype.hasOwnProperty.call(t_16, "CareerVacancyApplyStep2")) {
var t_19 = t_16.CareerVacancyApplyStep2;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep2'")); return;
}
context.setVariable("CareerVacancyApplyStep2", t_19);
output += "\n";
env.getTemplate("mobile/components/CareerVacancyApply/CareerVacancyApplyStep3.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_21,t_20) {
if(t_21) { cb(t_21); return; }
t_20.getExported(function(t_22,t_20) {
if(t_22) { cb(t_22); return; }
if(Object.prototype.hasOwnProperty.call(t_20, "CareerVacancyApplyStep3")) {
var t_23 = t_20.CareerVacancyApplyStep3;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep3'")); return;
}
context.setVariable("CareerVacancyApplyStep3", t_23);
output += "\n";
env.getTemplate("mobile/components/CareerVacancyApply/CareerVacancyApplyStep4.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_25,t_24) {
if(t_25) { cb(t_25); return; }
t_24.getExported(function(t_26,t_24) {
if(t_26) { cb(t_26); return; }
if(Object.prototype.hasOwnProperty.call(t_24, "CareerVacancyApplyStep4")) {
var t_27 = t_24.CareerVacancyApplyStep4;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep4'")); return;
}
context.setVariable("CareerVacancyApplyStep4", t_27);
output += "\n";
env.getTemplate("mobile/components/CareerVacancyApply/CareerVacancyApplyStep5.jinja", false, "assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja", false, function(t_29,t_28) {
if(t_29) { cb(t_29); return; }
t_28.getExported(function(t_30,t_28) {
if(t_30) { cb(t_30); return; }
if(Object.prototype.hasOwnProperty.call(t_28, "CareerVacancyApplyStep5")) {
var t_31 = t_28.CareerVacancyApplyStep5;
} else {
cb(new Error("cannot import 'CareerVacancyApplyStep5'")); return;
}
context.setVariable("CareerVacancyApplyStep5", t_31);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_33,t_32) {
if(t_33) { cb(t_33); return; }
output += t_32;
output += "\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("ogtitle"))(env, context, frame, runtime, function(t_35,t_34) {
if(t_35) { cb(t_35); return; }
output += t_34;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("body_class"))(env, context, frame, runtime, function(t_37,t_36) {
if(t_37) { cb(t_37); return; }
output += t_36;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("topNav"))(env, context, frame, runtime, function(t_39,t_38) {
if(t_39) { cb(t_39); return; }
output += t_38;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_41,t_40) {
if(t_41) { cb(t_41); return; }
output += t_40;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})})})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 11;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 11, colno = 24, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_ogtitle(env, context, frame, runtime, cb) {
var lineno = 12;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += " • ";
output += runtime.suppressValue((lineno = 12, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_body_class(env, context, frame, runtime, cb) {
var lineno = 14;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "IndexPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_topNav(env, context, frame, runtime, cb) {
var lineno = 16;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += runtime.suppressValue((lineno = 17, colno = 13, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "TopNav"), "TopNav", context, [runtime.makeKeywordArgs({"classes": "TopNav--fixed TopNav--career","currentUrl": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"path"),"title": (lineno = 17, colno = 86, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"]))})])), env.opts.autoescape);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n<div class=\"Career\">\n    <div class=\"Career-floatNav\"></div>\n    <div class=\"Career-main\">\n        <div class=\"CareerVacancyApply\">\n            <div class=\"CareerVacancyApply-header\">\n                <div class=\"Career-subtitle\">\n                    <span class=\"Career-subtitleStroke\"></span>\n                    <div class=\"Career-subtitleText\">";
output += runtime.suppressValue((lineno = 28, colno = 57, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Карьера"])), env.opts.autoescape);
output += "</div>\n                </div>\n                <h1 class=\"Career-title\">\n                    ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"name"), env.opts.autoescape);
output += ", ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"city")),"text"), env.opts.autoescape);
output += "\n                </h1>\n            </div>\n            <div class=\"CareerVacancyApplyFormWrapper\">\n                <ol class=\"CareerVacancyApply-stepsNav\">\n                    <li class=\"isCurrentStep\">\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Основные <br>данные</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Образование</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Опыт работы</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Ключевые навыки</span>\n                    </li>\n                    <li>\n                        <span class=\"CareerVacancyApply-stepsNavItem\">Сопроводительное <br>письмо</span>\n                    </li>\n                </ol>\n                <form class=\"CareerVacancyApplyForm CareerResumeForm\" action=\"/api/career-vacancy-apply/\" method=\"post\">\n                    <input type=\"hidden\" name=\"vacancy-id\" value=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "vacancy")),"id"), env.opts.autoescape);
output += "\">\n                    <div class=\"CareerVacancyApplyForm-step isCurrentStep\">\n                        ";
output += runtime.suppressValue((lineno = 55, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep1"), "CareerVacancyApplyStep1", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_42 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_43 = "";;
frame = frame.pop();
return new runtime.SafeString(t_43);
});
return macro_t_42;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 58, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep2"), "CareerVacancyApplyStep2", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_44 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_45 = "";;
frame = frame.pop();
return new runtime.SafeString(t_45);
});
return macro_t_44;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 61, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep3"), "CareerVacancyApplyStep3", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_46 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_47 = "";;
frame = frame.pop();
return new runtime.SafeString(t_47);
});
return macro_t_46;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 64, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep4"), "CareerVacancyApplyStep4", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_48 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_49 = "";;
frame = frame.pop();
return new runtime.SafeString(t_49);
});
return macro_t_48;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                    <div class=\"CareerVacancyApplyForm-step\">\n                        ";
output += runtime.suppressValue((lineno = 67, colno = 55, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CareerVacancyApplyStep5"), "CareerVacancyApplyStep5", context, [runtime.makeKeywordArgs({"caller": (function (){var macro_t_50 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_51 = "";;
frame = frame.pop();
return new runtime.SafeString(t_51);
});
return macro_t_50;})()})])), env.opts.autoescape);
output += "\n                    </div>\n                </form>\n            </div>\n        </div>\n    </div>\n</div>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_ogtitle: b_ogtitle,
b_body_class: b_body_class,
b_topNav: b_topNav,
b_content: b_content,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/pages/CareerVacancyApply/CareerVacancyApply.jinja"] , dependencies)
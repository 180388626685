var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../config/frontend/nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );




var shim = require("/var/www/mosbrew.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/mobile/components/Subscribe/Subscribe.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/mobile/components/Subscribe/Subscribe.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
if(Object.prototype.hasOwnProperty.call(t_1, "svgSprite")) {
var t_4 = t_1.svgSprite;
} else {
cb(new Error("cannot import 'svgSprite'")); return;
}
context.setVariable("svgSprite", t_4);
output += " \n\n";
var macro_t_5 = runtime.makeMacro(
[], 
["classes"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_6 = "";t_6 += "\n    <form class=\"Subscribe ";
t_6 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
t_6 += "\" action=\"/api/subscribe/\" method=\"POST\">\n        <label class=\"Subscribe-title\" for=\"Subscribe-email\">";
t_6 += runtime.suppressValue((lineno = 4, colno = 65, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Подписаться на новости"])), env.opts.autoescape);
t_6 += "</label>\n        <div class=\"Subscribe-input\">\n            <div class=\"Subscribe-input-inner\">\n                <input class=\"Subscribe-email\" type=\"text\" name=\"email\" id=\"Subscribe-email\" placeholder=\"Email\" />\n                <div class=\"Subscribe-message Subscribe-message--error\">";
t_6 += runtime.suppressValue((lineno = 8, colno = 76, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Адрес введен неверно"])), env.opts.autoescape);
t_6 += "</div>\n                <div class=\"Subscribe-message Subscribe-message--thanks\">";
t_6 += runtime.suppressValue((lineno = 9, colno = 77, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "_"), "_", context, ["Спасибо!"])), env.opts.autoescape);
t_6 += "</div>\n            </div>\n            <button class=\"Subscribe-submit\" type=\"submit\" value=\"\" name=\"subscribe\">";
t_6 += runtime.suppressValue((lineno = 11, colno = 97, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "svgSprite"), "svgSprite", context, ["submitArrow"])), env.opts.autoescape);
t_6 += "</button>\n        </div>\n    </form>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_6);
});
context.addExport("Subscribe");
context.setVariable("Subscribe", macro_t_5);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/mobile/components/Subscribe/Subscribe.jinja"] , dependencies)
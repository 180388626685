var Backbone = require('backbone');

var Page = require('front/pages/Page/Page');
require('mobile/components/PostItem/PostItem');
require('mobile/components/ViewCount/ViewCount');
var MenuTop = require('mobile/components/MenuTop/MenuTop');

require('./People.less');

module.exports = Page.extend({
    template: require('./People.jinja'),

    el: '.People',

    apiUrl: '/api/index/',

    title: window.gettext('Компания') + ': ' + window.gettext('Люди'),

    initialize: function (options) {
        this.options = options || {};
        this.data = this.options.data;
        this.menuTop = new MenuTop();
    },

    activate: function () {
        this.setTitle();
    }
});
